import { useCallback, useEffect } from 'react';

import {
  Categories,
  InsightV2Request,
  NativeResources,
} from '@ariksa/inventory-core/api';
import { filter, map, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { useResourceType } from 'containers/App/hooks/useResourceType';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useSearchParams } from 'hooks/useSearchParams';

import { selectInventory } from '../selector';
import { actions } from '../slice';

export const useInventory = () => {
  const dispatch = useDispatch();
  const {
    instanceInventory,
    instanceResourceType,
    deploymentSummary,
    currentCategory,
  } = useSelector(selectInventory);

  const { environmentId } = useSelector(selectActiveEnvironment);
  const { accountId } = useSelector(selectActiveCloudAccount);
  const { searchTerm: searchWord } = useSelector(selectSharedState);
  const { getResourceType } = useResourceType();
  const { selectedRegion, selectedVpc, setSelectedVpc } = useInventoryContext();

  const searchParams = useSearchParams<{
    category?: string;
  }>();

  useEffect(() => {
    const { category } = searchParams;
  }, [searchParams]);

  const handleCategoryChange = useCallback(
    (val, menuItem) => {
      console.log(val, menuItem);
      dispatch(actions.resetNativeResource());
      dispatch(actions.updateCurrentCategory(val));
      dispatch(actions.updateCurrentCategoryTitle(menuItem?.data?.name));
      dispatch(actions.resetSearchItems());

      setSelectedVpc({
        label: 'All VPC',
        value: undefined,
      });
    },
    [dispatch, setSelectedVpc],
  );

  const getVpcs = useCallback(() => {
    if (!!instanceResourceType || currentCategory === 'Overview')
      dispatch(
        actions.getVPCs({
          q: {
            environmentId,
            accountId: accountId ? [accountId] : undefined,
            region: selectedRegion?.value,
            nativeResource: !!instanceResourceType
              ? (instanceResourceType as NativeResources)
              : undefined,
          },
        }),
      );
  }, [
    dispatch,
    environmentId,
    accountId,
    selectedRegion,
    instanceResourceType,
    currentCategory,
  ]);

  const getResourceData = useCallback(() => {
    if (!!instanceResourceType) {
      let param: InsightV2Request = {
        environment_id: environmentId,
        account_id: accountId ? [accountId] : [],
        resource_type: instanceResourceType as NativeResources,
        page: instanceInventory.page.info.page_number,
        size: instanceInventory.page.info.page_size,
        search_term: searchWord,
        category: currentCategory?.split('_')?.[0] as Categories,
        regions: selectedRegion?.value ? [selectedRegion?.value] : undefined,
        vpcs: selectedVpc?.value ? [selectedVpc?.value] : undefined,
      };
      dispatch(
        actions.getInstanceInventory({
          q: { insightV2Request: param },
          onSuccess: res => {
            switch (instanceResourceType) {
              case NativeResources.Ec2Instance:
              case NativeResources.ElasticKubernetesService:
              case NativeResources.EcsCluster:
              case NativeResources.EcsService:
              case NativeResources.ElasticContainerRegistry:
              case NativeResources.LambdaFunction:
              case NativeResources.ElasticContainerService:
              case NativeResources.Container:
              case NativeResources.AmazonMachineImage:
              case NativeResources.GcpComputeEngine:
              case NativeResources.GcpCloudFunctions:
              case NativeResources.GcpContainer:
              case NativeResources.Gcpgke:
              case NativeResources.AzureVirtualMachine:
              case NativeResources.AzureContainer:
              case NativeResources.AzureVirtualMachineScaleSetInstance:
              case NativeResources.AzureVirtualMachineScaleSets:
              case NativeResources.AzureFunctions:
              case NativeResources.Aks:
                dispatch(
                  actions.getVulnerabilities({
                    q: {
                      resourceVulnerabilitiesRequest: {
                        resource_ids: map(res?.items, o => o?.uuid),
                        native_resource: instanceResourceType,
                        environment_id: environmentId,
                        account_ids: accountId ? [accountId] : [],
                      },
                    },
                  }),
                );
            }
          },
        }),
      );
    }
  }, [
    instanceInventory.page.info,
    environmentId,
    accountId,
    instanceResourceType,
    dispatch,
    searchWord,
    currentCategory,
    selectedRegion,
    selectedVpc,
  ]);

  const filterDeploymentSummary = useCallback(() => {
    if (!searchWord) return deploymentSummary.data;
    const search = searchWord?.trim()?.toLowerCase();

    return (
      filter(deploymentSummary.data, o => {
        const resource_type = getResourceType(o.native_resource ?? '');

        const item = map(
          [
            o?.native_resource,
            resource_type?.cloud_agnostic_class,
            resource_type?.cloud_agnostic_name,
            resource_type?.cloud_native_name,
            ...(o.regions ?? []),
          ],
          r => r?.toLowerCase(),
        );
        return some(item, i => i?.indexOf(search) !== -1);
      }) ?? []
    );
  }, [deploymentSummary.data, searchWord, getResourceType]);

  return {
    getResourceData,
    filterDeploymentSummary,
    handleCategoryChange,
    getVpcs,
  };
};
