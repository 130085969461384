import React, { useEffect } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  CustomTooltip,
  getIconColor,
  renderTooltipFieldValue,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes, ViewAllIcon } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { DashboardWidgetRightIcon } from 'containers/Dashboard/utils/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import {
  getSensitiveLabelBorderColor,
  getSensitiveLabelColor,
  renderLabel,
  renderPlusCountTagWithTooltip,
} from 'containers/Visibility/Data/Components/hooks/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { actions } from 'containers/Visibility/Data/slice';

export const HighRiskDataSourcesWithSensitiveData: React.FC = props => {
  const { highRiskDataSources } = useSelector(selectDataDashboard);
  const dispatch = useDispatch();
  const { accountId, environmentId } = useAccessBoundary();
  const { getResourceAlias } = useResourceType();
  const { accountMapping } = useCloudAccountId();

  useEffect(() => {
    dispatch(
      actions.getHighRiskDataSources({
        q: {
          accountIds: !!accountId ? [accountId] : [],
          environmentId,
          page: 1,
          size: 10,
          //riskRating: 'high',
          sortBy: 'total_sensitive_records',
        },
      }),
    );
  }, [dispatch, accountId, environmentId]);

  const columns = [
    {
      header: <Box pl={10}>SOURCE</Box>,
      render: ({ row }) => {
        const account = accountMapping[row?.account];
        return (
          <WithResourceIcon
            resourceType={row?.native_name}
            iconFilled={false}
            iconStyles={{ w: 5, h: 5 }}
            color={getIconColor(row?.native_name)}
          >
            <CustomTooltip
              label={
                <Stack spacing={0}>
                  {renderTooltipFieldValue('Resource', row?.resource_id)}
                  {renderTooltipFieldValue(
                    'Type',
                    getResourceAlias(row?.native_name),
                  )}
                  {renderTooltipFieldValue('Account Name', account?.name)}
                  {renderTooltipFieldValue(
                    'Account ID',
                    account?.cloud_account_id,
                  )}
                </Stack>
              }
            >
              <StackedCell
                upper={row?.resource_id}
                lower={
                  getResourceAlias(row?.native_name) + ' / ' + account?.name
                }
                styles={{ lower: { fontSize: '13px' } }}
                showLowerTooltip={false}
                showUpperTooltip={false}
              />
            </CustomTooltip>
          </WithResourceIcon>
        );
      },
      styles: { w: '40%' },
    },
    {
      header: 'DATA COUNT',
      render: ({ row }) => formatNumber(row?.total_sensitive_records, 1),
      styles: {
        td: { w: '25%' },
        cell: { textAlign: 'right', w: '65%' },
        header: { textAlign: 'center' },
      },
    },
    { header: 'RISK', render: () => 'High' },
    {
      header: 'LABELS',
      accessor: 'data_labels',
      render: ({ value }) => {
        return (
          <HStack>
            {value?.[0] &&
              renderLabel({
                label: value?.[0],
                color: getSensitiveLabelColor(value?.[0]),
                borderColor: getSensitiveLabelBorderColor(value[0]),
              })}
            <Box>
              {value?.length > 1 && renderPlusCountTagWithTooltip(value, 1)}
            </Box>
          </HStack>
        );
      },
    },
  ];

  return (
    <DashboardWidget
      label="High Risk Data Sources With Sensitive Data"
      content={
        <DashboardTable
          columns={columns}
          data={highRiskDataSources.data}
          isLoading={
            isEmpty(highRiskDataSources.data) && highRiskDataSources.isLoading
          }
          styles={{ td: { py: 2 } }}
        />
      }
      rightHeader={
        <DashboardWidgetRightIcon
          onClick={() =>
            dispatch(actions.updateSelectedTab(IconTypes.DataSources))
          }
          icon={<ViewAllIcon p={1} />}
          iconColor="primary"
        />
      }
      styles={{ contentWrapper: { pb: 0 } }}
    />
  );
};
