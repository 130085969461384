import { ContextType, NativeResources } from '@ariksa/inventory-core';

import { IconType, IconTypes } from 'components/Icons';

import AzureADGroupIcon from './blackIcons/azure-ad-group.png';
import AzureADUserIcon from './blackIcons/azure-ad-user.png';
import AzureADOrganizationIcon from './blackIcons/azure-ad.png';
import InternetIcon from './blackIcons/internet.png';
import KMSIcon from './blackIcons/Kms-Key-Black-128x128.png';
import SnowflakeIcon from './blackIcons/snowflake.png';
import AWSAccountIcon from './purpleIcons/Account-Purple-128x128.png';
import AccountIcon from './purpleIcons/Account-Purple-128x128.png';
import AccountRootIcon from './purpleIcons/Account-Root-Purple-128x128.png';
import AgentIcon from './purpleIcons/Agent-Purple-128x128.png';
import AmazonMachineImageIcon from './purpleIcons/Ami-Purple-128x128.png';
import APIGatewayRestAPIIcon from './purpleIcons/Api-Gateway-Api-Purple-128x128.png';
import AppsPurpleIcon from './purpleIcons/Apps-Purple-128x128.png';
import BackupIcon from './purpleIcons/Backup-Purple-128x128.png';
import BruteForceAttack from './purpleIcons/brute-force-attack-purple.png';
import ACMCertificateIcon from './purpleIcons/Certificate-Purple-128x128.png';
import CloudFrontIcon from './purpleIcons/Cloudfront-Distribution-Purple-128x128.png';
import CloudTrailIcon from './purpleIcons/Cloudtrail-Purple-128x128.png';
import CloudWatchLogGroupIcon from './purpleIcons/Cloudwatch-Monitoring-Purple-128x128.png';
import ComputeIcon from './purpleIcons/Compute-Purple-128x128.png';
import ConfigurationIcon from './purpleIcons/Configuration-Purple-128x128.png';
import ConsolePurple from './purpleIcons/ConsolePurple.png';
import CriticalData from './purpleIcons/critical-data-purple.png';
import CrossAccount from './purpleIcons/cross-account-purple.png';
import DHCPOptionsIcon from './purpleIcons/Dhcp-Options-Purple-128x128.png';
import DocumentDBClusterIcon from './purpleIcons/Documentdb-Cluster-Purple-128x128.png';
import DocumentDBIcon from './purpleIcons/Documentdb-Mongodb-Purple-128x128.png';
import DynamoDBIcon from './purpleIcons/Dynamodb-Purple-128x128.png';
import EBSIcon from './purpleIcons/Ebs-Data-Purple-128x128.png';
import EBSSnapshotIcon from './purpleIcons/Ebs-Snapshot-Purple-128x128.png';
import EC2AutoScalingGroupIcon from './purpleIcons/Ec2-Asg-Purple-128x128.png';
import SystemsManagerDocumentIcon from './purpleIcons/Ec2-Ssm-Document-Purple-128x128.png';
import EC2LaunchTemplateIcon from './purpleIcons/Ec2-Template-Purple-128x128.png';
import EcrImageIcon from './purpleIcons/Ecr-Image-Purple-128x128.png';
import ECSClusterIcon from './purpleIcons/Ecs-Cluster-Purple-128x128.png';
import EcsInstanceIcon from './purpleIcons/Ecs-Instance-Purple-128x128.png';
import ECSServiceIcon from './purpleIcons/Ecs-Service-Purple-128x128.png';
import ElasticFileSystemIcon from './purpleIcons/Efs-Purple-128x128.png';
import ElasticBeanstalkIcon from './purpleIcons/Elastic-Beanstalk-Application-Orchestration-Purple-128x128.png';
import ElasticLoadBalancerIcon from './purpleIcons/Elastic-Load-Balancer-Purple-128x128.png';
import ElasticMapReduceIcon from './purpleIcons/Elastic-Map-Reduce-Purple-128x128.png';
import ElastiCacheIcon from './purpleIcons/Elasticache-Purple-128x128.png';
import ElasticCacheReplicationGroupIcon from './purpleIcons/Elasticache-Replication-Group-Purple-128x128.png';
import ElasticSearchIcon from './purpleIcons/Elasticsearch-Purple-128x128.png';
import IAMGroupIcon from './purpleIcons/Group-Purple-128x128.png';
import HighPrivileges from './purpleIcons/high-privileges-purple.png';
import CloudFormationIcon from './purpleIcons/Infrastructure-As-Code-Cloudformation-Purple-128x128.png';
import InternetAccessibleIcon from './purpleIcons/Internet accessible.png';
import InternetGatewayIcon from './purpleIcons/Internet-Gateway-Purple-128x128.png';
import ElasticIPIcon from './purpleIcons/Ip-Address-Purple-128x128.png';
import ElasticKubernetesServiceIcon from './purpleIcons/Kubernetes-Purple-128x128.png';
import MaliciousIngress from './purpleIcons/malicious-ingress-purple.png';
import Namespace from './purpleIcons/Namespace-Purple-128x128.png';
import NATGatewayIcon from './purpleIcons/NAT-Gateway-Purple-128x128.png';
import NeptuneClusterIcon from './purpleIcons/Neptune-Cluster-Purple-128x128.png';
import NeptuneIcon from './purpleIcons/Neptune-Purple-128x128.png';
import NetworkAccessControlIcon from './purpleIcons/Network-ACL-Purple-128x128.png';
import NetworkInterfaceIcon from './purpleIcons/Network-Interface-Purple-128x128.png';
import OktaGroupIcon from './purpleIcons/Okta-Purple-128x128.png';
import OktaUserIcon from './purpleIcons/Okta-Purple-128x128.png';
import OktaOrganizationIcon from './purpleIcons/Okta-Purple-128x128.png';
import OpenAIIcon from './purpleIcons/openai-logomark.png';
import AWSOrganizationIcon from './purpleIcons/Organization-Purple-128x128.png';
import OrganizationUnitIcon from './purpleIcons/Organization-Unit-Purple-128x128.png';
import VPCPeeringConnectionIcon from './purpleIcons/P2p-Purple-128x128.png';
import PermissionsIcon from './purpleIcons/Permissions-Actions-Purple-128x128.png';
import Pods from './purpleIcons/Pods-Purple-128x128.png';
import PolicyIcon from './purpleIcons/Policy-Purple-128x128.png';
import PrefixListIcon from './purpleIcons/Prefix-List-Purple-128x128.png';
import RDSCertificateIcon from './purpleIcons/Rds-Certificate-Purple-128x128.png';
import RDSClusterIcon from './purpleIcons/Rds-Cluster-Purple-128x128.png';
import RDSIcon from './purpleIcons/Rds-Purple-128x128.png';
import RDSSnapshotIcon from './purpleIcons/Rds-Snapshot-Purple-128x128.png';
import RedshiftClusterIcon from './purpleIcons/Redshift-Purple-128x128.png';
import IAMRoleIcon from './purpleIcons/Role-Purple.png';
import RouteIcon from './purpleIcons/Route-Purple-128x128.png';
import SubnetRouteTableIcon from './purpleIcons/Route-Table-Purple-128x128.png';
import TransitGatewayRouteTableIcon from './purpleIcons/Route-Table-Purple-128x128.png';
import Route53HealthCheckIcon from './purpleIcons/Route53-Health-Check-Purple-128x128.png';
import Route53HostedZoneIcon from './purpleIcons/Route53-Hosted-Zone-Purple-128x128.png';
import Route53RecordSet from './purpleIcons/Route53-Record-Set-Purple-128x128.png';
import S3Icon from './purpleIcons/S3-Object-Storage-Purple-128x128.png';
import SagemakerEndpointsIcon from './purpleIcons/Sagemaker-Machine-Learning-Purple-128x128.png';
import SagemakerNotebookInstancesIcon from './purpleIcons/Sagemaker-Machine-Learning-Purple-128x128.png';
import SecretIcon from './purpleIcons/Secrets-Password-Purple-128x128.png';
import SecurityGroupIcon from './purpleIcons/Security-Group-Purple-128x128.png';
import DatabaseSecurityGroupIcon from './purpleIcons/Security-Group-Purple-128x128.png';
import AWSLambdaIcon from './purpleIcons/Serverless-Purple-128x128.png';
import ResourceTypeIcons from './purpleIcons/services-purple.png';
import SNSIcon from './purpleIcons/Sns-Purple-128x128.png';
import SQLDatabaseIcon from './purpleIcons/Sql-Database-Purple-128x128.png';
import SQSIcon from './purpleIcons/Sqs-Purple-128x128.png';
import SSHKeyPairIcon from './purpleIcons/Ssh-Key-Pair-Purple-128x128.png';
import SubnetIcon from './purpleIcons/Subnet-Purple-128x128.png';
import TransitGatewayAttachmentIcon from './purpleIcons/Transit-Gateway-Attachment-Purple-128x128.png';
import UnencryptedDiskIcon from './purpleIcons/Unencrypted disk.png';
import IAMUserIcon from './purpleIcons/User-Purple-128x128.png';
import ViewIcon from './purpleIcons/View.png';
import EC2Icon from './purpleIcons/Virtual-Machine-Purple-128x128.png';
import VpcFlowLogIcon from './purpleIcons/Vpc-Flow-Log-Purple-128x128.png';
import VirtualPrivateCloudIcon from './purpleIcons/Vpc-Purple-128x128.png';
import VpnGatewayIcon from './purpleIcons/Vpn-Gateway-Purple-128x128.png';
import VpnIcon from './purpleIcons/Vpn-Purple-128x128.png';
import VulnerabilityIcon from './purpleIcons/Vulnerability-Purple-128x128.png';
import WAFIcon from './purpleIcons/Web-Application-Firewall-(WAF)-Purple-128x128.png';

export const getPurpleMapIcon = (type: IconType) => {
  switch (type) {
    case IconTypes.View:
      return ViewIcon;
    case IconTypes.Process:
      return AppsPurpleIcon;
    case IconTypes.KeyManagementService:
    case IconTypes.IamUserAccessKey:
    case ContextType.CredentialFound:
    case NativeResources.GcpCloudKms:
      return KMSIcon;
    case IconTypes.Snowflake:
    case IconTypes.SnowflakeTables:
    case IconTypes.SnowflakeRoles:
    case IconTypes.SnowflakePrivileges:
    case IconTypes.SnowflakeUsers:
    case IconTypes.SnowflakeDatabases:
    case IconTypes.SnowflakeSchemas:
      return SnowflakeIcon;
    case ContextType.UnencryptedDisk:
    case ContextType.UnencryptedData:
      return UnencryptedDiskIcon;
    case ContextType.InternetAccess:
      return InternetAccessibleIcon;
    case ContextType.CriticalVulnerability:
      return VulnerabilityIcon;
    case ContextType.HighVulnerability:
      return VulnerabilityIcon;
    case ContextType.SensitiveData:
      return CriticalData;
    case ContextType.BruteForceAttack:
      return BruteForceAttack;
    case ContextType.CrossAccount:
      return CrossAccount;
    case ContextType.Privileged:
      return HighPrivileges;
    case ContextType.MaliciousIngress:
      return MaliciousIngress;
    case IconTypes.ResourceType:
    case 'ResourceType':
      return ResourceTypeIcons;
    case IconTypes.Account:
    case IconTypes.GcpServiceAccount:
      return AccountIcon;
    case NativeResources.AccountRoot:
      return AccountRootIcon;
    case NativeResources.AcmCertificate:
    case NativeResources.GcpsqlsslCerts:
      return ACMCertificateIcon;
    case NativeResources.AwsConfigRule:
      return ConfigurationIcon;
    case NativeResources.AmazonMachineImage:
    case NativeResources.ElasticContainerRegistry:
    case NativeResources.GcpImage:
      return AmazonMachineImageIcon;
    case NativeResources.ApiGatewayRestApi:
    case NativeResources.GcpapiGateway:
      return APIGatewayRestAPIIcon;
    case NativeResources.AsgResourcePolicy:
    case NativeResources.IamPolicy:
    case NativeResources.IamInlinePolicy:
    case NativeResources.OrganizationPolicy:
    case IconTypes.Policy:
    case NativeResources.AccountPasswordIdentityPolicy:
    case NativeResources.ResourcePolicy:
    case NativeResources.GcpiamPolicy:
    case NativeResources.GcpResourcePolicy:
    case NativeResources.GcpOrgPolicyService:
      return PolicyIcon;
    case NativeResources.AwsAccount:
      return AWSAccountIcon;
    case NativeResources.LambdaFunction:
    case NativeResources.GcpCloudFunctions:
      return AWSLambdaIcon;
    case NativeResources.AwsOrganization:
    case NativeResources.GcpOrganization:
      return AWSOrganizationIcon;
    case NativeResources.OrganizationUnit:
    case NativeResources.GcpFolder:
      return OrganizationUnitIcon;
    case NativeResources.AzureAdGroup:
      return AzureADGroupIcon;
    case NativeResources.AzureAdOrganization:
      return AzureADOrganizationIcon;
    case NativeResources.AzureAdUser:
      return AzureADUserIcon;
    case NativeResources.BackupPlan:
    case NativeResources.GcpFilestoreBackup:
      return BackupIcon;
    case NativeResources.CloudFormation:
    case NativeResources.GcpCloudDeploymentManager:
      return CloudFormationIcon;
    case NativeResources.CloudFront:
      return CloudFrontIcon;
    case NativeResources.CloudTrail:
    case NativeResources.GcpCloudLogMetrics:
      return CloudTrailIcon;
    case NativeResources.CloudWatchLogGroup:
    case NativeResources.CloudWatchAlarms:
    case NativeResources.CloudWatchLogs:
    case NativeResources.CloudWatchMetrics:
    case NativeResources.CloudWatchMetricAlarms:
      return CloudWatchLogGroupIcon;
    case NativeResources.Container:
    case NativeResources.EcsContainerInstance:
    case NativeResources.GcpContainer:
    case NativeResources.AzureContainer:
      return EcsInstanceIcon;
    case NativeResources.DatabaseSecurityGroup:
      return DatabaseSecurityGroupIcon;
    case NativeResources.DhcpOptions:
      return DHCPOptionsIcon;
    case NativeResources.DocumentDb:
    case NativeResources.GcpFirestore:
      return DocumentDBIcon;
    case NativeResources.DocumentDbCluster:
      return DocumentDBClusterIcon;
    case NativeResources.DynamoDb:
      return DynamoDBIcon;
    case NativeResources.ElasticBlockStorage:
    case NativeResources.GcpPersistentDisk:
      return EBSIcon;
    case NativeResources.EbsSnapshot:
      return EBSSnapshotIcon;
    case NativeResources.Ec2Instance:
    case NativeResources.GcpComputeEngine:
    case NativeResources.GcpCloudTpus:
    case NativeResources.AzureVirtualMachine:
      return EC2Icon;
    case IconTypes.EcrImage:
      return EcrImageIcon;
    case NativeResources.SnowflakeUsers:
      return SnowflakeIcon;
    case NativeResources.SnowflakeRoles:
      return SnowflakeIcon;
    case NativeResources.SnowflakePrivileges:
      return SnowflakeIcon;
    case NativeResources.Ec2AutoScalingGroup:
    case NativeResources.GcpComputeEngineAutoscaler:
      return EC2AutoScalingGroupIcon;
    case NativeResources.Ec2LaunchTemplate:
      return EC2LaunchTemplateIcon;
    case NativeResources.EcsCluster:
      return ECSClusterIcon;
    case NativeResources.EcsService:
    case NativeResources.ElasticContainerService:
      return ECSServiceIcon;
    case NativeResources.ElasticBeanstalk:
      return ElasticBeanstalkIcon;
    case NativeResources.ElasticFileSystem:
    case NativeResources.GcpFilestore:
      return ElasticFileSystemIcon;
    case NativeResources.ElasticIp:
      return ElasticIPIcon;
    case NativeResources.ElasticKubernetesService:
    case NativeResources.Gcpgke:
      return ElasticKubernetesServiceIcon;
    case NativeResources.ElasticLoadBalancer:
    case NativeResources.GcpurlMaps:
    case NativeResources.ElasticLoadBalancerv2:
      return ElasticLoadBalancerIcon;
    case NativeResources.ElasticMapReduce:
      return ElasticMapReduceIcon;
    case NativeResources.ElastiCache:
    case NativeResources.GcpMemorystoreRedis:
      return ElastiCacheIcon;
    case NativeResources.ElastiCacheReplicationGroup:
      return ElasticCacheReplicationGroupIcon;
    case NativeResources.ElasticSearch:
      return ElasticSearchIcon;
    case NativeResources.TransitGatewayAttachment:
      return TransitGatewayAttachmentIcon;
    case NativeResources.IamGroup:
    case NativeResources.GcpGroup:
      return IAMGroupIcon;
    case NativeResources.IamRole:
    case NativeResources.GcpRole:
      return IAMRoleIcon;
    case NativeResources.IamUser:
    case NativeResources.GcpUser:
    case 'User':
      return IAMUserIcon;
    case NativeResources.InternetGateway:
      return InternetGatewayIcon;
    case NativeResources.NatGateway:
    case NativeResources.GcpCloudNat:
      return NATGatewayIcon;
    case NativeResources.Neptune:
      return NeptuneIcon;
    case NativeResources.NeptuneCluster:
      return NeptuneClusterIcon;
    case NativeResources.NetworkAcl:
      return NetworkAccessControlIcon;
    case NativeResources.ElasticNetworkInterface:
    case NativeResources.VirtualInterface:
    case NativeResources.GcpNetworkInterface:
      return NetworkInterfaceIcon;
    case NativeResources.OktaGroup:
      return OktaGroupIcon;
    case NativeResources.OktaOrganization:
      return OktaOrganizationIcon;
    case NativeResources.OktaUser:
      return OktaUserIcon;
    case NativeResources.PrefixList:
      return PrefixListIcon;
    case IconTypes.Permission:
    case ContextType.ExcessPermissions:
    case 'Permission Type':
    case 'PermissionType':
      return PermissionsIcon;
    case NativeResources.RelationalDatabaseService:
    case NativeResources.GcpCloudSql:
      return RDSIcon;
    case NativeResources.RdsCertificate:
      return RDSCertificateIcon;
    case NativeResources.RdsCluster:
      return RDSClusterIcon;
    case NativeResources.RdsSnapshot:
    case NativeResources.RdsClusterSnapshot:
      return RDSSnapshotIcon;
    case NativeResources.RedshiftCluster:
    case NativeResources.GcpBigQuery:
      return RedshiftClusterIcon;
    case NativeResources.Route53HealthCheck:
      return Route53HealthCheckIcon;
    case NativeResources.Route53HostedZone:
    case IconTypes.Route53SubDomain:
    case NativeResources.GcpCloudDnsZone:
    case NativeResources.GcpCloudDomainsRegistration:
      return Route53HostedZoneIcon;
    case NativeResources.SimpleStorageService:
    case NativeResources.GcpBucket:
      return S3Icon;
    case NativeResources.SagemakerEndpoints:
      return SagemakerEndpointsIcon;
    case NativeResources.SagemakerNotebookInstances:
      return SagemakerNotebookInstancesIcon;
    case NativeResources.Secret:
    case NativeResources.GcpSecretManager:
    case NativeResources.AzureSecrets:
      return SecretIcon;
    case NativeResources.SecurityGroup:
    case NativeResources.GcpCloudFirewall:
      return SecurityGroupIcon;
    case NativeResources.SimpleNotificationService:
    case NativeResources.GcpPubSubLite:
      return SNSIcon;
    case NativeResources.SimpleQueueService:
    case NativeResources.GcpCloudTasksQueue:
      return SQSIcon;
    case NativeResources.SshKeyPair:
      return SSHKeyPairIcon;
    case NativeResources.Subnet:
    case NativeResources.GcpSubnetwork:
      return SubnetIcon;
    case NativeResources.SubnetRouteTable:
      return SubnetRouteTableIcon;
    case NativeResources.SystemsManager:
      return AgentIcon;
    case NativeResources.SystemsManagerDocument:
      return SystemsManagerDocumentIcon;
    case NativeResources.TransitGatewayRouteTable:
      return TransitGatewayRouteTableIcon;
    case NativeResources.VirtualPrivateCloud:
    case NativeResources.GcpVirtualPrivateCloud:
      return VirtualPrivateCloudIcon;
    case NativeResources.VpcPeeringConnection:
    case NativeResources.TransitGatewayConnect:
      return VPCPeeringConnectionIcon;
    case NativeResources.AwsVirtualPrivateNetwork:
      return VpnIcon;
    case NativeResources.VpcFlowLogs:
      return VpcFlowLogIcon;
    case NativeResources.VpnGateway:
    case NativeResources.GcpvpnGateway:
      return VpnGatewayIcon;
    case NativeResources.Wafv1:
    case NativeResources.Wafv2:
    case NativeResources.WafRegional:
      return WAFIcon;
    case NativeResources.GcpRoute:
      return RouteIcon;
    case IconTypes.Internet:
      return InternetIcon;
    case IconTypes.Resource:
      return ComputeIcon;
    case IconTypes.Route53RecordSet:
      return Route53RecordSet;
    case IconTypes.Pod:
      return Pods;
    case IconTypes.Namespace:
      return Namespace;
    case IconTypes.Console:
      return ConsolePurple;
    case NativeResources.GcpsqlInstances:
      return SQLDatabaseIcon;
    case IconTypes.OpenAI:
      return OpenAIIcon;
    default:
      return '';
  }
};
