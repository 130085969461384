import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

import { overviewDashboardWidgets } from './widget';

export const identityDashboardConfig: IDashboardLayout = {
  id: 'overview_dashboard',
  title: 'Overview Dashboard',
  widgets: [
    {
      type: 'card',
      i: 'identities',
      x: 0,
      y: 0,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'cloudUsers',
      x: 0,
      y: 3.8,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'nonUsers',
      x: 0,
      y: 7.6,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'identityRoles',
      x: 0,
      y: 11.4,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'federatedUsers',
      x: 0,
      y: 15.2,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'saasUsers',
      x: 0,
      y: 19,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'findingsToPrioritize',
      x: 2.4,
      y: 0,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'identitiesHistory',
      x: 5.6,
      y: 0,
      w: 6.4,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'insightsForNonHumanIdentities',
      x: 2.4,
      y: 7.6,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'identitiesWithHighPrivileges',
      x: 5.6,
      y: 7.6,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'identitiesWithAccessToSensitiveData',
      x: 8.8,
      y: 7.6,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'identitiesWithExcessPermissions',
      x: 2.4,
      y: 15.2,
      w: 4.8,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'jitAccessRequests',
      x: 7.2,
      y: 15.2,
      w: 4.8,
      h: 7.6,
      isResizable: false,
      static: true,
    },
  ],
  available: overviewDashboardWidgets,
};
