import React, { useEffect, useReducer, useState } from 'react';

import {
  InsightV2ApiInsightV2Request,
  NativeResources,
} from '@ariksa/inventory-core/api';
import { Box, Center, Stack, HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  each,
  filter,
  forEach,
  isEmpty,
  isEqual,
  some,
  sortBy,
  toArray,
} from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { QueryStatus } from 'services/types';

import { InventoryService } from 'api/services';
import {
  Card,
  getIcon,
  PageHeaderWithIcon,
  renderTableHeaderWithLoader,
  renderTime,
  renderTimeStackedCell,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import {
  ActionButton,
  AddButton,
  DeleteActionButton,
  Select,
} from 'components/DataEntry';
import {
  DataScanIcon,
  IconTypes,
  OutpostForVulnerabilityIcon,
  RefreshIcon,
} from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { Menu } from 'components/Overlay';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { OutpostSubComponent } from 'containers/Setup/Outpost/Components/OutpostSubComponent';
import {
  outpostStatusOptions,
  outpostTypeOptions,
} from 'containers/Setup/Outpost/Components/utils';
import { selectOutpost } from 'containers/Setup/Outpost/selectors';

import { actions } from '../slice';

export const OutpostTable: React.FC = () => {
  const { contentHeight } = usePageContentContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { vulnerabilityOutposts, dataOutposts, resourceDetails } = useSelector(
    selectOutpost,
  );
  const { accountMapping } = useCloudAccountId();
  const { accountEnvironmentMapping } = useEnvironmentOptions();
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [filteredData, setFilteredData] = useState<Record<string, any>[]>([]);

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      status: outpostStatusOptions[0],
      region: { label: 'All regions', value: undefined },
      regionOptions: [],
      outpostType: outpostTypeOptions[0],
      account: { label: 'All accounts', value: undefined },
      accountOptions: [],
      sortByField: {
        sortField: 'last_seen',
        sortOrder: 'desc',
      },
      sortedData: [],
      row: {},
    },
  );

  useDeepCompareEffect(() => {
    dispatch(actions.getVulnerabilityScanners({ q: {} }));
    dispatch(actions.getDataScanners({ q: {} }));
  }, [dispatch]);

  useEffect(() => {
    let items: Record<string, any>[] = [];
    forEach(dataOutposts.data, (o, id) => {
      const account = accountMapping?.[id];
      const environment = accountEnvironmentMapping?.[id];
      items = [
        ...items,
        ...map(o, outpost => ({
          ...outpost,
          type: 'Data',
          cloud: account?.cloud_type,
          account_name: account?.name,
          cloud_account_id: account?.cloud_account_id,
          account_id: id,
          environment_name: environment?.name,
          native_name: o?.native_type,
        })),
      ];
    });
    forEach(vulnerabilityOutposts.data, (o, id) => {
      const account = accountMapping?.[id];
      const environment = accountEnvironmentMapping?.[id];
      items = [
        ...items,
        ...map(o, outpost => ({
          ...outpost,
          type: 'Vulnerability',
          cloud: account?.cloud_type,
          account_name: account?.name,
          cloud_account_id: account?.cloud_account_id,
          account_id: id,
          environment_name: environment?.name,
          instance_id: outpost?.scanner_id,
        })),
      ];
    });
    setData(items);
  }, [
    dataOutposts.data,
    accountMapping,
    accountEnvironmentMapping,
    vulnerabilityOutposts.data,
  ]);

  useEffect(() => {
    if (isEmpty(data)) return;
    let resources: Record<string, any> = {};
    each(data, o => {
      if (!o?.native_name) return;
      if (!!resources?.[o?.native_name]) {
        resources[o?.native_name] = {
          ids: [...resources?.[o?.native_name]?.ids, o?.instance_id],
          account_ids: [
            ...resources?.[o?.native_name]?.account_ids,
            o.account_id,
          ],
        };
      } else {
        resources[o?.native_name] = {
          ids: [o?.instance_id],
          account_ids: [o.account_id],
        };
      }
    });

    //get resource details
    forEach(resources, (items, key) => {
      const insightPayload: InsightV2ApiInsightV2Request = {
        insightV2Request: {
          resource_type: key as NativeResources,
          resource_ids: items?.ids,
          account_id: items?.account_ids,
        },
      };
      dispatch(
        actions.getResourceDetails({
          q: insightPayload,
          status: QueryStatus.pending,
        }),
      );
      InventoryService.InsightV2.insightV2(insightPayload).then(res => {
        dispatch(
          actions.getResourceDetails({
            q: insightPayload,
            status: QueryStatus.fulfilled,
            data: res.data,
          }),
        );
      });
    });
  }, [data, dispatch]);

  //set region options
  useEffect(() => {
    let regions = new Set();
    each(data, o => regions.add(o.region));
    updateState({
      regionOptions: [
        { label: 'All regions', value: undefined },
        ...map(toArray(regions), o => ({ label: o, value: o })),
      ],
    });
  }, [data]);

  //set account options
  useEffect(() => {
    let accounts = new Set();
    each(data, o => accounts.add(o.account_id));
    updateState({
      accountOptions: [
        { label: 'All accounts', value: undefined },
        ...map(toArray(accounts) as string[], o => ({
          label:
            accountMapping[o]?.name +
            ' (Account ID: ' +
            accountMapping[o]?.cloud_account_id +
            ')',
          value: o,
          icon: getIcon(accountMapping[o]?.cloud_type!),
        })),
      ],
    });
  }, [data, accountMapping]);

  //filter data
  useEffect(() => {
    const filtered_data = filter(
      data,
      o =>
        o.status === state.status?.value &&
        (!!state.region?.value ? o.region === state.region?.value : true) &&
        (!!state.account?.value
          ? o.account_id === state.account?.value
          : true) &&
        (!!state.outpostType?.value
          ? o.type === state.outpostType?.value
          : true),
    );
    setFilteredData(filtered_data);
  }, [data, state.status, state.region, state.outpostType, state.account]);

  //sort filtered data
  useEffect(() => {
    updateState({
      sortedData: sortBy(filteredData, d => -dayjs(d.last_seen)),
    });
  }, [filteredData]);

  const columns = [
    {
      header: (
        <Box pl={10}>
          {renderTableHeaderWithLoader(
            'Name',
            some(resourceDetails, { isLoading: true }),
          )}
        </Box>
      ),
      accessor: 'name',
      align: 'left',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={
            row?.type === 'Data'
              ? IconTypes.DataScan
              : IconTypes.OutpostForVulnerability
          }
        >
          <StackedCell
            upper={
              resourceDetails?.[row?.native_name]?.data?.[row?.instance_id]
                ?.name
            }
            lower={row.instance_id}
          />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Deployed in',
      accessor: 'cloud',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={
            <HStack align="baseline">
              <Box boxSize={5}>
                <Center>{getIcon(row?.cloud)}</Center>
              </Box>
              <Box>{row?.account_name ?? row?.cloud_account_id}</Box>
            </HStack>
          }
          lower={row?.region}
        />
      ),
    },
    {
      header: 'Type',
      accessor: 'type',
      align: 'left',
    },
    /*{
      header: 'Deployed In',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={row?.region}
          lower={
            resourceDetails.data?.[row?.instance_id]?.vpc ||
            resourceDetails.data?.[row?.instance_id]?.vpc_id
          }
        />
      ),
    },*/
    {
      header: 'Created',
      accessor: 'first_seen',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Last Sync',
      accessor: 'last_seen',
      align: 'left',
      render: renderTimeStackedCell,
    },
    {
      header: 'Status',
      accessor: 'status',
      align: 'left',
      render: ({ value }) => (
        <Box
          borderRadius={4}
          w={16}
          bg={value === 'Inactive' ? 'critical' : 'green.300'}
          color="white"
          fontSize="xs"
        >
          <Center>{value?.toUpperCase()}</Center>
        </Box>
      ),
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: ({ row }) => {
        return (
          <Center>
            {row?.type === 'Vulnerability' && (
              <ActionButton
                icon={<RefreshIcon />}
                onClick={() => {
                  dispatch(
                    actions.rescanResources({
                      q: {
                        scannerStatus: {
                          account_id: row.account_id,
                          region: row?.region,
                          scanner_id: row?.scanner_id,
                          settings: { ...row?.settings, restart: true },
                          id: row?.id,
                          first_seen: row?.first_seen,
                          last_seen: row?.last_seen,
                        },
                      },
                    }),
                  );
                }}
                label={'Rescan resources'}
              />
            )}
            <DeleteActionButton isDisabled onConfirm={() => {}} name={''} />
          </Center>
        );
      },
    },
  ];

  const menuItems = [
    {
      label: 'CHOOSE OUTPOST TYPE',
      group: {
        title: 'CHOOSE OUTPOST TYPE',
        list: [
          {
            key: 'data',
            label: 'Outpost for Data',
            icon: <DataScanIcon />,
            onClick: () => navigate('/setup/outpost/add/data'),
          },
          {
            key: 'vulnerability',
            label: 'Outpost for Vulnerability',
            icon: <OutpostForVulnerabilityIcon />,
            onClick: () => navigate('/setup/outpost/add/vulnerability'),
          },
        ],
      },
    },
  ];

  /*{...(state.row?.type === 'Vulnerability'
              ? {
                  subComponent: ({ row, expanded }) =>
                    expanded && (
                      <SubComponentContainer>
                        <Stack pl={10}>
                          <H3>Accounts being scanned by this Outpost</H3>
                          <HStack></HStack>
                        </Stack>
                      </SubComponentContainer>
                    ),
                }
              : {})}*/

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <HStack w="full" justify="space-between">
          <PageHeaderWithIcon label="Outpost" icon={<DataScanIcon />} />
          <HStack>
            <Box w={48} zIndex={900}>
              <Select
                options={outpostStatusOptions}
                value={state.status}
                onChange={s => updateState({ status: s })}
                showIconInValueContainer
                isDisabled={
                  vulnerabilityOutposts.isLoading || dataOutposts.isLoading
                }
                //styles={styles}
              />
            </Box>
            <Box w={48} zIndex={900}>
              <Select
                options={state.regionOptions}
                value={state.region}
                onChange={s => updateState({ region: s })}
                showIconInValueContainer
                //styles={styles}
                isDisabled={
                  vulnerabilityOutposts.isLoading || dataOutposts.isLoading
                }
              />
            </Box>
            <Box w={48} zIndex={900}>
              <Select
                options={outpostTypeOptions}
                value={state.outpostType}
                onChange={s => updateState({ outpostType: s })}
                showIconInValueContainer
                //styles={styles}
                isDisabled={
                  vulnerabilityOutposts.isLoading || dataOutposts.isLoading
                }
              />
            </Box>
            <Box w={48} zIndex={900}>
              <Select
                options={state.accountOptions}
                value={state.account}
                onChange={s => updateState({ account: s })}
                showIconInValueContainer
                styles={{
                  menu: provided => ({
                    ...provided,
                    width: 'max-content',
                    minWidth: '100%',
                    right: 0,
                  }),
                }}
                isDisabled={
                  vulnerabilityOutposts.isLoading || dataOutposts.isLoading
                }
              />
            </Box>
            <Menu
              menuItems={menuItems}
              buttonLabel={<AddButton label="Add Outpost" />}
            />
          </HStack>
        </HStack>
        <Box flex={1}>
          <Table
            data={state.sortedData}
            columns={columns}
            isLoading={
              vulnerabilityOutposts.isLoading || dataOutposts.isLoading
            }
            styles={{ header: { zIndex: 800 } }}
            onSort={sortInfo => {
              if (!isEqual(state.sortByField, sortInfo)) {
                updateState({ sortByField: sortInfo });
              }
            }}
            onRowClick={row => {
              updateState({ row });
            }}
            subComponent={({ row, expanded }) => (
              <OutpostSubComponent row={row} isExpanded={expanded} />
            )}
          />
        </Box>
      </Stack>
    </Card>
  );
};
