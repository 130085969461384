import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { each, map, sum } from 'lodash';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { UserIcon } from 'components/Icons';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { CardWithLineChart } from 'containers/Dashboard/utils/CardWithLineChart';
import { formatDate } from 'utils/date';

export const IdentitiesHistory: React.FC = () => {
  const { inventorySummary } = useSelector(selectDashboard);
  const { identitiesHistory } = useSelector(selectIdentityOverview);
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [change, setChange] = useState(0);

  useEffect(() => {
    const items = map(identitiesHistory.data?.users, (count, date) => ({
      Date: formatDate(date, 'D-MMM'),
      Users: count,
      Roles: identitiesHistory.data?.roles?.[date],
      'Non-Users': identitiesHistory.data?.non_user_resources?.[date],
    }));
    setChartData(items);
    const first =
      (items?.[0]?.Users ?? 0) +
      (items?.[0]?.Roles ?? 0) +
      (items?.[0]?.['Non-Users'] ?? 0);
    const last =
      (items?.[items?.length - 1]?.Users ?? 0) +
      (items?.[items?.length - 1]?.['Non-Users'] ?? 0) +
      (items?.[items?.length - 1]?.Roles ?? 0);
    setChange(
      first === 0 && last > 0
        ? 100
        : first === 0
        ? 0
        : round(((last - first) / first) * 100, 0),
    );
  }, [identitiesHistory.data]);

  const getSum = value => sum(map(value, o => o));

  const lineChartColor = useMemo(
    () => ({
      'Non-Users': customTheme.colors.skyBlue['300'],
      Users: customTheme.colors.blue['300'],
      Roles: customTheme.colors.primary,
    }),
    [],
  );

  const getItems = useCallback(
    type => {
      let items: Record<string, any> = {};
      each(inventorySummary.data?.items?.[type], o =>
        each(o, (v, k) => (items[k] = v)),
      );
      return items;
    },
    [inventorySummary.data],
  );

  const legends = useMemo(
    () => [
      {
        label: 'Users',
        count: getSum(getItems('User')) ?? 0,
        isLoading: inventorySummary.isLoading,
        color: lineChartColor.Users,
      },
      {
        label: 'Roles',
        count: getSum(getItems('Role')) ?? 0,
        isLoading: inventorySummary.isLoading,
        color: lineChartColor.Roles,
      },
      {
        label: 'Non-Users',
        count: getSum(getItems('Non-users')) ?? 0,
        isLoading: inventorySummary.isLoading,
        color: lineChartColor['Non-Users'],
      },
    ],
    [inventorySummary.isLoading, lineChartColor, getItems],
  );
  return (
    <CardWithLineChart
      label="Cloud identities"
      icon={<UserIcon />}
      lineChartColor={lineChartColor}
      yFields={['Users', 'Roles', 'Non-Users']}
      chartData={chartData}
      isLoadingChart={identitiesHistory.isLoading}
      legends={legends}
      change={change}
      showLegendAtTop
      legendsSpacing={8}
    />
  );
};
