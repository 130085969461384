import React from 'react';

import { Grid, GridItem, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { colorOpacity, customTheme } from 'theme';

import { formatTooltip } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { VulnerabilityIcon } from 'components/Icons';
import { CDashboard } from 'components/Visualization';
import { IDashboardLayout } from 'components/Visualization/CDashboard/types';
import { CloudCompliance } from 'containers/Dashboard/Overview/Components/CloudCompliance';
import { DataSourcesSummaryCard } from 'containers/Dashboard/Overview/Components/DataSourcesSummaryCard';
import { GovernanceScore } from 'containers/Dashboard/Overview/Components/GovernanceScore';
import { Identities } from 'containers/Dashboard/Overview/Components/Identities';
import { Infrastructure } from 'containers/Dashboard/Overview/Components/Infrastructure';
import { OpenAlertsAndResolutions } from 'containers/Dashboard/Overview/Components/OpenAlertsAndResolutions';
import { PolicyManagement } from 'containers/Dashboard/Overview/Components/PolicyManagement';
import { OverviewCardSpacing } from 'containers/Dashboard/types';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';
import { Containers } from 'containers/Dashboard/VulnerabilityOverview/Components/Containers';
import { ContainerService } from 'containers/Dashboard/VulnerabilityOverview/Components/ContainerService';
import { EndOfLifeSoftware } from 'containers/Dashboard/VulnerabilityOverview/Components/EndOfLifeSoftware';
import { InternetExposedHosts } from 'containers/Dashboard/VulnerabilityOverview/Components/InternetExposedHosts';
import { MostWidelyDeployedLibraries } from 'containers/Dashboard/VulnerabilityOverview/Components/MostWidelyDeployedLibraries';
import { NoVulnerabilityScannerDeployed } from 'containers/Dashboard/VulnerabilityOverview/Components/NoVulnerabilityScannerDeployed';
import { Serverless } from 'containers/Dashboard/VulnerabilityOverview/Components/Serverless';
import { TopNewVulnerabilities } from 'containers/Dashboard/VulnerabilityOverview/Components/TopNewVulnerabilities';
import { VirtualMachines } from 'containers/Dashboard/VulnerabilityOverview/Components/VirtualMachines';
import { VMImages } from 'containers/Dashboard/VulnerabilityOverview/Components/VMImages';
import { VulnerabilitiesPrioritization } from 'containers/Dashboard/VulnerabilityOverview/Components/VulnerabilitiesPrioritization';
import { Vulnerability } from 'containers/Dashboard/VulnerabilityOverview/Components/Vulnerability';
import { VulnerableHostsWithAccessToSensitiveData } from 'containers/Dashboard/VulnerabilityOverview/Components/VulnerableHostsWithAccessToSensitiveData';
import { selectSharedState } from 'containers/SharedState/selectors';

interface Props {
  showDashboard: boolean;
  vulnerabilityScannerCount: number;
}

export const Vulnerabilities: React.FC<Props> = props => {
  const { showDashboard, vulnerabilityScannerCount } = props;
  const { vulnerabilityScannerInfo } = useSelector(selectSharedState);

  const overviewDashboardWidgets = {
    summary: {
      flex: true,
      components: {
        content: (
          <Vulnerability
            vulnerabilityScannerCount={vulnerabilityScannerCount}
            isOutpostEnabled={showDashboard}
          />
        ),
      },
    },
    virtualMachines: {
      flex: true,
      components: {
        content: <VirtualMachines />,
      },
    },
    containers: {
      flex: true,
      components: {
        content: <Containers />,
      },
    },
    serverless: {
      flex: true,
      components: {
        content: <Serverless />,
      },
    },
    vmImages: {
      flex: true,
      components: {
        content: <VMImages />,
      },
    },
    ContainerService: {
      flex: true,
      components: {
        content: <ContainerService />,
      },
    },
    vulnerabilitiesPrioritization: {
      flex: true,
      components: {
        content: <VulnerabilitiesPrioritization />,
      },
    },
    topNewVulnerabilities: {
      flex: true,
      components: {
        content: <TopNewVulnerabilities />,
      },
    },
    internetExposedHosts: {
      flex: true,
      components: {
        content: <InternetExposedHosts />,
      },
    },
    vulnerableHostsWithAccessToSensitiveData: {
      flex: true,
      components: {
        content: (
          <VulnerableHostsWithAccessToSensitiveData
            scannerDeployed={showDashboard}
          />
        ),
      },
    },
    mostWidelyDeployedLibraries: {
      flex: true,
      components: {
        content: <MostWidelyDeployedLibraries />,
      },
    },
    endOfLifeSoftware: {
      flex: true,
      components: {
        content: <EndOfLifeSoftware />,
      },
    },
  };

  const vulnerabilityDashboardConfig: IDashboardLayout = {
    id: 'overview_dashboard',
    title: 'Overview Dashboard',
    widgets: [
      {
        type: 'card',
        i: 'summary',
        x: 0,
        y: 0,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'virtualMachines',
        x: 0,
        y: 3.8,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'containers',
        x: 0,
        y: 7.6,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'serverless',
        x: 0,
        y: 11.4,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'vmImages',
        x: 0,
        y: 15.2,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'ContainerService',
        x: 0,
        y: 19,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'vulnerabilitiesPrioritization',
        x: 2.4,
        y: 0,
        w: 4.8,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'topNewVulnerabilities',
        x: 7.2,
        y: 0,
        w: 4.8,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'internetExposedHosts',
        x: 2.4,
        y: 7.6,
        w: 4.8,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'vulnerableHostsWithAccessToSensitiveData',
        x: 7.2,
        y: 7.6,
        w: 4.8,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'mostWidelyDeployedLibraries',
        x: 2.4,
        y: 15.2,
        w: 4.8,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'endOfLifeSoftware',
        x: 7.2,
        y: 15.2,
        w: 4.8,
        h: 7.6,
        isResizable: false,
        static: true,
      },
    ],
    available: overviewDashboardWidgets,
  };

  return (
    <DashboardOverviewWrapperCard
      label="Software Vulnerabilities"
      titleBgColor={colorOpacity(customTheme.colors.critical, 0.2)}
      content={
        vulnerabilityScannerInfo.isLoading ? (
          <CustomSpinner size="xl" />
        ) : showDashboard ? (
          <CDashboard dashboardWidgets={vulnerabilityDashboardConfig} />
        ) : (
          <NoVulnerabilityScannerDeployed />
        )
      }
      icon={<VulnerabilityIcon />}
    />
  );
};
