/**
 *
 * Jira
 *
 */

import React from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiAddClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../../slice';

import { JiraForm } from './JiraForm';

export const CreateJira = () => {
  const dispatch = useDispatch();
  const { onCancel } = useIntegrationsContext();

  const onCreate = data => {
    const { url, user, token, name, project_id, account_id, issue_type } = data;
    const payload: ClientsApiAddClientRequest = {
      clientConfig: {
        client_name: Clients.Jira,
        status: true,
        name,
        jira: {
          url,
          user,
          token,
          project_name: project_id.label,
          project_id: project_id.value,
          account_id: account_id?.value,
          default_issue_type: issue_type?.value,
        },
      },
    };
    dispatch(
      actions.createClient({
        q: payload,
        onSuccess: res => {
          onCancel();
        },
      }),
    );
  };

  return <JiraForm handleSubmit={onCreate} />;
};
