import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { forEach, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  CloudServiceIcon,
  DatabaseIcon,
  UserIcon,
  VulnerabilityIcon,
} from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { ResourceIconAndCountProps } from 'containers/Dashboard/types';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';

export const CriticalAndHighSeverityFindingsByCategory: React.FC = () => {
  const { openFindings } = useSelector(selectDashboard);
  const navigate = useNavigate();
  const [data, setData] = useState<ResourceIconAndCountProps[]>([]);

  const getTotal = items => {
    let total = 0;
    forEach(items, s => {
      total += (s.severity.CRITICAL ?? 0) + (s.severity.HIGH ?? 0);
    });
    return total;
  };

  const onRedirect = useCallback(
    tab =>
      navigate(
        '/findings?tab=' + tab + '&severity=CRITICAL,HIGH&redirect=true',
      ),
    [navigate],
  );

  const categories = useMemo(
    () => [
      {
        count: getTotal(
          openFindings.data?.[AlertCategoryClass.Vulnerabilities],
        ),
        onClick: () => onRedirect(AlertCategoryClass.Vulnerabilities),
        label: 'Vulnerabilities',
        icon: <VulnerabilityIcon />,
      },
      {
        count: getTotal(openFindings.data?.[AlertCategoryClass.CloudPosture]),
        onClick: () => onRedirect(AlertCategoryClass.CloudPosture),
        label: 'Cloud Posture',
        icon: <CloudServiceIcon />,
      },
      {
        count: getTotal(openFindings.data?.[AlertCategoryClass.DataSecurity]),
        onClick: () => onRedirect(AlertCategoryClass.DataSecurity),
        label: 'Data Security',
        icon: <DatabaseIcon />,
      },
      {
        count: getTotal(
          openFindings.data?.[AlertCategoryClass.IdentityAuthorization],
        ),
        onClick: () => onRedirect(AlertCategoryClass.IdentityAuthorization),
        label: 'Identity & Privileges ',
        icon: <UserIcon />,
      },
    ],
    [openFindings.data, onRedirect],
  );

  useEffect(() => {
    setData(sortBy(categories, o => o.count).reverse());
  }, [categories]);

  return (
    <DashboardWidget
      label="Critical and high severity findings"
      isLoading={openFindings.isLoading}
      content={renderResourcesWithIconAndCount(data)}
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
    />
  );
};
