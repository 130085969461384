import React from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectDashboard } from 'containers/Dashboard/selectors';
import { TopFindings } from 'containers/Dashboard/utils/TopFindings';

export const DataSecurityFindingsToPrioritize: React.FC = () => {
  const { findingsCategorySummary } = useSelector(selectDashboard);

  return (
    <TopFindings
      accounts={findingsCategorySummary.data}
      categoryClass="Data Security"
      tooltip={{
        header: 'You can enrich key findings…',
        tooltip: (
          <Stack>
            <Box>
              Ariksa can uncover deeper insights such as anomalous user behavior
              and privilege escalation attempts, if you enable user activity
              logs (such as AWS CloudTrail) and store them for analysis
            </Box>
            <Box>
              We prioritize these findings based on severity, context and risk
              to your environment
            </Box>
          </Stack>
        ),
        footerText: 'Ok',
      }}
    />
  );
};
