import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { reduce } from 'lodash';
import { useSelector } from 'react-redux';

import {
  formatNumberWithComma,
  WithResourceIcon,
} from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const DocumentsByTypes: React.FC = () => {
  const { dataSources } = useSelector(selectDataDashboard);
  const [total, setTotal] = useState(0);
  const { data, isLoading, page } = dataSources;

  useEffect(() => {
    setTotal(reduce(data, (sum, n) => sum + n?.count, 0));
  }, [data]);

  const columns = [
    {
      header: <Box pl={9}>SOURCE</Box>,
      accessor: 'region',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={'Documents'}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
          color="primary"
        >
          {row?.type_name}
        </WithResourceIcon>
      ),
      styles: { td: { w: '60%' } },
    },
    {
      header: 'SOURCE COUNT',
      render: ({ row }) => formatNumber(row.count, 1),
      styles: {
        td: { w: '40%' },
        cell: { textAlign: 'right', w: '58%' },
        header: { textAlign: 'center' },
      },
    },
  ];

  return (
    <DashboardWidget
      label="Documents By Types"
      subHeader={
        formatNumberWithComma(page.totalCount) +
        ' type' +
        (page.totalCount === 1 ? ' ' : 's, ') +
        formatNumberWithComma(total) +
        ' document' +
        (total === 1 ? '' : 's')
      }
      content={
        <DashboardTable columns={columns} data={data} isLoading={isLoading} />
      }
    />
  );
};
