import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AIInsightIcon = props => (
  <Icon viewBox="0 0 512 512" color="inherit" h="full" w="full" {...props}>
    <path
      d="M318.441,458.688l2.599,43.163c0.216,3.574-2.329,6.723-5.87,7.257c-12.68,1.919-25.658,2.892-38.564,2.892
	c-12.892,0-25.866-0.973-38.56-2.892c-3.541-0.534-6.09-3.683-5.874-7.255l2.225-37.062c-27.113-5.435-52.483-15.951-75.579-31.332
	l-24.622,27.78c-2.374,2.673-6.401,3.106-9.28,0.98c-20.829-15.341-39.177-33.69-54.537-54.537
	c-2.126-2.881-1.703-6.906,0.978-9.282l27.771-24.616c-15.379-23.091-25.897-48.463-31.334-75.588l-37.051,2.23
	c-3.532,0.218-6.716-2.329-7.257-5.872c-1.914-12.672-2.887-25.645-2.887-38.562c0-12.915,0.973-25.888,2.887-38.562
	c0.536-3.543,3.68-6.147,7.257-5.872l37.06,2.23c5.437-27.116,15.951-52.49,31.33-75.581l-27.776-24.622
	c-2.676-2.376-3.104-6.401-0.982-9.282c15.343-20.827,33.69-39.174,54.537-54.537c2.883-2.124,6.91-1.694,9.28,0.98l24.631,27.782
	c23.235-15.435,49.591-26.262,76.898-31.591L232.739,10.2c-0.293-3.631,2.275-6.867,5.879-7.403c25.055-3.73,50.92-3.73,75.975,0
	c3.604,0.536,6.171,3.773,5.879,7.403l-3.496,43.001c-0.306,3.811-3.685,6.649-7.455,6.336c-3.811-0.311-6.649-3.649-6.338-7.457
	l2.964-36.463c-19.577-2.383-39.506-2.383-59.083,0l2.96,36.46c0.293,3.592-2.225,6.809-5.779,7.39
	c-29.514,4.82-58.06,16.532-82.552,33.868c-2.883,2.041-6.838,1.579-9.171-1.056l-24.433-27.562
	C112.098,77.153,97.75,91.5,85.327,107.478l27.559,24.431c2.64,2.342,3.095,6.295,1.059,9.176
	c-17.298,24.44-28.613,51.74-33.632,81.142c-0.599,3.48-3.748,5.955-7.234,5.741l-36.771-2.212
	c-1.243,9.987-1.869,20.131-1.869,30.237c0,10.108,0.626,20.251,1.869,30.237l36.758-2.212c3.487-0.234,6.64,2.264,7.234,5.741
	c5.023,29.411,16.343,56.713,33.641,81.149c2.036,2.881,1.581,6.833-1.059,9.176l-27.55,24.424
	c12.433,15.994,26.78,30.339,42.758,42.76l24.433-27.562c2.333-2.64,6.288-3.099,9.171-1.059
	c24.447,17.3,51.745,28.616,81.146,33.636c3.478,0.595,5.951,3.712,5.739,7.234l-2.207,36.769c20.014,2.493,40.501,2.493,60.47,0
	l-2.212-36.767c-0.23-3.815,2.676-7.093,6.491-7.322C314.918,451.992,318.211,454.875,318.441,458.688z M466.967,159.27
	c11.086,0,20.109,9.021,20.109,20.109s-9.023,20.109-20.109,20.109c-2.547,0-4.965-0.525-7.21-1.393l-18.814,19.484
	c-1.306,1.349-3.099,2.113-4.978,2.113h-96.124c-3.82,0-6.919-3.097-6.919-6.919s3.099-6.919,6.919-6.919h93.187l16.348-16.932
	c-1.555-2.853-2.519-6.071-2.519-9.542C446.859,168.29,455.881,159.27,466.967,159.27z M460.697,179.379
	c0,3.457,2.811,6.27,6.27,6.27c3.46,0,6.27-2.813,6.27-6.27s-2.811-6.27-6.27-6.27C463.508,173.108,460.697,175.921,460.697,179.379
	z M391.826,141.071l25.647-36.884c-2.59-3.385-4.192-7.564-4.192-12.146c0-11.088,9.023-20.109,20.109-20.109
	s20.109,9.021,20.109,20.109s-9.023,20.109-20.109,20.109c-1.466,0-2.889-0.178-4.267-0.477l-28,40.269
	c-1.293,1.858-3.414,2.969-5.68,2.969h-76.106c-3.82,0-6.919-3.097-6.919-6.919s3.099-6.919,6.919-6.919h72.489V141.071z
	 M427.119,92.041c0,3.457,2.811,6.27,6.27,6.27c3.46,0,6.27-2.813,6.27-6.27s-2.811-6.27-6.27-6.27
	C429.93,85.77,427.119,88.583,427.119,92.041z M451.832,419.948c0,11.088-9.023,20.109-20.109,20.109s-20.109-9.021-20.109-20.109
	c0-5.421,2.179-10.329,5.68-13.95l-25.469-36.63h-72.488c-3.82,0-6.919-3.097-6.919-6.919s3.099-6.919,6.919-6.919h76.106
	c2.266,0,4.388,1.11,5.68,2.969l28.866,41.517c0.581-0.051,1.14-0.176,1.734-0.176C442.809,399.839,451.832,408.86,451.832,419.948z
	 M437.994,419.948c0-3.457-2.811-6.27-6.27-6.27c-3.46,0-6.27,2.813-6.27,6.27s2.811,6.27,6.27,6.27
	C435.183,426.218,437.994,423.405,437.994,419.948z M485.409,334.534c0,11.088-9.023,20.109-20.109,20.109
	s-20.109-9.021-20.109-20.109c0-4.317,1.396-8.295,3.72-11.575l-15.883-16.448h-93.187c-3.82,0-6.919-3.097-6.919-6.919
	s3.099-6.919,6.919-6.919h96.124c1.878,0,3.671,0.764,4.978,2.113l19.58,20.276c1.538-0.378,3.122-0.636,4.776-0.636
	C476.386,314.425,485.409,323.445,485.409,334.534z M471.571,334.534c0-3.457-2.811-6.27-6.27-6.27s-6.27,2.813-6.27,6.27
	s2.811,6.27,6.27,6.27S471.571,337.991,471.571,334.534z M491.4,256.18c0,11.088-9.023,20.109-20.109,20.109
	c-8.646,0-15.972-5.516-18.807-13.192h-151.67v122.314c0,25.061-20.388,45.452-45.452,45.452c-18.356,0-34.51-10.757-41.663-27.246
	c-0.288,0.007-0.572,0.009-0.856,0.009c-25.064,0-45.452-20.39-45.452-45.452c0-1.2,0.05-2.401,0.144-3.597
	c-10.793-2.16-21.014-7.714-29.366-16.07c-10.721-10.719-16.622-24.969-16.622-40.127c0-15.158,5.901-29.408,16.622-40.125
	c0.803-0.804,1.705-1.509,2.561-2.261c-0.856-0.753-1.758-1.458-2.561-2.261c-10.721-10.719-16.622-24.969-16.622-40.125
	c0-15.158,5.901-29.408,16.622-40.127c8.352-8.356,18.573-13.91,29.366-16.07c-0.095-1.196-0.144-2.396-0.144-3.597
	c0-25.064,20.388-45.454,45.452-45.454c0.284,0,0.568,0.002,0.856,0.009c7.153-16.487,23.307-27.244,41.663-27.244
	c25.064,0,45.452,20.39,45.452,45.452V249.26h151.672c2.836-7.674,10.161-13.187,18.805-13.187
	C482.378,236.072,491.4,245.092,491.4,256.18z M147.954,243.948c1.92,1.92,4.101,3.605,6.412,5.126h132.61V126.575
	c0-17.433-14.181-31.613-31.613-31.613c-11.622,0-21.969,6.194-27.492,15.946c5.599,1.96,10.798,5.014,15.293,9.041
	c0.053,0.047,0.071,0.114,0.122,0.162c2.578-1.204,5.422-1.926,8.451-1.926c11.086,0,20.109,9.021,20.109,20.106
	c0,11.088-9.023,20.109-20.109,20.109c-11.086,0-20.109-9.021-20.109-20.109c0-2.998,0.704-5.818,1.886-8.376
	c-5.739-4.972-13.053-7.719-20.671-7.719c-17.433,0-31.613,14.183-31.613,31.616c0,0.998,0.092,1.99,0.185,2.981
	c8.197,0.847,16.04,3.434,23.106,7.669c3.099-1.954,6.739-3.13,10.665-3.13c11.086,0,20.109,9.021,20.109,20.109
	c0,11.086-9.023,20.106-20.109,20.106s-20.109-9.021-20.109-20.106c0-2.034,0.394-3.96,0.958-5.812
	c-6.228-3.431-13.156-5.305-20.384-5.328c-10.047,0.551-19.879,5.142-27.697,12.962c-8.104,8.106-12.568,18.881-12.568,30.343
	C135.386,225.067,139.851,235.842,147.954,243.948z M208.915,181.442c0,3.457,2.811,6.268,6.27,6.268c3.46,0,6.27-2.811,6.27-6.268
	s-2.811-6.27-6.27-6.27C211.726,175.171,208.915,177.984,208.915,181.442z M251.736,144.562c3.46,0,6.27-2.813,6.27-6.27
	s-2.811-6.268-6.27-6.268s-6.27,2.811-6.27,6.268C245.466,141.749,248.277,144.562,251.736,144.562z M286.976,385.411V262.912
	H154.243c-2.275,1.505-4.388,3.225-6.288,5.126c-8.104,8.104-12.568,18.879-12.568,30.341s4.464,22.237,12.568,30.343
	c7.818,7.82,17.65,12.411,27.697,12.962c7.112-0.023,13.944-1.827,20.099-5.156c-0.398-1.575-0.673-3.197-0.673-4.894
	c0-11.088,9.023-20.109,20.109-20.109s20.109,9.021,20.109,20.109s-9.023,20.109-20.109,20.109c-4.313,0-8.291-1.394-11.57-3.716
	c-6.832,3.926-14.349,6.354-22.2,7.166c-0.093,0.991-0.185,1.983-0.185,2.981c0,17.433,14.181,31.613,31.613,31.613
	c7.46,0,14.614-2.641,20.297-7.412c-0.962-2.346-1.512-4.904-1.512-7.593c0-11.088,9.023-20.109,20.109-20.109
	s20.109,9.021,20.109,20.109s-9.023,20.109-20.109,20.109c-3.336,0-6.436-0.894-9.209-2.338c-4.351,3.759-9.312,6.654-14.657,8.523
	c5.523,9.755,15.87,15.949,27.492,15.949C272.796,417.025,286.976,402.844,286.976,385.411z M251.736,381.053
	c3.46,0,6.27-2.813,6.27-6.27s-2.811-6.27-6.27-6.27s-6.27,2.813-6.27,6.27S248.277,381.053,251.736,381.053z M215.186,337.905
	c3.46,0,6.27-2.813,6.27-6.27s-2.811-6.27-6.27-6.27c-3.46,0-6.27,2.813-6.27,6.27c0,1.16,0.338,2.233,0.889,3.166
	c0.114,0.135,0.245,0.249,0.35,0.395c0.112,0.155,0.184,0.325,0.282,0.486C211.587,337.03,213.277,337.905,215.186,337.905z
	 M477.562,256.18c0-3.457-2.811-6.27-6.27-6.27s-6.27,2.813-6.27,6.27s2.811,6.27,6.27,6.27S477.562,259.637,477.562,256.18z"
    />
  </Icon>
);
