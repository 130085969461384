import React, { useCallback, useMemo, useState } from 'react';

import {
  NativeResources,
  Resources,
  SearchTermSubCategory,
} from '@ariksa/inventory-core';
import { NotificationFor } from '@ariksa/notification/api';
import { Box, Center, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { find, map, slice, some } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';
import { Dict } from 'types/utils';

import {
  AriksaIcon,
  formatBytes,
  getIcon,
  renderCloudIcon,
  renderRiskContext,
  renderSeverityBar,
  renderStackedCloudNameAndIcon,
  renderTableHeaderWithLoader,
  renderTimeStackedCell,
  renderTooltipFieldValue,
  StackedCell,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ActionButton } from 'components/DataEntry';
import {
  GraphIcon,
  IconTypes,
  ListIcon,
  NotificationIcon,
  TicketIcon,
} from 'components/Icons';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import {
  ResourceTypeIconTooltip,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { NotificationResource } from 'containers/Findings/Alerts/types';
import { renderVulnerabilities } from 'containers/Inventory/CloudInventory/Components/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useRedirectToSecurityGraph } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectToSecurityGraph';
import {
  renderCloudEntities,
  renderDeployedInVPCTooltip,
  renderImageName,
  renderPackagesImpacted,
  renderPackageType,
} from 'containers/Visibility/Vulnerabilities/Components/hooks/utils';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { CONTAINER_ON_VIRTUAL_MACHINE } from 'containers/Visibility/Vulnerabilities/types';

export const useVulnerabilityTableColumns = () => {
  const {
    tablesTabIndex,
    selectedTab,
    cveHostsRiskContext,
    cveHostsVulnerabilities,
    publishedAt,
  } = useSelector(selectVulnerabilities);
  const { riskContext } = useSelector(selectSharedState);
  const {
    toCloudAccountId,
    renderStackedAccountNameID,
    toCloudAccountName,
    renderStackedCloudAccountWithCloud,
  } = useCloudAccountId();
  const { getCloudNativeName } = useResourceType();
  const { accountMapping } = useCloudAccountId();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const hostsDisclosure = useDisclosure();

  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const { getTabIndex } = useMetadataDrawerTabs();
  const { redirectToSecurityGraph } = useRedirectToSecurityGraph();

  const [currentRow, setCurrentRow] = useState<Dict<any> | null>(null);
  const [currentCVE, setCurrentCVE] = useState<Dict<any>>({});

  const handleRowClick = useCallback(
    (r, tabType) => {
      let resource =
        selectedTab === NativeResources.ElasticContainerRegistry
          ? NativeResources.ElasticContainerRegistry
          : r?.native_name;
      const tabIndex = getTabIndex(resource, tabType);

      updateActiveResource({
        resourceType: resource as NativeResources,
        uuid: r?.registry_uuid ?? r?.uuid,
        accountId: r?.account_uuid ?? r?.account,
        resourceId: r?.registry_id ?? r?.resource_id,
        showResolvedVulnerabilities: tablesTabIndex === 1,
        vulnerabilityPublishedTime: publishedAt,
        searchTerm:
          selectedTab === NativeResources.ElasticContainerRegistry
            ? r?.image_tags?.[0]
            : undefined,
      });
      onOpenMetadata(tabIndex);
    },
    [
      onOpenMetadata,
      updateActiveResource,
      getTabIndex,
      tablesTabIndex,
      publishedAt,
      selectedTab,
    ],
  );

  const getTab = useCallback(() => {
    switch (selectedTab) {
      case Resources.VirtualMachine:
      case NativeResources.ElasticContainerService:
      case CONTAINER_ON_VIRTUAL_MACHINE:
        return MetadataTabs.Network;
      default:
        return MetadataTabs.Summary;
    }
  }, [selectedTab]);

  const renderActions = useCallback(
    ({ row }) => {
      return (
        <Center onClick={e => e.stopPropagation()}>
          {selectedTab !== 'cve' && (
            <ActionButton
              label="Show security graph"
              icon={<GraphIcon />}
              onClick={() => {
                const { native_name, uuid, resource_id, account } = row;
                redirectToSecurityGraph({
                  mapType: 'posture',
                  resourceType:
                    selectedTab === NativeResources.ElasticContainerRegistry
                      ? 'ContainerImage'
                      : native_name,
                  resourceUuid:
                    selectedTab === NativeResources.ElasticContainerRegistry
                      ? row?.id
                      : uuid,
                  resourceId: resource_id,
                  account: account ?? row?.account_uuid,
                  sourceTab: MetadataTabs.Vulnerabilities,
                  ...(selectedTab === NativeResources.ElasticContainerRegistry
                    ? {}
                    : { subCategory: SearchTermSubCategory.Vulnerable }),
                });
                setCurrentRow(row);
                handleRowClick(row, getTab());
              }}
            />
          )}
          {/*<ActionButton
            label="Create ticket"
            icon={<TicketIcon />}
            onClick={() => {
              setCurrentRow(row);
              ticketModal.onOpen();
            }}
          />*/}
          <ActionButton
            label="Create notification"
            icon={<NotificationIcon />}
            onClick={() => {
              setCurrentRow(row);
              notificationModal.onOpen();
            }}
          />
        </Center>
      );
    },
    [
      getTab,
      handleRowClick,
      notificationModal,
      redirectToSecurityGraph,
      selectedTab,
      ticketModal,
    ],
  );

  let columns: TableColumnProps[];

  const host = {
    header: <Box pl="thLeftPaddingWithIcon">Host</Box>,
    accessor: '',
    render: ({ row }) => {
      let resource = selectedTab;
      if (
        selectedTab === NativeResources.ElasticContainerService ||
        selectedTab === CONTAINER_ON_VIRTUAL_MACHINE
      )
        resource = NativeResources.Container;
      return (
        <WithResourceIcon
          resourceType={resource as string}
          iconSize="small"
          iconTooltip={
            <ResourceTypeIconTooltip resourceType={row?.native_name} />
          }
        >
          <StackedCell
            upper={row?.name ?? row?.container_id}
            lower={row?.resource_id ?? 'Container'}
          />
        </WithResourceIcon>
      );
    },
    align: 'left',
  };

  const image = {
    header: <Box pl="thLeftPaddingWithIcon">Image</Box>,
    accessor: '',
    render: ({ row }) => renderImageName(row, selectedTab),
    align: 'left',
  };

  const cloud = {
    header: 'Cloud',
    align: 'left',
    render: ({ row }) => {
      const provider =
        accountMapping[row?.account]?.cloud_type ?? row?.cloud ?? '';
      return !!provider ? renderCloudIcon({ provider }) : '';
    },
    styles: { width: '60px', cell: { maxWidth: '60px' } },
  };

  const containerCloud = {
    header: 'Cloud',
    align: 'left',
    render: ({ row }) => {
      const account = accountMapping[row?.account];
      return (
        <Stack spacing={1}>
          <Box>
            {renderStackedCloudNameAndIcon(account?.cloud_type ?? 'aws', true)}
          </Box>
          <Box>{account?.name ?? account?.cloud_account_id}</Box>
        </Stack>
      );
    },
  };

  const deployedInVpc = {
    header: 'Deployed In',
    align: 'left',
    styles: { width: '150px', cell: { maxWidth: '150px' } },
    render: ({ row }) => {
      const vpc = row?.vpc || row?.vpc_id || '-';
      const account = toCloudAccountId(row?.account);
      return (
        <StackedCell
          upper={vpc}
          lower={account}
          lowerTooltip={renderDeployedInVPCTooltip(vpc, account)}
          upperTooltip={renderDeployedInVPCTooltip(vpc, account)}
        />
      );
    },
  };

  const deployedInContainer = {
    header: 'Deployed In',
    accessor: 'deployed_in',
    align: 'left',
    styles: { width: '150px', cell: { maxWidth: '150px' } },
    render: ({ value, row }) => {
      const renderValue = (val, type) => {
        return (
          <CustomTooltip
            label={
              <Stack>
                <Box color="orange">{getCloudNativeName(type)}</Box>
                <Box>{val}</Box>
              </Stack>
            }
          >
            <HStack w="full" spacing={1} align="center">
              <Box boxSize={6} color="primary">
                {getIcon(type, 'inherit')}
              </Box>
              <Box w="full" textOverflow="ellipsis" overflow="hidden">
                {val}
              </Box>
            </HStack>
          </CustomTooltip>
        );
      };

      return (
        <Stack spacing={0}>
          {(value?.gcpgke || value?.gcpgke_id) &&
            renderValue(
              value?.gcpgke || value?.gcpgke_id,
              NativeResources.Gcpgke,
            )}
          {(value?.eks || value?.eks_id) &&
            renderValue(
              value?.eks || value?.eks_id,
              NativeResources.ElasticKubernetesService,
            )}
          {(value?.ecs || value?.ecs_id) &&
            renderValue(
              value?.ecs || value?.ecs_id,
              NativeResources.ElasticContainerService,
            )}
          {(value?.ec2 || value?.ec2_id) &&
            renderValue(
              value?.ec2 || value?.ec2_id,
              NativeResources.Ec2Instance,
            )}
          {(value?.gce || value?.gce_id) &&
            renderValue(
              value?.gce || value?.gce_id,
              NativeResources.GcpComputeEngine,
            )}
          {(value?.avm || value?.avm_id) &&
            renderValue(
              value?.avm || value?.avm_id,
              NativeResources.AzureVirtualMachine,
            )}
          {(value?.avmss_instance || value?.avmss_instance_id) &&
            renderValue(
              value?.avmss_instance || value?.avmss_instance_id,
              NativeResources.AzureVirtualMachineScaleSets,
            )}
          {(value?.aks || value?.aks_id) &&
            renderValue(
              value?.aks || value?.aks_id,
              NativeResources.Aks,
            )}
        </Stack>
      );
    },
  };

  const deployedFromRegistry = {
    header: 'Registry',
    align: 'left',
    //styles: { width: '150px', cell: { maxWidth: '150px' } },
    render: ({ row }) => {
      return row?.repository_name ?? row?.uri;
      /*<StackedCell
        lower={row?.registry_id}
        upper={row?.repository_uri ?? row?.repository_name}
      />;*/
    },
    styles: { width: '130px', cell: { maxWidth: '130px' } },
  };

  const actions = {
    header: 'Action',
    render: renderActions,
    styles: { width: '60px', cell: { maxWidth: '60px' } },
  };

  const createdAt = {
    header: 'Created At',
    align: 'left',
    accessor: 'created_at',
    render: renderTimeStackedCell,
  };

  const context = {
    header: renderTableHeaderWithLoader('Risk Context', riskContext.isLoading),
    align: 'left',
    render: ({ row }) => renderRiskContext(riskContext.data?.[row?.uuid]),
    styles: { width: '100px', cell: { maxWidth: '100px' } },
  };

  const technology = {
    header: 'Technologies',
    accessor: 'technologies',
    align: 'left',
    render: ({ row }) => {
      const data = row?.applications;
      const renderTag = index =>
        !!data?.[index] && (
          <CustomTooltip label={data[index]}>
            <AriksaIcon
              border={true}
              type={data[index]}
              iconFilled={false}
              borderColor={customTheme.colors.gray['200']}
              bg={customTheme.colors.gray['50']}
            />
          </CustomTooltip>
        );
      return data?.length ? (
        <Stack spacing={1}>
          <HStack spacing={1}>
            {renderTag(0)}
            {renderTag(1)}
            {renderTag(2)}
            {renderTag(4)}
          </HStack>
          <HStack spacing={1}>
            {renderTag(5)}
            {renderTag(6)}
            {renderTag(7)}
            {data?.length > 8 && (
              <CustomTooltip
                label={
                  <Stack>
                    {map(slice(data, 8), o => (
                      <Box>{o as string}</Box>
                    ))}
                  </Stack>
                }
              >
                <Tag label={'+ ' + (data?.length - 8)} />
              </CustomTooltip>
            )}
          </HStack>
        </Stack>
      ) : (
        '-'
      );
    },
  };

  const containerOs = {
    header: 'Container OS',
    align: 'left',
    styles: { width: '150px', cell: { maxWidth: '150px' } },
    render: ({ row }) => (
      <StackedCell
        upper={
          row?.os_name ??
          '-' /*+
          ' ' +
          (summary?.[row?.uuid]?.os_version ?? '')*/
        }
        lower={
          <HStack>
            <Box>{row?.os_version ?? ''}</Box>
            {/*Is Latest: -*/}
          </HStack>
        }
      />
    ),
  };

  const os = {
    header: 'OS Version',
    align: 'left',
    //styles: { width: '100px', cell: { maxWidth: '100px' } },
    render: ({ row }) => (
      <StackedCell upper={row?.os_name} lower={row?.os_version} />
    ),
  };

  const vulnerability = {
    header: 'Vulnerabilities',
    accessor: 'vulnerabilities',
    align: 'left',
    render: renderVulnerabilities,
    //styles: { width: '300px', cell: { maxWidth: '300px' } },
  };

  const sizeDigest = {
    header: 'Size / Digest',
    accessor: 'vulnerabilities',
    align: 'left',
    render: ({ row }) => (
      <CustomTooltip
        label={
          <Stack>
            {renderTooltipFieldValue(
              'Size',
              formatBytes(row?.image_size_in_bytes),
            )}
            {renderTooltipFieldValue('Digest', row?.image_digest)}
          </Stack>
        }
      >
        <StackedCell
          upper={formatBytes(row?.image_size_in_bytes)}
          lower={row?.image_digest}
          showUpperTooltip={false}
          showLowerTooltip={false}
        />
      </CustomTooltip>
    ),
    styles: { width: '200px', cell: { maxWidth: '200px' } },
  };
  const platform = {
    header: 'Architecture',
    accessor: 'architecture',
    align: 'left',
    //styles: { width: '80px', cell: { maxWidth: '80px' } },
    //render: ({ row }) => <StackedCell upper={'-'} lower={row?.architecture} />,
  };
  const device = {
    header: 'Device',
    accessor: 'vulnerabilities',
    align: 'left',
    render: ({ row }) => {
      const deviceMapping = find(
        map(row?.block_device_mappings, o => JSON.parse(o)),
        { DeviceName: row?.root_device_name },
      );
      const encrypted =
        (deviceMapping?.Ebs?.Encrypted ? '' : 'Not ') + 'Encrypted';
      return <StackedCell upper={row?.root_device_name} lower={encrypted} />;
    },
    styles: { width: '150px', cell: { maxWidth: '150px' } },
  };
  const location = {
    header: 'Location',
    accessor: 'vulnerabilities',
    align: 'left',
    render: ({ row }) => (
      <StackedCell
        upper={row?.public ? 'Public' : 'Private'}
        lower={row?.image_location}
      />
    ),
    styles: { width: '150px', cell: { maxWidth: '150px' } },
  };

  const entities = {
    header: 'Entities',
    accessor: 'entities',
    render: renderCloudEntities,
    align: 'left',
  };

  const renderTooltip = (field, value) => (
    <HStack align="flex-start">
      <Box color="orange" whiteSpace="nowrap">
        {field}:
      </Box>
      <Box>{value}</Box>
    </HStack>
  );

  const virtualMachine = [
    host,
    vulnerability,
    cloud,
    deployedInVpc,
    os,
    //ipAddress,
    context,
    actions,
  ];

  const serverless = [
    host,
    vulnerability,
    cloud,
    deployedInVpc,
    // {
    //   header: 'Runtime',
    //   accessor: 'vulnerabilities',
    //   align: 'left',
    //   render: ({ row }) => <StackedCell upper={'-'} lower={'-'} />,
    // },
    // {
    //   header: 'Role',
    //   accessor: 'role',
    //   align: 'left',
    // },
    createdAt,
    actions,
  ];
  const containers = [
    host,
    vulnerability,
    containerCloud,
    deployedInContainer,
    {
      header: 'Image',
      accessor: 'image',
      align: 'left',
      styles: { width: '150px', cell: { maxWidth: '150px' } },

      /*render: ({ row }) => (
        <StackedCell
          upper={'OS: ' + row?.os_vulnerabilities_count}
          lower={'Package: ' + row?.library_vulnerabilities_count}
        />
      ),*/
    },
    containerOs,
    technology,
    actions,
  ];
  const containerImages = [
    image,
    vulnerability,
    deployedFromRegistry,
    {
      header: 'Containers Deployed',
      accessor: 'containers_count',
      styles: { width: '100px', cell: { maxWidth: '100px' } },
    },
    {
      header: 'Deployed In',
      render: ({ row }) => {
        const name = toCloudAccountName(row?.account_uuid);
        const id = toCloudAccountId(row?.account_uuid);
        return (
          <CustomTooltip
            label={
              <Stack>
                {renderTooltipFieldValue('Account Name', name)}
                {renderTooltipFieldValue('Account ID', id)}
                {renderTooltipFieldValue('Region', row?.region)}
              </Stack>
            }
          >
            <StackedCell
              upper={renderStackedCloudAccountWithCloud(
                row?.account_uuid,
                true,
              )}
              lower={row?.region}
              showLowerTooltip={false}
              showUpperTooltip={false}
            />
          </CustomTooltip>
        );
      },
      align: 'left',
      //styles: { width: '250px', cell: { maxWidth: '250px' } },
    },
    sizeDigest,
    createdAt,
    //version,
    actions,
  ];
  const machineImages = [
    image,
    cloud,
    vulnerability,
    platform,
    device,
    {
      header: 'VMs Deployed',
      accessor: 'vm_count',
      styles: { width: '100px', cell: { maxWidth: '100px' } },
    },
    createdAt,
    location,
    actions,
  ];
  const cve = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Finding</Box>,
      accessor: 'vulnerability_id',
      sortKey: 'vulnerability_id',
      render: ({ row }) => {
        return (
          <WithResourceIcon
            resourceType={IconTypes.Cve}
            bgColor={row?.severity}
            tooltip={row?.title}
          >
            <StackedCell
              upper={row?.vulnerability_id}
              lower={row?.title}
              showLowerTooltip={false}
              showUpperTooltip={false}
            />
          </WithResourceIcon>
        );
      },
      //styles: { width: '600px', cell: { maxWidth: '600px' } },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      align: 'left',
      sortKey: 'severity',
      render: ({ value }) => renderSeverityBar({ value, isInline: false }),
      styles: { width: '100px', cell: { maxWidth: '100px' } },
    },
    {
      header: 'CVSS V3 Score',
      render: ({ row }) => <Box>{row?.cvss_score?.toFixed(1) ?? '-'}</Box>,
      accessor: 'cvss_v3_score',
      sortKey: 'cvss_v3_score',
      styles: { width: '100px', cell: { maxWidth: '100px' } },
    },
    /*{
      header: 'Description',
      accessor: 'description',
      align: 'left',
      styles: { width: '500px', cell: { maxWidth: '600px' } },
      render: ({ row }) => (
        <CustomTooltip label={row?.description}>
          <Center
            w={'full'}
            whiteSpace="pre-line"
            overflow="hidden"
            textOverflow="ellipsis"
            lineHeight={'1.5em'}
            h={'3em'}
            justifyContent="left"
            alignItems="top"
            position="relative"
          >
            {row?.description}
          </Center>
        </CustomTooltip>
      ),
    },*/
    {
      header: 'Known Exploits',
      render: ({ row }) => {
        return row.exploitable ? 'Yes' : 'No';
      },
      styles: { width: '100px', cell: { maxWidth: '100px' } },
    },
    {
      header: 'Requires Network Access',
      render: ({ row }) => {
        return row?.cvss_v3?.attack_vector === 'Network' ? 'Yes' : 'No';
      },
      styles: { width: '100px', cell: { maxWidth: '100px' } },
    },
    {
      header: 'Actions',
      render: ({ row }) => (
        <Center>
          <ActionButton
            label={'Show all entities'}
            icon={<ListIcon />}
            onClick={e => {
              e.stopPropagation();
              hostsDisclosure.onOpen();
              setCurrentCVE(row);
            }}
          />
        </Center>
      ),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];
  const cveHostsColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Host</Box>,
      accessor: 'name',
      align: 'left',
      //sortKey: 'name',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.native_name}
          iconTooltip={
            <ResourceTypeIconTooltip resourceType={row?.native_name} />
          }
          iconSize="sm"
          bgColor={row?.native_name ?? 'primary'}
        >
          <CustomTooltip
            label={
              <Stack>
                {renderTooltip('Name', row?.name)}
                {renderTooltip('Resource ID', row?.resource_id)}
                {row?.pod && renderTooltip('POD', row?.pod)}
                {row?.instance_id &&
                  renderTooltip('Instance ID', row?.instance_id)}
              </Stack>
            }
          >
            <StackedCell
              upper={
                row?.name
                  ? row?.name + '(' + row?.resource_id + ')'
                  : row?.resource_id
              }
              lower={getCloudNativeName(row?.native_name)}
              showUpperTooltip={false}
              showLowerTooltip={false}
            />
          </CustomTooltip>
        </WithResourceIcon>
      ),
    },
    {
      header: 'Cloud',
      align: 'left',
      render: ({ row }) => {
        const provider =
          accountMapping[row?.account]?.cloud_type ?? row?.cloud ?? '';
        return !!provider ? <Box boxSize={5}>{getIcon(provider)}</Box> : '-';
      },
    },
    {
      header: 'Deployed in',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={row?.vpc ?? row?.vpc_id}
          lower={row?.region}
          showLowerTooltip={false}
        />
      ),
    },
    {
      header: 'Account',
      align: 'left',
      render: ({ row }) => renderStackedAccountNameID(row?.account),
    },
    ...(selectedTab === 'cve'
      ? [
          {
            header: 'Packages Impacted',
            accessor: 'packages',
            render: renderPackagesImpacted,
            align: 'left',
          },
        ]
      : []),
    {
      header: renderTableHeaderWithLoader(
        'OS Version',
        some(cveHostsVulnerabilities, { isLoading: true }),
      ),
      accessor: 'os_name',
      align: 'left',
      styles: { width: '100px', cell: { maxWidth: '100px' } },
      render: ({ row }) => {
        const data =
          cveHostsVulnerabilities?.[row?.native_name].data?.[row?.uuid];
        return (
          <CustomTooltip
            label={
              <Stack>
                {renderTooltip('OS', data?.os_name || '-')}
                {renderTooltip('Version', data?.os_version || '-')}
              </Stack>
            }
          >
            <StackedCell
              upper={data?.os_name || '-'}
              lower={data?.os_version || '-'}
              showLowerTooltip={false}
              showUpperTooltip={false}
            />
          </CustomTooltip>
        );
      },
    },
    {
      header: renderTableHeaderWithLoader(
        'Risk Context',
        cveHostsRiskContext.isLoading,
      ),
      align: 'left',
      render: ({ row }) =>
        renderRiskContext(cveHostsRiskContext.data?.[row?.uuid]),
      styles: { width: '100px', cell: { maxWidth: '100px' } },
    },
    actions,
  ];
  const packagesColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Package Name</Box>,
      accessor: 'package_name',
      align: 'left',
      //sortKey: 'name',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={IconTypes.Packages}
          bgColor={'primary'}
          tooltip={value}
        >
          {value}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Version',
      accessor: 'installed_version',
      align: 'left',
    },
    {
      header: 'Type',
      accessor: 'package_category',
      align: 'left',
      render: ({ value }) => renderPackageType(value),
    },
    {
      header: 'Vulnerabilities',
      accessor: 'vulnerability',
      align: 'left',
      render: renderVulnerabilities,
    },
    entities,
    {
      header: 'CVE',
      accessor: 'cve_ids',
      render: renderPackagesImpacted,
      align: 'left',
    },
  ];

  switch (selectedTab) {
    case Resources.VirtualMachine:
      columns = virtualMachine;
      break;
    case Resources.ServerlessCompute:
      columns = serverless;
      break;
    case NativeResources.ElasticContainerService:
    case CONTAINER_ON_VIRTUAL_MACHINE:
      columns = containers;
      break;
    case NativeResources.ElasticContainerRegistry:
      columns = containerImages;
      break;
    case Resources.Image:
      columns = machineImages;
      break;
    case IconTypes.Packages:
      columns = packagesColumns;
      break;
    default:
      columns = cve;
  }

  let tableColumns = columns;

  const tableActionModals = useMemo(() => {
    const alert: NotificationResource = {
      entity_uuid: currentRow?.uuid ?? currentRow?.id,
      entity_id: currentRow?.resource_id,
      entity_type:
        selectedTab === NativeResources.ElasticContainerRegistry
          ? NativeResources.ElasticContainerRegistry
          : currentRow?.native_name,
      account_id: currentRow?.account || currentRow?.account_uuid,
      resource:
        selectedTab === NativeResources.ElasticContainerRegistry
          ? NativeResources.ElasticContainerRegistry
          : currentRow?.native_name,
      entity_name: currentRow?.name || currentRow?.image_tags?.[0],
    };

    return (
      <>
        {currentRow && (
          <CreateTicket
            onClose={ticketModal.onClose}
            isOpen={ticketModal.isOpen}
            alert={alert}
            notification_for={NotificationFor.Vulnerabilities}
          />
        )}
        {currentRow && (
          <CreateNotification
            onClose={notificationModal.onClose}
            isOpen={notificationModal.isOpen}
            alert={alert}
            notification_for={NotificationFor.Vulnerabilities}
          />
        )}
      </>
    );
  }, [
    currentRow,
    selectedTab,
    notificationModal.isOpen,
    notificationModal.onClose,
    ticketModal.isOpen,
    ticketModal.onClose,
  ]);

  return {
    tableColumns,
    tableActionModals,
    handleRowClick,
    cveHostsColumns,
    packagesCVEColumns: cve,
    hostsDisclosure,
    currentCVE,
  };
};
