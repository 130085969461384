import React, { useEffect, useState } from 'react';

import { AggregatedAlertsSummary } from '@ariksa/notification/api';
import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import { each, isEmpty, map, slice } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { DashboardOverviewTooltipProps } from 'containers/Dashboard/utils/OverviewDashboardTooltip';
import { dashboardWidgetLineStyles } from 'containers/Dashboard/utils/styles';
import { renderEmptyRows } from 'containers/Dashboard/utils/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { toTitleCase } from 'utils/string';

interface Props {
  categoryClass?: string;
  tooltip?: DashboardOverviewTooltipProps;
  accounts: AggregatedAlertsSummary[];
}

export const TopFindings: React.FC<Props> = props => {
  const { categoryClass, accounts } = props;
  const { openFindings } = useSelector(selectDashboard);
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState<Record<string, any>[]>();
  const [categoryAccountsMapping, setCategoryAccountsMapping] = useState<
    Record<string, any>[]
  >();
  const { accountMapping } = useCloudAccountId();

  useEffect(() => {
    if (!!categoryClass) return;
    let items: Record<string, any> = {};
    each(openFindings.data, o => {
      each(
        o,
        c =>
          (items[c.category] = {
            CRITICAL: c.severity?.CRITICAL + (items[c.category]?.CRITICAL ?? 0),
            MEDIUM: c.severity?.MEDIUM + (items[c.category]?.MEDIUM ?? 0),
            HIGH: c.severity?.HIGH + (items[c.category]?.HIGH ?? 0),
            LOW: c.severity?.LOW + (items[c.category]?.LOW ?? 0),
          }),
      );
    });

    setAllCategories(map(items, (s, c) => ({ category: c, severity: s })));
  }, [openFindings.data, categoryClass]);

  useEffect(() => {
    let mapping: Record<string, any>[] = [];
    each(accounts, o => {
      mapping[o.category] = o;
    });
    setCategoryAccountsMapping(mapping);
  }, [accounts, accountMapping]);

  const getSeverity = severity => {
    if (!!severity?.CRITICAL) return 'critical';
    if (!!severity?.MEDIUM) return 'medium';
    if (!!severity?.HIGH) return 'high';
    if (!!severity?.LOW) return 'low';
  };

  const renderLegend = (category, count) => {
    const severity = getSeverity(count);
    const accounts =
      categoryAccountsMapping?.[category]?.account_ids?.length ?? 0;
    const value = count?.[severity?.toUpperCase()!] ?? 0;
    return (
      <HStack
        onClick={() => {
          navigate(
            '/findings?tab=' +
              (!!categoryClass ? categoryClass : 'AllFindings') +
              '&alertCategory=' +
              category +
              '&severity=' +
              severity?.toUpperCase() +
              '&redirect=true',
          );
        }}
        cursor="pointer"
        spacing={6}
        {...dashboardWidgetLineStyles}
      >
        <Box h="full" w={1} bg={severity} borderRadius={2} />
        <Stack spacing={0} w="full">
          <HStack justify="space-between" w="full">
            <Box>{toTitleCase(category)}</Box>
            <Box>{value}</Box>
          </HStack>
          <HStack
            justify="space-between"
            color={customTheme.colors.gray['250']}
            w="full"
          >
            <Box>{toTitleCase(severity)} severity</Box>
            <Box>
              In {accounts} account{accounts === 1 ? '' : 's'}
            </Box>
          </HStack>
        </Stack>
      </HStack>
    );
  };

  return (
    <DashboardWidget
      label="Findings to Prioritize"
      content={
        <Flex w="full" h="full">
          {openFindings.isLoading ? (
            <CustomSpinner />
          ) : isEmpty(openFindings.data) ? (
            <NoDataAvailable />
          ) : (
            <Stack h="full" w="full" justify="space-between" spacing={0}>
              {map(
                slice(
                  !!categoryClass
                    ? openFindings.data?.[categoryClass]
                    : allCategories,
                  0,
                  3,
                ),
                o => renderLegend(o.category, o.severity),
              )}
              {renderEmptyRows(
                (!!categoryClass
                  ? openFindings.data?.[categoryClass]
                  : allCategories
                )?.length,
              )}
            </Stack>
          )}
        </Flex>
      }
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
    />
  );
};
