import React, { useCallback, useState } from 'react';

import { Tr, Td } from '@chakra-ui/react';
import isString from 'lodash/isString';

import { customTheme } from '../../../../theme';

import { TableCell } from './Table';
import { TableCellTooltip } from './TableCellTooltip';
import { cellValue } from './Utils';

export const TableBody = ({
  columns,
  row,
  subComponent,
  onRowClick,
  expanded,
  index,
  rowIndex = -1,
  styles = {} as any,
  cursor,
  setCurrentRowIndex,
  isRowActive,
}) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  const expand = () => {
    setIsRowExpanded(!isRowExpanded);
    onRowClick && onRowClick(row, index);
    setCurrentRowIndex(index);
  };

  const handleCursor = useCallback(() => {
    if (cursor) {
      if (isString(cursor)) return cursor;
      return cursor?.(row, index);
    }
  }, [cursor, row, index]);

  return (
    <>
      <Tr
        key={index + '-table-row'}
        onClick={() => expand()}
        cursor={onRowClick || subComponent ? 'pointer' : handleCursor()}
        _hover={{
          bg: rowIndex === index ? 'hover' : customTheme.colors.gray['50'],
          'td:first-of-type': {
            background:
              rowIndex === index ? 'hover' : customTheme.colors.gray['50'],
          },
        }}
        css={{
          'td:first-of-type': {
            position: 'sticky',
            left: 0,
            overflow: 'hidden',
            background: rowIndex === index ? 'hover' : 'white',
          },
        }}
        _active={{
          bg: 'hover',
          'td:first-of-type': {
            background: 'hover',
          },
        }}
        bg={rowIndex === index ? 'hover' : 'white'}
        {...styles.row}
      >
        {columns.map((column, index) => (
          <TableCell
            styles={{
              textAlign: column?.align ?? (index === 0 ? 'left' : 'center'),
              fontSize: 'tableCell',
              ...styles.cell,
              ...column.styles?.cell,
            }}
            key={index + '-table-cell'}
            {...styles.cell}
          >
            {column?.render ? (
              column.render({ value: cellValue(row, column), row, column })
            ) : (
              <TableCellTooltip
                label={cellValue(row, column)}
                value={cellValue(row, column)}
              />
            )}
          </TableCell>
        ))}
      </Tr>
      {expanded &&
        subComponent?.({ row, expanded: isRowExpanded, columns, index })}
      {/* {`${expanded}`} */}
      {/*{rowIndex !== undefined && rowIndex !== null*/}
      {/*  ? expanded &&*/}
      {/*    rowIndex === index &&*/}
      {/*    subComponent && (*/}
      {/*      <Tr w={'full'}>*/}
      {/*        <Td colSpan={100}>*/}
      {/*          {subComponent({ row, expanded, columns, index })}*/}
      {/*        </Td>*/}
      {/*      </Tr>*/}
      {/*    )*/}
      {/*  : expanded &&*/}
      {/*    // rowIndex === index &&*/}
      {/*    subComponent && (*/}
      {/*      <Tr w={'full'}>*/}
      {/*        <Td colSpan={100}>*/}
      {/*          {subComponent({ row, expanded, columns, index })}*/}
      {/*        </Td>*/}
      {/*      </Tr>*/}
      {/*    )}*/}
      {/* // Child components should pass here */}
      {/*</>*/}

      {!expanded && isRowExpanded && subComponent && (
        <Tr w={'full'} key={index + '-sub-component-table-row'}>
          <Td colSpan={100}>
            {subComponent({ row, expanded: isRowExpanded, columns, index })}
          </Td>
        </Tr>
      )}
    </>
  );
};
