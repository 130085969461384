import { useNavigate } from 'react-router-dom';

interface Props {
  onClose();
}

export const useFAQ = (props: Props) => {
  const navigate = useNavigate();

  const onClickLink = url => {
    props.onClose();
    navigate(url);
  };

  const keywords = [
    'Cloud Accounts',
    'Policies',
    'Environment',
    'Integrations',
    'Whitelist',
    'Alert Workflow',
  ];

  const links = {
    PolicyHub: {
      label: 'Take me to the Policy Hub',
      url: '/policy-hub/blueprint',
    },
    Compliance: {
      label: 'Take me to Compliance dashboard',
      url: '/compliance',
    },
    Onboarding: {
      label: `I'd like to add Cloud Account`,
      url: '/setup/accounts/add/aws',
    },
    onboarding: {
      label: `I'd like to add Cloud Account`,
      url: '/setup/accounts/add',
    },
    AddEnvironment: {
      label: `I'd like to add Environment`,
      url: '/setup/environment/add',
    },
    CreateAlertWorkflow: {
      label: `Create an Alert Workflow`,
      url: '/setup/alert-workflow/add',
    },
    Integrations: {
      label: `Setup up third party integrations`,
      url: '/setup/integrations',
    },
    Notification: {
      label: `Set up notification (such as Slack, Microsoft Teams)`,
      url: '/setup/integrations',
    },
    Ticketing: {
      label: `Set up ticketing (such as JIRA, ServiceNow)`,
      url: '/setup/integrations',
    },
    Dashboard: {
      label: `Go to the Dashboard`,
      url: '/dashboard',
    },
    DashboardForFindings: {
      label: `Take me to dashboard for findings`,
      url: '/dashboard',
    },
    Vulnerabilities: {
      label: `Review vulnerabilities on workloads`,
      url: '/dashboard/vulnerability',
    },
    SecurityGraphs: {
      label: `Take me to security graphs`,
      url: '/visibility/access',
    },
    CreateLabels: {
      label: `Create a custom label`,
      url: '/inventory/context/add',
    },
    Labels: {
      label: `Review existing labels`,
      url: '/inventory/context',
    },
    DataScanning: {
      label: `I'd like to enable data scanning`,
      url: '/setup/data',
    },
    CreatePolicy: {
      label: `Create a custom policy`,
      url: '/policy-hub/policy/add',
    },
    Policies: {
      label: `Review existing custom policies`,
      url: '/policy-hub/policy',
    },
    Inventory: {
      label: `Take me to the inventory table`,
      url: '/inventory',
    },
    CloudInventory: {
      label: `Take me to cloud inventory`,
      url: '/inventory',
    },
    CreateWhitelist: {
      label: `Create a whitelist or blacklist policy`,
      url: '/inventory/whitelist/add',
    },
    Whitelist: {
      label: `Review existing whitelist policies`,
      url: '/inventory/whitelist',
    },
    Roles: {
      label: `Review existing roles`,
      url: '/setup/roles',
    },
    CreateRole: {
      label: `Customize cloud account access`,
      url: '/setup/roles/add',
    },
    Outpost: {
      label: `Deploy Ariksa Outpost for vulnerability detection`,
      url: '/setup/accounts',
    },
    DataOutpost: {
      label: `Deploy Ariksa Outpost for Data`,
      url: '/setup/accounts',
    },
    CustomDataClassifier: {
      label: `Add custom data classifiers`,
      url: '/setup/data',
    },
    Website: {
      label: `Visit Ariksa's website: https://ariksa.cloud`,
      url: 'https://ariksa.cloud',
    },
    AttachPolicyToEnvironment: {
      label: 'Attach Policy to Environment',
      url: '/policy-hub/blueprint',
    },
    AriksaCharts: {
      label: 'See Ariksa charts',
      url: '/reports/dashboard',
    },
    Reports: {
      label: 'Attach Policy to Environment',
      url: '/reports/all',
    },
    SnowflakeIntegration: {
      label: `Set up Snowflake integration`,
      url: '/setup/integrations',
    },
    SnowflakeDataClassification: {
      label: `Set up data classification for Snowflake`,
      url: '/setup/integrations',
    },
  };

  return { keywords, links, onClickLink };
};
