import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import {
  Box,
  ListItem,
  Stack,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { CustomerJourneyBulbIcon, IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import {
  DashboardWidgetTooltipIcon,
  renderResourcesWithIconAndCount,
} from 'containers/Dashboard/utils/utils';

export const InsightsForDataSources: React.FC = () => {
  const { insightsForDataSources } = useSelector(selectDataSecurityOverview);
  const { exposedAndUnencrypted, inactiveAndAnomaly } = insightsForDataSources;
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  return (
    <>
      <DashboardWidget
        label="Insights for Data Sources"
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
        content={renderResourcesWithIconAndCount([
          {
            label: 'Internet Exposed',
            count:
              exposedAndUnencrypted.data?.internet_accessible
                ?.total_resources || 0,
            isLoading: exposedAndUnencrypted.isLoading,
            iconType: IconTypes.Globe,
            iconColor: 'critical',
            iconTooltip: 'Publicly exposed data sources',
            onClick() {
              allEntitiesDisclosure.onOpen();
              setDetails({
                iconType: IconTypes.Globe,
                panel: PanelsEnum.PubliclyExposedDataSources,
                total:
                  exposedAndUnencrypted.data?.internet_accessible
                    ?.total_resources || 0,
              });
            },
          },
          {
            label: 'Unencrypted',
            count:
              exposedAndUnencrypted.data?.unencrypted?.total_resources || 0,
            isLoading: exposedAndUnencrypted.isLoading,
            iconType: IconTypes.UnencryptedDisk,
            iconColor: 'critical',
            iconTooltip: 'Unencrypted data sources',
            onClick() {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.UnencryptedDataSources,
                iconType: IconTypes.UnencryptedDisk,
                total:
                  exposedAndUnencrypted.data?.unencrypted?.total_resources || 0,
              });
            },
          },
          {
            label: 'Unused',
            count: exposedAndUnencrypted.data?.inactive?.total_resources || 0,
            isLoading: exposedAndUnencrypted.isLoading,
            iconType: IconTypes.UNUSED_ENTITIES,
            iconColor: 'critical',
            iconTooltip: 'Data sources that are not active',
            onClick() {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.InactiveDataSources,
                iconType: IconTypes.UNUSED_ENTITIES,
                total:
                  exposedAndUnencrypted.data?.inactive?.total_resources || 0,
              });
            },
          },
          {
            label: 'Anomalous Activity',
            count: inactiveAndAnomaly.data?.length || 0,
            isLoading: inactiveAndAnomaly.isLoading,
            iconType: IconTypes.AnomalousBehaviour,
            iconColor: 'critical',
            iconTooltip: 'Data sources with anomalous activity or changes',
            onClick() {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.AnomalousDataSources,
                iconType: IconTypes.AnomalousBehaviour,
                total: inactiveAndAnomaly.data?.length || 0,
              });
            },
          },
        ])}
        tooltip={{
          tooltip: (
            <Stack spacing={3}>
              <Box>
                Ariksa correlates runtime, logs, activity and configuration data
                to detect hidden risks and imminent threats to cloud resources.
              </Box>
              <Box>For deeper insights:</Box>
              <UnorderedList color="primary" spacing={2}>
                <ListItem pl={2}>Deploy Outpost for Data</ListItem>
                <ListItem pl={2}>Deploy Outpost for Vulnerability</ListItem>
                <ListItem pl={2}>Deploy runtime sensors</ListItem>
              </UnorderedList>
            </Stack>
          ),
          header: 'Insights for Data Sources',
          showArrow: false,
          icon: <CustomerJourneyBulbIcon />,
          button: <DashboardWidgetTooltipIcon />,
        }}
      />
      {allEntitiesDisclosure.isOpen && (
        <AllEntitiesDrawer
          isOpen={allEntitiesDisclosure.isOpen}
          onClose={allEntitiesDisclosure.onClose}
          {...details}
        />
      )}
    </>
  );
};
