import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ServiceTicketIcon = props => (
  <Icon
    viewBox="0 0 484.965 484.965"
    color="inherit"
    h="full"
    w="full"
    {...props}
  >
    <g>
      <path
        d="M352.806,234.798L250.268,132.26l14.588-14.588L367.394,220.21L352.806,234.798z M297.845,289.758L195.308,187.221
		l14.588-14.588L312.434,275.17L297.845,289.758z M242.886,344.717L140.348,242.179l14.589-14.589l102.538,102.538L242.886,344.717z
		 M187.92,399.681L85.381,297.142l14.589-14.589l102.539,102.539L187.92,399.681z"
      />
      <path
        d="M472.587,12.57c-8.019-8.044-18.689-12.472-30.175-12.472L303.599,0.526c-11.29,0.035-21.899,4.448-29.868,12.427
		L12.499,274.179c-23.585,25.623-8.014,51.987,0,59.997l138.386,138.386c25.746,23.135,51.988,8.014,59.997,0l261.227-261.227
		c7.979-7.979,12.392-18.588,12.427-29.873l0.428-138.813C485,31.295,480.602,20.615,472.587,12.57z M463.902,181.403
		c-0.014,5.793-2.282,11.243-6.382,15.344L196.294,457.973c-8.232,8.232-22.589,8.232-30.82,0L27.088,319.587
		c-4.115-4.116-12.072-18.591,0-30.82L288.325,27.542c4.091-4.101,9.541-6.368,15.334-6.382l138.884-0.428
		c5.834,0,11.309,2.277,15.43,6.406c4.116,4.131,6.378,9.618,6.357,15.451L463.902,181.403z"
      />
      <path
        d="M390.665,54.113c-22.248,0-40.283,18.035-40.283,40.283s18.035,40.283,40.283,40.283s40.283-18.035,40.283-40.283
		S412.913,54.113,390.665,54.113z M390.665,114.438c-11.069,0-20.041-8.973-20.041-20.042s8.973-20.042,20.041-20.042
		s20.041,8.973,20.041,20.042S401.733,114.438,390.665,114.438z"
      />
    </g>
  </Icon>
);
