import React, { useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { map, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { getSensitiveLabelColor } from 'containers/Visibility/Data/Components/hooks/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const SensitiveDataByType: React.FC = props => {
  const { labelCount } = useSelector(selectDataDashboard);
  const [sortedLabels, setSortedLabels] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const items = {
      PII: labelCount.data?.pii,
      PCI: labelCount.data?.pci,
      PHI: labelCount.data?.phi,
      Credential: labelCount.data?.credential,
    };
    const sortedLabels = orderBy(
      map(items, (count, key) => ({
        label: key,
        count,
        bg: getSensitiveLabelColor(key),
      })),
      ['count'],
      ['desc'],
    );

    setSortedLabels(sortedLabels);
  }, [labelCount.data]);

  const columns = [
    {
      header: <Box pl={9}>LABEL</Box>,
      accessor: 'region',
      render: ({ row }) => (
        <HStack spacing={4}>
          <Box
            boxSize={5}
            border="1px solid"
            borderColor={customTheme.colors.gray['200']}
            bg={row.bg}
            borderRadius={6}
          />
          <Box>{row.label}</Box>
        </HStack>
      ),
      styles: { td: { w: '70%' } },
    },
    {
      header: 'COUNT',
      render: ({ row }) => formatNumber(row.count, 1),
      styles: {
        cell: { textAlign: 'right', pr: 12 },
        header: { textAlign: 'right', pr: 14 },
      },
    },
  ];

  return (
    <DashboardWidget
      label="Sensitive Data By Labels"
      content={
        <DashboardTable
          columns={columns}
          data={sortedLabels}
          isLoading={labelCount.isLoading}
        />
      }
    />
  );
};
