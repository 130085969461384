import React from 'react';

import { useSelector } from 'react-redux';

import { selectDashboard } from 'containers/Dashboard/selectors';
import { FindingsWithLineChart } from 'containers/Dashboard/utils/FindingsWithLineChart';

export const InfrastructurePostureFindings: React.FC = () => {
  const { cloudPostureOpenFindingsHistory } = useSelector(selectDashboard);

  return (
    <FindingsWithLineChart
      history={cloudPostureOpenFindingsHistory.data}
      isLoadingChart={cloudPostureOpenFindingsHistory.isLoading}
      label="Critical And High Severity Issues"
    />
  );
};
