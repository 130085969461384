import {
  AuditTrailServiceApiAuditTrailServiceActionsPerformedOnDayRequest,
  AuditTrailServiceApiAuditTrailServiceResourceActionsDailyRequest,
  V1ActionsPerformedOnDayResponse,
  V1ResourceActionsDailyResponse,
} from '@ariksa/audittrail';
import {
  AuditTrailServiceApiAuditTrailServiceActionsPerformedMonthlyRequest,
  AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest,
  V1ActionsPerformedMonthlyResponse,
  V1ResourceActionsMonthlyResponse,
} from '@ariksa/audittrail/api';
import { CloudProviders } from '@ariksa/cloud-account/api';
import {
  AudittrailApiGetInsecureAclResponseRequest,
  AudittrailApiGetS3CrossAccountRequest,
  AudittrailApiGetS3InternetActivityRequest,
  BlueprintApiAddExceptionRequest,
  BlueprintExceptionRead,
  ComplianceApiGetComplianceReportForResourceRequest,
  PageComplianceReport,
  S3CrossAccountAccess,
  S3InsecureACL,
  S3InternetActivity,
} from '@ariksa/compliance-policies/api';
import {
  DataTypeInfo,
  DataTypesApiGetDataTypesBySourceRequest,
  DistributionByDocumentType,
  ObjectsDistributionCount,
  PageDocumentClassificationForSourceInfo,
  PageFileMetaDataInfo,
  RepositoryBranchesInfo,
  ResourcesApiGetObjectsDistributionCountRequest,
  ResourcesApiGetSummarySourcesCountRequest,
  SensitiveDataApiGetSensitiveDataLabelsCountRequest,
  SourceCountSummary,
  VisibilityDataApiGetDocumentClassificationByTypeForSourceRequest,
  VisibilityDataApiGetDocumentClassificationTypeDistributionForSourceRequest,
  VisibilityDataApiGetFileMetadataInfoRequest,
  VisibilityDataApiGetRepositoryBranchesInfoRequest,
} from '@ariksa/data-scanning/api';
import { ConnectionByProcessPayload } from '@ariksa/ebpf-data-collector/api';
import {
  DeployedResourcePagination,
  IACApiDeployedResourcesRequest,
  IACApiDeploymetSummaryRequest,
} from '@ariksa/inventory-core';
import {
  ContextApiGetRiskContextDataScanRequest,
  InsightApiGetCanAccessRelationSummaryRequest,
  MapApiGetAccessGraphRequest,
  PackageCategory,
  RiskContextResponse,
  SearchResponse,
  SearchV2ApiNetworkMapRequest,
  VulnerabilitiesApiGetInstanceSummaryByIdRequest,
  VulnerabilitiesApiGetVulnerabilityByIdRequest,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  ResourceApiGetResourceByUuidRequest,
  AccessSummaryTypes,
  NativeResources,
  AppSchemasIacIacDeploymentSummary,
  PrivilegeSummary,
  InsightApiGetPrivilegeSummaryRequest,
  InsightApiGetCanAccessActionsRequest,
  CrudPermissions,
  ResourceApiGetResourceMetadataRequest,
  ResourceMetadata,
  ContextApiGetRiskContextResRequest,
  ProcessResponse,
  EbpfApiGetUniqueProcessesRequest,
  ConnectionResponse,
  InsightV2ApiGetVmDeployedByAmiRequest,
  ResourceVulnerabilities,
  VulnerabilitiesApiGetVulnerabilitiesRequest,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertPaginatedResponse,
  AlertsApiGetAlertsRequest,
  AlertsApiGetEntitySeverityListRequest,
  AlertsApiGetEntityTimelineRequest,
  EntitySeverityListResponse,
  EntityTimelineResponse,
  SnoozedAlertsApiAddSnoozedAlertRequest,
  SnoozeDetailsRead,
} from '@ariksa/notification/api';
import {
  ContainerInfo,
  DefaultApiGetEcrImageListRequest,
  ECRImageVisibilityDashboard,
  InstanceSummary,
  ItemApiGetContainersRequest,
  ItemApiGetInstanceSummaryByIdRequest,
  ItemApiGetVulnerabilityByIdRequest,
  LibraryType,
  VulnerabilityResponse,
  VulnerabilityType,
} from '@ariksa/scan-analysis/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { forEach, values } from 'lodash';
import { QueryAction, QueryStatus } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { publishedAtOptions } from 'components/DataEntry';
import { PageInfo } from 'components/Navigation';
import { resourceVulnerabilityKey } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/utils';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ActiveResourceInfo, ActiveResourceState } from './types';

// The initial state of the SetupUsers container
export const initialState: ActiveResourceState = {
  resourceType: '' as NativeResources,
  resourceUuid: '',
  resourceAccountId: '',
  resourceId: '',
  searchTerm: '',
  cloudProvider: CloudProviders.Aws,
  alerts: PagedQueryState.init([]),
  riskContext: QueryState.init([]),
  resourceDetails: QueryState.init({}),
  resourceInsight: QueryState.init({}),
  entityTimeline: QueryState.init<EntityTimelineResponse[]>([]),
  vulnerabilitySummary: QueryState.init({}),
  vulnerabilityDetails: {
    os: {},
    application: {},
    library: {},
  },
  instanceVulnerabilityDetails: QueryState.init({}),
  resourceSeverity: QueryState.init({}),
  activeTabIndex: 0,
  networkMap: QueryState.init({}),
  networkMapScale: 1,

  piiByBucketName: PagedQueryState.init([]),
  dataRiskContext: QueryState.init([]),
  canAccessSummary: {
    canAccess: QueryState.init([]),
    canAccessData: QueryState.init([]),
    accessByIdentity: QueryState.init([]),
    accessMap: QueryState.init({}),
    expandedAccessMap: QueryState.init({}),
    privilegeSummary: QueryState.init([]),
  },
  permissions: {
    insecureAcl: QueryState.init([]),
  },
  activity: {
    s3CrossAccount: QueryState.init([]),
    s3InternetActivity: QueryState.init([]),
  },
  data: {
    dataTypesBySource: QueryState.init([]),
    documentTypesBySource: PagedQueryState.init([]),
    summary: QueryState.init({}),
    dataLabels: QueryState.init({}),
    typesOfFiles: QueryState.init({} as ObjectsDistributionCount),
    distributionByDocumentType: QueryState.init([]),
  },
  instanceSummaryByID: QueryState.init({}),
  containerVulnerability: QueryState.init([]),
  imageVulnerabilities: {},
  ecrImageContainers: QueryState.init([]),

  linkedResourceDetails: QueryState.init({}),

  ecrContainerImages: PagedQueryState.init([]),
  branches: QueryState.init([]),
  alertStatus: QueryState.init({}),
  alertAction: QueryState.init({}),
  accessed: QueryState.init({}),
  actionsOnDay: QueryState.init({}),
  libraryType: undefined, //LibraryType
  showResolvedVulnerabilities: false,
  vulnerabilityPublishedTime: publishedAtOptions[0],
  vulnerabilitySeverity: '',
  isPatchable: '',
  activeTabName: '',

  deploymentSummary: PagedQueryState.init([]),
  accountDeployedResources: {},
  permissionsMap: QueryState.init([]),
  resourceActionsMonthly: QueryState.init({}),
  resourceActionsDaily: QueryState.init({}),
  runningProcesses: PagedQueryState.init([]),
  amiHosts: {
    hosts: PagedQueryState.init([]),
    riskContext: QueryState.init({}),
    vulnerabilities: {},
  },
  processConnections: {},
  rules: PagedQueryState.init([]),
};

const setupSlice = createSlice({
  name: 'activeResourceState',
  initialState,
  reducers: {
    getRunningProcesses(
      state,
      action: QueryAction<ProcessResponse, EbpfApiGetUniqueProcessesRequest>,
    ) {
      state.runningProcesses = PagedQueryState.next(
        state.runningProcesses,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total },
      );
    },
    getProcessConnections(
      state,
      actions: QueryAction<ConnectionResponse, ConnectionByProcessPayload>,
    ) {
      // if the key is not in the state, initialize it
      const payload = actions.payload.q;
      const key =
        payload.connection_type +
        '_' +
        payload.agent_id +
        '_' +
        payload.src_process_name;
      if (!state.processConnections[key]) {
        state.processConnections[key] = PagedQueryState.init([]);
      }

      state.processConnections[key] = PagedQueryState.next(
        state.processConnections[key],
        actions,
        { mapData: r => r.items, mapTotalCount: r => r.total },
      );
    },
    getAlertStatus(
      state,
      action: QueryAction<
        AlertLogsResponse[],
        AlertLogsApiGetLogsForIdsRequest
      >,
    ) {
      state.alertStatus = QueryState.next(state.alertStatus, action, {
        mapData: res => {
          let data = {};
          forEach(res, o => (data[o.alert_id] = o.logs));
          return data;
        },
      });
    },
    getPiiRiskContext(
      state,
      action: QueryAction<
        Record<string, RiskContextResponse[]>,
        ContextApiGetRiskContextDataScanRequest
      >,
    ) {
      state.dataRiskContext = QueryState.next(state.dataRiskContext, action);
    },
    getPiiByBucketName(
      state,
      action: QueryAction<
        PageFileMetaDataInfo,
        VisibilityDataApiGetFileMetadataInfoRequest
      >,
    ) {
      state.piiByBucketName = PagedQueryState.next(
        state.piiByBucketName,
        action,
        { mapData: res => res.items, mapTotalCount: res => res.total ?? 0 },
      );
    },
    updatePiiDataPagination(state, action: PayloadAction<PageInfo>) {
      state.piiByBucketName.page.info = action.payload;
    },
    updateECRImagesPagination(state, action: PayloadAction<PageInfo>) {
      state.ecrContainerImages.page.info = action.payload;
    },
    updateAlertsPagination(state, action: PayloadAction<PageInfo>) {
      state.alerts.page.info = action.payload;
    },
    updateNetworkMapScale(state, action: PayloadAction<number>) {
      state.networkMapScale = action.payload;
    },
    setActiveTabName(state, action: PayloadAction<string>) {
      state.activeTabName = action.payload;
    },
    setActiveTabIndex(state, action: PayloadAction<number>) {
      state.activeTabIndex = action.payload;
    },
    getNetworkMap(
      state,
      action: QueryAction<SearchResponse, SearchV2ApiNetworkMapRequest>,
    ) {
      state.networkMap = QueryState.next(state.networkMap, action);
    },
    getRepositoryBranches(
      state,
      action: QueryAction<
        RepositoryBranchesInfo[],
        VisibilityDataApiGetRepositoryBranchesInfoRequest
      >,
    ) {
      state.branches = QueryState.next(state.branches, action);
    },
    getCrudPermissions(
      state,
      action: QueryAction<
        Array<CrudPermissions>,
        InsightApiGetCanAccessActionsRequest
      >,
    ) {
      state.permissionsMap = QueryState.next(state.permissionsMap, action);
    },
    getAccessed(
      state,
      action: QueryAction<
        V1ActionsPerformedMonthlyResponse,
        AuditTrailServiceApiAuditTrailServiceActionsPerformedMonthlyRequest
      >,
    ) {
      state.accessed = QueryState.next(state.accessed, action);
    },
    getActionsPerformedOnDay(
      state,
      action: QueryAction<
        V1ActionsPerformedOnDayResponse,
        AuditTrailServiceApiAuditTrailServiceActionsPerformedOnDayRequest
      >,
    ) {
      state.actionsOnDay = QueryState.next(state.actionsOnDay, action);
    },
    updateResource(state, action: PayloadAction<ActiveResourceInfo>) {
      const {
        resourceType = '',
        uuid = '',
        accountId = '',
        resourceId = '',
        vulnerabilitySeverity,
        isPatchable = '',
        tabIndex = 0,
        showResolvedVulnerabilities = false,
        vulnerabilityPublishedTime = publishedAtOptions[0],
        cloudProvider = CloudProviders.Aws,
        searchTerm,
      } = action.payload as any;

      state.resourceType = resourceType;
      state.resourceUuid = uuid;
      state.resourceAccountId = accountId;
      state.resourceId = resourceId;
      state.activeTabIndex = tabIndex;
      state.cloudProvider = cloudProvider;
      state.showResolvedVulnerabilities = showResolvedVulnerabilities;
      state.vulnerabilityPublishedTime = vulnerabilityPublishedTime;
      state.vulnerabilitySeverity = vulnerabilitySeverity;
      state.isPatchable = isPatchable;
      state.searchTerm = searchTerm;

      // reset the active resource state
      state.resourceInsight = QueryState.init({});
      state.resourceDetails = QueryState.init({});
      state.alerts = PagedQueryState.init([]);
    },
    getResourceDetails(
      state,
      action: QueryAction<any, ResourceApiGetResourceByUuidRequest>,
    ) {
      state.resourceDetails = QueryState.next(state.resourceDetails, action);
    },
    getResourceInsight(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2Request>,
    ) {
      state.resourceInsight = QueryState.next(state.resourceInsight, action, {
        mapData: r => r?.items?.[0],
      });
    },
    getAlerts(
      state,
      action: QueryAction<AlertPaginatedResponse, AlertsApiGetAlertsRequest>,
    ) {
      state.alerts = PagedQueryState.next(state.alerts, action, {
        mapData: r => r.results,
        mapTotalCount: r => r.total_results,
      });
    },

    //alerts timeline
    getEntityTimeline(
      state,
      action: QueryAction<
        EntityTimelineResponse[],
        AlertsApiGetEntityTimelineRequest
      >,
    ) {
      state.entityTimeline = QueryState.next(state.entityTimeline, action);
    },

    /*get risk context*/
    getRiskContext(
      state,
      action: QueryAction<
        Record<string, RiskContextResponse[]>,
        ContextApiGetRiskContextResRequest
      >,
    ) {
      state.riskContext = QueryState.next(state.riskContext, action, {
        mapData: r => r?.[action.payload.q.riskContextRequest.uuids[0]],
      });
    },

    //vulnerability summary
    getVulnerabilitySummary(
      state,
      action: QueryAction<
        InstanceSummary,
        ItemApiGetInstanceSummaryByIdRequest
      >,
    ) {
      state.vulnerabilitySummary = QueryState.next(
        state.vulnerabilitySummary,
        action,
      );
    },
    getAMIVulnerabilitySummary(
      state,
      action: QueryAction<
        InstanceSummary,
        VulnerabilitiesApiGetInstanceSummaryByIdRequest
      >,
    ) {
      state.vulnerabilitySummary = QueryState.next(
        state.vulnerabilitySummary,
        action,
      );
    },

    //vulnerability details
    getVulnerabilityDetails(
      state,
      action: QueryAction<
        VulnerabilityResponse,
        ItemApiGetVulnerabilityByIdRequest
      >,
    ) {
      const {
        instanceId,
        vulnerabilityType,
        containerId = '',
      } = action.payload.q;
      const resourceKey = resourceVulnerabilityKey(
        instanceId ?? '',
        containerId ?? '',
      );
      switch (action.payload.q.vulnerabilityType) {
        case VulnerabilityType.Application:
          if (!state.vulnerabilityDetails.application[resourceKey]) {
            state.vulnerabilityDetails.application[
              resourceKey
            ] = PagedQueryState.init([]);
          }
          state.vulnerabilityDetails.application[
            resourceKey
          ] = PagedQueryState.next(
            state.vulnerabilityDetails.application[resourceKey],
            action,
            { mapData: r => r.items, mapTotalCount: r => r?.total ?? 0 },
          );

          break;
        case VulnerabilityType.Os:
          if (!state.vulnerabilityDetails.os[resourceKey]) {
            state.vulnerabilityDetails.os[resourceKey] = PagedQueryState.init(
              [],
            );
          }
          state.vulnerabilityDetails.os[
            resourceKey
          ] = PagedQueryState.next(
            state.vulnerabilityDetails.os[resourceKey],
            action,
            { mapData: r => r.items, mapTotalCount: r => r?.total ?? 0 },
          );
          break;
        case VulnerabilityType.Library:
          if (!state.vulnerabilityDetails.library[resourceKey]) {
            state.vulnerabilityDetails.library[
              resourceKey
            ] = PagedQueryState.init([]);
          }
          state.vulnerabilityDetails.library[
            resourceKey
          ] = PagedQueryState.next(
            state.vulnerabilityDetails.library[resourceKey],
            action,
            { mapData: r => r.items, mapTotalCount: r => r?.total ?? 0 },
          );
          break;
      }
    },

    getAMIVulnerabilityDetails(
      state,
      action: QueryAction<
        VulnerabilityResponse,
        VulnerabilitiesApiGetVulnerabilityByIdRequest
      >,
    ) {
      const { resourceId, packageCategory, ...payload } = action.payload.q;
      switch (action.payload.q.packageCategory) {
        case PackageCategory.ApplicationPackage:
          if (!state.vulnerabilityDetails.application[resourceId]) {
            state.vulnerabilityDetails.application[
              resourceId
            ] = PagedQueryState.init([]);
          }
          state.vulnerabilityDetails.application[
            resourceId
          ] = PagedQueryState.next(
            state.vulnerabilityDetails.application[resourceId],
            action,
            { mapData: r => r.items, mapTotalCount: r => r?.total ?? 0 },
          );
          break;
        case PackageCategory.OsPackage:
          if (!state.vulnerabilityDetails.os[resourceId]) {
            state.vulnerabilityDetails.os[resourceId] = PagedQueryState.init(
              [],
            );
          }
          state.vulnerabilityDetails.os[resourceId] = PagedQueryState.next(
            state.vulnerabilityDetails.os[resourceId],
            action,
            { mapData: r => r.items, mapTotalCount: r => r?.total ?? 0 },
          );
          break;
        case PackageCategory.LibraryPackage:
          if (!state.vulnerabilityDetails.library[resourceId]) {
            state.vulnerabilityDetails.library[
              resourceId
            ] = PagedQueryState.init([]);
          }
          state.vulnerabilityDetails.library[
            resourceId
          ] = PagedQueryState.next(
            state.vulnerabilityDetails.library[resourceId],
            action,
            { mapData: r => r.items, mapTotalCount: r => r?.total ?? 0 },
          );
          break;
      }
    },

    getECRContainerImageVulnerability(
      state,
      action: QueryAction<
        VulnerabilityResponse,
        ItemApiGetVulnerabilityByIdRequest
      >,
    ) {
      const { instanceId } = action.payload.q;
      if (!!instanceId) {
        if (!state.imageVulnerabilities?.[instanceId]) {
          state.imageVulnerabilities[instanceId] = PagedQueryState.init([]);
        }
        state.imageVulnerabilities[instanceId] = PagedQueryState.next(
          state.imageVulnerabilities?.[instanceId],
          action,
          { mapData: r => r.items, mapTotalCount: r => r?.total ?? 0 },
        );
      }
    },

    getECRImageContainers(
      state,
      action: QueryAction<ContainerInfo[], ItemApiGetContainersRequest>,
    ) {
      state.ecrImageContainers = QueryState.next(
        state.ecrImageContainers,
        action,
      );
    },

    updateVulnerabilityPageInfo(
      state,
      action: PayloadAction<{ pageInfo: PageInfo; type: VulnerabilityType }>,
    ) {
      throw new Error('Not implemented');
      // state.vulnerabilityDetails[action.payload.type].page.info =
      //   action.payload.pageInfo;
    },

    //get entity severity
    getEntitySeverity(
      state,
      action: QueryAction<
        EntitySeverityListResponse,
        AlertsApiGetEntitySeverityListRequest
      >,
    ) {
      state.resourceSeverity = QueryState.next(state.resourceSeverity, action, {
        mapData: r => values(r?.severity)[0],
      });
    },

    //get can access data
    getCanAccessSummary(
      state,
      action: QueryAction<any, InsightApiGetCanAccessRelationSummaryRequest>,
    ) {
      switch (action.payload.q.summaryType) {
        case AccessSummaryTypes.CanAccess:
          state.canAccessSummary.canAccess = QueryState.next(
            state.canAccessSummary.canAccess,
            action,
          );
          break;
        case AccessSummaryTypes.CanAccessData:
          state.canAccessSummary.canAccessData = QueryState.next(
            state.canAccessSummary.canAccessData,
            action,
          );
          break;
        case AccessSummaryTypes.AccessByIdentity:
          state.canAccessSummary.accessByIdentity = QueryState.next(
            state.canAccessSummary.accessByIdentity,
            action,
          );
          break;
      }
    },

    getAccessMapData(
      state,
      action: QueryAction<SearchResponse, MapApiGetAccessGraphRequest>,
    ) {
      if (action.payload.q.expanded) {
        state.canAccessSummary.expandedAccessMap = QueryState.next(
          state.canAccessSummary.expandedAccessMap,
          action,
        );
      } else {
        state.canAccessSummary.accessMap = QueryState.next(
          state.canAccessSummary.accessMap,
          action,
        );
      }
    },

    getPrivilegeSummary(
      state,
      action: QueryAction<
        PrivilegeSummary[],
        InsightApiGetPrivilegeSummaryRequest
      >,
    ) {
      state.canAccessSummary.privilegeSummary = QueryState.next(
        state.canAccessSummary.privilegeSummary,
        action,
      );
    },
    getDeploymentSummary(
      state,
      action: QueryAction<
        AppSchemasIacIacDeploymentSummary[],
        IACApiDeploymetSummaryRequest
      >,
    ) {
      state.deploymentSummary = QueryState.next(
        state.deploymentSummary,
        action,
      );
    },
    getAccountDeployedResources(
      state,
      action: QueryAction<
        DeployedResourcePagination,
        IACApiDeployedResourcesRequest
      >,
    ) {
      const id = `${action.payload.q.uuid}-${action.payload.q.cloudAccountUuid}`;
      if (!state.accountDeployedResources[id] || !action.payload.status) {
        state.accountDeployedResources[id] = PagedQueryState.init([]);
      }

      state.accountDeployedResources[id] = PagedQueryState.next(
        state.accountDeployedResources[id],
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r.total ?? 0,
        },
      );
    },

    updateLibraryType(state, action: PayloadAction<LibraryType>) {
      state.libraryType = action.payload;
    },

    updateIsPatchable(state, action: PayloadAction<string>) {
      state.isPatchable = action.payload;
    },

    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },

    updateVulnerabilitySeverity(state, action: PayloadAction<string>) {
      state.vulnerabilitySeverity = action.payload;
    },

    updateShowResolvedVulnerabilities(state, action: PayloadAction<boolean>) {
      state.showResolvedVulnerabilities = action.payload;
    },

    updateVulnerabilityPublishedAt(
      state,
      action: PayloadAction<Record<string, any>>,
    ) {
      state.vulnerabilityPublishedTime = action.payload;
    },

    //get permissions
    getPermissions(
      state,
      action: QueryAction<
        S3InsecureACL[],
        AudittrailApiGetInsecureAclResponseRequest
      >,
    ) {
      state.permissions.insecureAcl = QueryState.next(
        state.permissions.insecureAcl,
        action,
      );
    },
    getS3InternetActivity(
      state,
      action: QueryAction<
        S3InternetActivity[],
        AudittrailApiGetS3InternetActivityRequest
      >,
    ) {
      state.activity.s3InternetActivity = QueryState.next(
        state.activity.s3InternetActivity,
        action,
      );
    },
    getS3CrossAccount(
      state,
      action: QueryAction<
        S3CrossAccountAccess[],
        AudittrailApiGetS3CrossAccountRequest
      >,
    ) {
      state.activity.s3CrossAccount = QueryState.next(
        state.activity.s3CrossAccount,
        action,
      );
    },

    getContainerVulnerability(
      state,
      action: QueryAction<ContainerInfo[], ItemApiGetContainersRequest>,
    ) {
      state.containerVulnerability = QueryState.next(
        state.containerVulnerability,
        action,
      );
    },
    resetContainerVulnerability(state) {
      state.containerVulnerability.data =
        initialState.containerVulnerability.data;
    },
    getInstanceSummary(
      state,
      action: QueryAction<
        InstanceSummary,
        ItemApiGetInstanceSummaryByIdRequest
      >,
    ) {
      const status = QueryState.emptyFromAction(action);
      state.instanceSummaryByID.error = status.error;
      state.instanceSummaryByID.isLoading = status.isLoading;
      state.instanceSummaryByID.isSuccess = status.isSuccess;
      state.instanceSummaryByID.isError = status.isError;
      if (action.payload.data && action.payload?.q?.containerId)
        state.instanceSummaryByID.data = {
          ...state.instanceSummaryByID.data,
          [action.payload.q?.containerId]: action.payload.data,
        };
    },

    getLinkedResourceDetails(
      state,
      action: QueryAction<any, InsightV2ApiInsightV2Request>,
    ) {
      state.linkedResourceDetails = QueryState.next(
        state.linkedResourceDetails,
        action,
      );
    },

    getECRContainerImages(
      state,
      action: QueryAction<
        ECRImageVisibilityDashboard,
        DefaultApiGetEcrImageListRequest
      >,
    ) {
      state.ecrContainerImages = PagedQueryState.next(
        state.ecrContainerImages,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total },
      );
    },

    /*Add alert as exception*/
    addException(
      state,
      action: QueryAction<
        BlueprintExceptionRead,
        BlueprintApiAddExceptionRequest
      >,
    ) {
      state.alertAction = QueryState.next(state.alertAction, action);
    },

    snoozeAlert(
      state,
      action: QueryAction<
        SnoozeDetailsRead,
        SnoozedAlertsApiAddSnoozedAlertRequest
      >,
    ) {
      state.alertAction = QueryState.next(state.alertAction, action);
    },
    getResourceActionsMonthly(
      state,
      action: QueryAction<
        V1ResourceActionsMonthlyResponse,
        AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest
      >,
    ) {
      state.resourceActionsMonthly = QueryState.next(
        state.resourceActionsMonthly,
        action,
      );
    },
    getResourceActionsDaily(
      state,
      action: QueryAction<
        V1ResourceActionsDailyResponse,
        AuditTrailServiceApiAuditTrailServiceResourceActionsDailyRequest
      >,
    ) {
      state.resourceActionsDaily = QueryState.next(
        state.resourceActionsDaily,
        action,
      );
    },

    /*-----------------------------------Data-----------------------------------*/
    getDataTypesBySource(
      state,
      action: QueryAction<
        DataTypeInfo[],
        DataTypesApiGetDataTypesBySourceRequest
      >,
    ) {
      state.data.dataTypesBySource = QueryState.next(
        state.data.dataTypesBySource,
        action,
      );
    },
    getDocumentTypesBySource(
      state,
      action: QueryAction<
        PageDocumentClassificationForSourceInfo,
        VisibilityDataApiGetDocumentClassificationByTypeForSourceRequest
      >,
    ) {
      state.data.documentTypesBySource = PagedQueryState.next(
        state.data.documentTypesBySource,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total ?? 0 },
      );
    },
    updateDocumentTypesBySourcePagination(
      state,
      action: PayloadAction<PageInfo>,
    ) {
      state.data.documentTypesBySource.page.info = action.payload;
    },

    getDataSummary(
      state,
      action: QueryAction<
        SourceCountSummary,
        ResourcesApiGetSummarySourcesCountRequest
      >,
    ) {
      state.data.summary = QueryState.next(state.data.summary, action);
    },

    getDataLabels(
      state,
      action: QueryAction<
        Record<string, number>,
        SensitiveDataApiGetSensitiveDataLabelsCountRequest
      >,
    ) {
      state.data.dataLabels = QueryState.next(state.data.dataLabels, action);
    },

    getDistributionByTypeOfFiles(
      state,
      action: QueryAction<
        ObjectsDistributionCount,
        ResourcesApiGetObjectsDistributionCountRequest
      >,
    ) {
      state.data.typesOfFiles = QueryState.next(
        state.data.typesOfFiles,
        action,
      );
    },

    getDistributionByDocumentType(
      state,
      action: QueryAction<
        DistributionByDocumentType,
        VisibilityDataApiGetDocumentClassificationTypeDistributionForSourceRequest
      >,
    ) {
      state.data.distributionByDocumentType = QueryState.next(
        state.data.distributionByDocumentType,
        action,
      );
    },

    getGcpResourceDetails(
      state,
      action: QueryAction<
        ResourceMetadata,
        ResourceApiGetResourceMetadataRequest
      >,
    ) {
      state.resourceInsight = QueryState.next(state.resourceInsight, action);
    },

    getAMIHosts(
      state,
      action: QueryAction<
        InsightV2Pagination,
        InsightV2ApiGetVmDeployedByAmiRequest
      >,
    ) {
      state.amiHosts.hosts = PagedQueryState.next(
        state.amiHosts.hosts,
        action,
        {
          mapData: res => res.items,
          mapTotalCount: res => res.total ?? 0,
        },
      );
    },
    getAMIHostsRiskContext(
      state,
      action: QueryAction<
        Record<string, RiskContextResponse[]>,
        ContextApiGetRiskContextResRequest
      >,
    ) {
      state.amiHosts.riskContext = QueryState.next(
        state.amiHosts.riskContext,
        action,
      );
    },
    getAMIHostsVulnerabilities(
      state,
      action: QueryAction<
        ResourceVulnerabilities[],
        VulnerabilitiesApiGetVulnerabilitiesRequest
      >,
    ) {
      const resourceType =
        action.payload.q.resourceVulnerabilitiesRequest.native_resource;
      if (!state.amiHosts.vulnerabilities[resourceType!]) {
        state.amiHosts.vulnerabilities[resourceType!] = QueryState.init(
          {} as any,
          {
            status: QueryStatus.pending,
          },
        );
        return;
      } else {
        state.amiHosts.vulnerabilities[resourceType!] = QueryState.next(
          state.amiHosts.vulnerabilities[resourceType!],
          action,
          {
            mapData: res => {
              let data: Record<string, any> = {};
              forEach(res, o => (data[o?.resource_id!] = o));
              return data;
            },
          },
        );
      }
    },
    updateAMIHostsPagination(state, action: PayloadAction<PageInfo>) {
      state.amiHosts.hosts.page.info = action.payload;
    },
    loadRules(
      state,
      action: QueryAction<
        PageComplianceReport,
        ComplianceApiGetComplianceReportForResourceRequest
      >,
    ) {
      state.rules = PagedQueryState.next(state.rules, action, {
        mapData: r => r?.items,
        mapTotalCount: r => r?.total ?? 0,
      });
    },
    updateRulesPageInfo(state, action: PayloadAction<PageInfo>) {
      state.rules.page.info = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = setupSlice;
