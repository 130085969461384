import { customTheme } from 'theme';

export const defaultStyles = {
  item: {
    m: 0,
    //pl: 8,
    pt: 2,
    border: 'none',
    borderColor: customTheme.colors.gray['200'],
    borderRadius: 6,
    boxShadow: `none`,
    alignItems: 'top',
  },
  innerWrapper: {
    border: '1px solid',
    borderColor: customTheme.colors.gray['100'],
    borderRadius: 6,
    py: 1,
  },
  button: {
    fontSize: 'sm',
    px: 0,
    pb: 1,
    alignItems: 'baseline',
    //justifyContent: 'space-between',
    _focus: { border: 'none' },
    _hover: { bg: 'transparent' },
  },
};

export const linkStyles = {
  color: 'primary',
  textDecoration: 'underline',
  cursor: 'pointer',
};
