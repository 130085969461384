import React, { useState, useRef, FC } from 'react';

import { Box, Center, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { IconTypes, ListIcon } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { DataSecurityFilters } from 'containers/Visibility/Data/Components/DataTable/DataSecurityFilters';
import { DataSourcesByResourceType } from 'containers/Visibility/Data/Components/DataTable/DataSourcesByResourceType';
import { DataSourcesSubcomponent } from 'containers/Visibility/Data/Components/DataTable/DataSourcesSubcomponent';
import { DocumentSubTypes } from 'containers/Visibility/Data/Components/DataTable/DocumentSubTypes';
import { DocumentTypeDataSources } from 'containers/Visibility/Data/Components/DataTable/DocumentTypeDataSources';
import { useDataTableColumns } from 'containers/Visibility/Data/Components/hooks/useDataTableColumns';
import { renderDataSecurityHeader } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { actions } from 'containers/Visibility/Data/slice';

interface Props {
  setShowDataTypeSources?(val: boolean);
  setCurrentDataType(val: Record<string, any>);
}

export const DataTableComponent: FC<Props> = props => {
  const { setShowDataTypeSources, setCurrentDataType } = props;
  const { selectedTab, dataSources, allDataResourceTypes } = useSelector(
    selectDataDashboard,
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef(document.querySelector('.portal-container'));
  const { tableColumns, tableActionModals } = useDataTableColumns(
    MetadataTabs.Data,
  );
  const dispatch = useDispatch();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const subTypeDisclosure = useDisclosure();
  const dataSourcesDisclosure = useDisclosure();
  const dataSourcesByResourceType = useDisclosure();

  const renderTable = () => (
    <Stack h="full" spacing={3} w="full">
      {renderDataSecurityHeader(selectedTab)}
      <HStack w="full" justify="flex-end">
        <DataSecurityFilters
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
      </HStack>

      <Box flex={1} pt={3} pos="relative">
        <Table
          noDataMessage="No data sources being analyzed"
          data={
            selectedTab === 'all' ? allDataResourceTypes.data : dataSources.data
          }
          columns={[
            ...tableColumns,
            ...(selectedTab === 'DataSources' || selectedTab === 'all'
              ? []
              : [
                  {
                    header: 'Actions',
                    render: ({ row }) => (
                      <Center>
                        <ActionButton
                          icon={<ListIcon />}
                          label="Show all sources"
                          onClick={() => {
                            if (selectedTab === 'DataTypes') {
                              setShowDataTypeSources?.(true);
                              setCurrentDataType(row);
                            } else if (selectedTab === 'DocumentTypes') {
                              dataSourcesDisclosure.onOpen();
                              setCurrentRecord(row);
                            }
                          }}
                        />
                      </Center>
                    ),
                  },
                ]),
          ]}
          isLoading={
            selectedTab === 'all'
              ? allDataResourceTypes.isLoading
              : dataSources.isLoading
          }
          cursor={selectedTab === IconTypes.DataSources ? 'pointer' : 'default'}
          styles={{ header: { position: 'relative', zIndex: 800 } }}
          {...(selectedTab === IconTypes.DataSources
            ? {
                subComponent: ({ expanded, row }) =>
                  expanded && (
                    <DataSourcesSubcomponent row={row} isExpanded={expanded} />
                  ),
              }
            : {})}
          {...(selectedTab === 'all'
            ? {}
            : {
                pagination: {
                  totalCount: dataSources?.page.totalCount,
                  pageInfo: dataSources.page.info,
                  onChange: pageInfo => {
                    dispatch(actions.updateDataTablePageInfo(pageInfo));
                  },
                },
              })}
          {...(selectedTab === 'DocumentTypes' || selectedTab === 'all'
            ? {
                onRowClick: row => {
                  setCurrentRecord(row);
                  if (selectedTab === 'DocumentTypes') {
                    subTypeDisclosure.onOpen();
                  } else {
                    dataSourcesByResourceType.onOpen();
                  }
                },
              }
            : {})}
        />
        {tableActionModals}
      </Box>
      {subTypeDisclosure.isOpen && (
        <DocumentSubTypes
          {...subTypeDisclosure}
          currentRecord={currentRecord}
        />
      )}
      {dataSourcesDisclosure.isOpen && (
        <DocumentTypeDataSources
          {...dataSourcesDisclosure}
          currentRecord={currentRecord}
          type="type"
        />
      )}
      {dataSourcesByResourceType.isOpen && (
        <DataSourcesByResourceType
          {...dataSourcesByResourceType}
          currentRecord={currentRecord}
        />
      )}
    </Stack>
  );

  return isExpanded ? (
    <Drawer
      isOpen={isExpanded}
      onClose={() => setIsExpanded(false)}
      body={renderTable()}
      closeButton
      styles={{
        content: { maxW: 'full' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
    />
  ) : (
    renderTable()
  );
};
