/**
 *
 * Add Slack Client
 *
 */

import React from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiAddClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../../slice';

import { SlackForm } from './SlackForm';

export const CreateSlack = () => {
  const dispatch = useDispatch();
  const { onCancel } = useIntegrationsContext();

  const createClient = data => {
    const { token, name, channel_id, channelName } = data;
    const payload: ClientsApiAddClientRequest = {
      clientConfig: {
        client_name: Clients.Slack,
        name,
        status: true,
        slack: {
          token,
          channel_id,
          channel_name: channelName,
          workspace: '',
        },
      },
    };
    dispatch(
      actions.createClient({
        q: payload,
        onSuccess: res => {
          onCancel();
          //setCurrentRecord(res);
          //dispatch(actions.updateActiveClient(res));
        },
      }),
    );
  };

  return <SlackForm handleSubmit={createClient} />;
};
