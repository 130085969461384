import React, { FC, useMemo } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { VulnerabilityType } from '@ariksa/scan-analysis';
import { PackageSummary } from '@ariksa/scan-analysis/api';
import {
  Accordion,
  Box,
  Grid,
  GridItem,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { StackedCell, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { ExclamationTriangleIcon, IconType } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { VulnerabilityTabFilters } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/Vulnerability/VulnerabilityTabFilters';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

import { VulnerabilitySummaryCard } from '../../../ResourceMetadata/Vulnerability/VulnerabilitySummaryCard';

export interface Props {}

export const EC2Vulnerability: FC<Props> = () => {
  const { vulnerabilitySummary, resourceId, resourceType } = useSelector(
    selectActiveResource,
  );
  const { data, isLoading } = vulnerabilitySummary;
  const isContainer = useMemo(() => {
    return [
      NativeResources.Container,
      NativeResources.GcpContainer,
      NativeResources.AzureContainer,
    ].includes(resourceType as any);
  }, [resourceType]);

  if (isLoading)
    return (
      <Box w="full" h="full">
        <CustomSpinner loading={isLoading} />
      </Box>
    );

  const renderIsLatest = ({ value }) =>
    value ? (
      'Yes'
    ) : value === null ? (
      '-'
    ) : (
      <HStack spacing={1}>
        <Box>Not latest</Box>
        <Box boxSize={6} color="critical" pb={1.5}>
          <ExclamationTriangleIcon />
        </Box>
      </HStack>
    );

  const columns = [
    {
      header: <Box pl={12}>OS</Box>,
      render: ({ row }) => (
        <WithResourceIcon resourceType={row?.name} iconFilled={false}>
          <StackedCell upper={row?.name} lower={row?.version} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'name',
      align: 'left',
      render: ({ row, value }) => (
        <StackedCell upper={row?.platform} lower={row?.platform_like} />
      ),
    },
    {
      header: 'Latest Version',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={row?.latest_version}
          lower={'-'}
          showLowerTooltip={false}
        />
      ),
    },
    {
      header: 'Is Latest',
      align: 'left',
      accessor: 'is_latest',
      render: renderIsLatest,
    },
    {
      header: 'Is EOL',
      align: 'left',
      assessor: 'is_current_eol',
      render: ({ value }) => (value ? 'Yes' : value === null ? '-' : 'No'),
    },
    {
      header: 'Total Findings',
      accessor: 'os_vulnerability.total',
    },
  ];

  const payload = {
    instanceId: isContainer ? undefined : resourceId,
    containerId: isContainer ? resourceId : undefined,
  };

  return (
    <Stack spacing={0}>
      <VulnerabilityTabFilters />
      <Accordion allowMultiple defaultIndex={[0]} my={0}>
        {resourceType !== NativeResources.LambdaFunction && (
          <MetaAccordionItem
            iconType={data.name as IconType}
            label={
              <Grid templateColumns="repeat(7, 1fr)" w="full" gap={3} flex={1}>
                <GridItem colSpan={3}>
                  <HStack>
                    <Box>OS:</Box>
                    <Box>
                      {data.name} {data.version}
                    </Box>
                  </HStack>
                </GridItem>
              </Grid>
            }
            onClickDownload={() => {}}
            panel={<Table columns={columns} data={[data]} />}
            iconFilled={false}
            height="150px"
          />
        )}
      </Accordion>
      <Stack spacing={4}>
        {!!data?.os_vulnerability?.total &&
          resourceType !== NativeResources.LambdaFunction && (
            <VulnerabilitySummaryCard
              type={VulnerabilityType.Os}
              summary={data?.os_vulnerability as PackageSummary}
              {...payload}
            />
          )}
        {resourceType !== NativeResources.LambdaFunction && (
          <VulnerabilitySummaryCard
            type={VulnerabilityType.Application}
            summary={data?.applications as PackageSummary}
            {...payload}
          />
        )}
        <VulnerabilitySummaryCard
          type={VulnerabilityType.Library}
          summary={data?.library_vulnerability as PackageSummary}
          {...payload}
        />
      </Stack>
    </Stack>
  );
};
