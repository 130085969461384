import React from 'react';

import { formatTooltip } from 'components/DataDisplay';
import { CloudIcon } from 'components/Icons';
import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { CloudUsers } from 'containers/Dashboard/IdentityOverview/Components/CloudUsers';
import { FederatedUsers } from 'containers/Dashboard/IdentityOverview/Components/FederatedUsers';
import { FindingsToPrioritize } from 'containers/Dashboard/IdentityOverview/Components/FindingsToPrioritize';
import { IdentitiesHistory } from 'containers/Dashboard/IdentityOverview/Components/IdentitiesHistory';
import { IdentitiesWithAccessToSensitiveData } from 'containers/Dashboard/IdentityOverview/Components/IdentitiesWithAccessToSensitiveData';
import { IdentitiesWithExcessPermissions } from 'containers/Dashboard/IdentityOverview/Components/IdentitiesWithExcessPermissions';
import { IdentitiesWithHighPrivileges } from 'containers/Dashboard/IdentityOverview/Components/IdentitiesWithHighPrivileges';
import { IdentityRoles } from 'containers/Dashboard/IdentityOverview/Components/IdentityRoles';
import { InsightsForNonHumanIdentities } from 'containers/Dashboard/IdentityOverview/Components/InsightsForNonHumanIdentities';
import { JITAccessRequests } from 'containers/Dashboard/IdentityOverview/Components/JITAccessRequests';
import { NonUsers } from 'containers/Dashboard/IdentityOverview/Components/NonUsers';
import { SaaSUsers } from 'containers/Dashboard/IdentityOverview/Components/SaaSUsers';
import { Identities } from 'containers/Dashboard/Overview/Components/Identities';

export const overviewDashboardWidgets: Record<string, WidgetProps> = {
  identities: {
    flex: true,
    components: {
      content: (
        <Identities
          showLogEnabled={false}
          icon={<CloudIcon />}
          label="All Identities"
          labelTooltip={formatTooltip({
            header: 'All Identities',
            content: 'Cloud users, federated users & machine identities',
            width: 48,
          })}
        />
      ),
    },
  },
  cloudUsers: {
    flex: true,
    components: {
      content: <CloudUsers />,
    },
  },
  nonUsers: {
    flex: true,
    components: {
      content: <NonUsers />,
    },
  },
  identityRoles: {
    flex: true,
    components: {
      content: <IdentityRoles />,
    },
  },
  federatedUsers: {
    flex: true,
    components: {
      content: <FederatedUsers />,
    },
  },
  saasUsers: {
    flex: true,
    components: {
      content: <SaaSUsers />,
    },
  },
  findingsToPrioritize: {
    flex: true,
    components: {
      content: <FindingsToPrioritize />,
    },
  },
  identitiesHistory: {
    flex: true,
    components: {
      content: <IdentitiesHistory />,
    },
  },
  insightsForNonHumanIdentities: {
    flex: true,
    components: {
      content: <InsightsForNonHumanIdentities />,
    },
  },
  identitiesWithHighPrivileges: {
    flex: true,
    components: {
      content: <IdentitiesWithHighPrivileges />,
    },
  },
  identitiesWithAccessToSensitiveData: {
    flex: true,
    components: {
      content: <IdentitiesWithAccessToSensitiveData />,
    },
  },
  identitiesWithExcessPermissions: {
    flex: true,
    components: {
      content: <IdentitiesWithExcessPermissions />,
    },
  },
  jitAccessRequests: {
    flex: true,
    components: {
      content: <JITAccessRequests />,
    },
  },
};
