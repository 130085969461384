import React, { FC, useCallback, useEffect, useMemo, useReducer } from 'react';

import { Clients, NotificationFor } from '@ariksa/notification';
import { Box, Stack } from '@chakra-ui/react';
import { each, forEach, isEmpty, map, values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { DetailsIdentifier, StackedHeader } from 'components/DataDisplay';
import { Field, Form, Select } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { errorToast, successToast } from 'components/Toast';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { EnvironmentName } from 'containers/App/utils';
import { ticketingServices } from 'containers/Findings/Alerts/Components/utils';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';
import { actions } from 'containers/Findings/Alerts/slice';
import { NotificationResource } from 'containers/Findings/Alerts/types';

interface ICreateTicket {
  alert: NotificationResource;
  notification_for: NotificationFor;
  onClose();
  isOpen: boolean;
  onCreate?(data: any, alert);
}

export const CreateTicket: FC<ICreateTicket> = props => {
  const { alert, notification_for, isOpen, onClose, onCreate } = props;
  const { environmentId } = useSelector(selectActiveEnvironment);
  const dispatch = useDispatch();
  const { alertAction, ticketInfo, ticketFields, clients } = useSelector(
    selectAlerts,
  );

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      clientType: {},
      clientOptions: [],
      projectOptions: [],
      selectedProject: {},
      ticketFieldValues: {},
      ticketFieldFinalValues: {},
      projectType: 'array',
    },
  );

  useEffect(() => {
    dispatch(actions.getClients({ q: {} }));
  }, [dispatch]);

  //set ticketing services (client) options and select first one as the default value
  useEffect(() => {
    const options = ticketingServices.filter(t =>
      clients.data?.find(c => c.client_name === t.value),
    );

    updateState({
      clientOptions: options,
      clientType: options[0],
    });
  }, [clients.data]);

  //set project options
  useEffect(() => {
    const options = clients.data
      ?.filter(c => c.client_name === state.clientType?.value)
      ?.map(c => ({
        label: c.name,
        value: c.uuid,
        data: c,
      }));
    updateState({
      projectOptions: options,
      selectedProject: options?.[0],
    });
  }, [clients.data, state.clientType]);

  //get mandatory fields and ticket information
  useEffect(() => {
    if (state.clientType?.value && state.selectedProject?.value) {
      dispatch(
        actions.getTicketInfo({
          q: {
            clientName: state.clientType?.value,
            name: state.selectedProject?.label,
            notificationFor: notification_for,
            resourceId: alert.entity_id,
            entityUuid: alert.entity_uuid,
            accountId: alert.account_id,
            resourceType: alert.entity_type,
            alertUuid: alert.uuid,
            alertRuleId: alert.alert_rule_id,
          },
        }),
      );
      if (state.clientType?.value === 'jira')
        dispatch(
          actions.getTicketFields({
            q: {
              clientId: state.selectedProject?.value,
            },
            onSuccess: req => {
              let options: Record<string, any> = {};
              forEach(req, (o, key) => {
                options[key] = o?.option ? {} : [];
              });
              updateState({
                ticketFieldValues: options,
                ticketFieldFinalValues: options,
              });
            },
          }),
        );
    }
  }, [
    alert,
    state.selectedProject,
    state.clientType,
    dispatch,
    notification_for,
  ]);

  //reset everything on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.resetTicketDetails());
    };
  }, [dispatch]);

  //if not valid, disable create button
  const isValid = useMemo(() => {
    if (
      state.clientType?.value === 'jira' &&
      ((isEmpty(state.ticketFieldValues) && !isEmpty(ticketFields.data)) ||
        values(state.ticketFieldValues).some(v => isEmpty(v) || v === null))
    )
      return false;

    return !!state.clientType?.value && !!state.selectedProject?.label;
  }, [
    state.selectedProject,
    state.clientType,
    state.ticketFieldValues,
    ticketFields.data,
  ]);

  const handleCreateTicket = useCallback(
    data => {
      let ticket_fields: Dict<any> = {};
      each(state?.ticketFieldFinalValues, (values, name) => {
        ticket_fields = {
          ...ticket_fields,
          [name]: values,
        };
      });

      dispatch(
        actions.createTicket({
          q: {
            notifyClients: {
              client_name: state.clientType?.value as Clients,
              name: state.selectedProject?.label,
              alert_uuid: alert.uuid,
              entity_uuid: alert.entity_uuid,
              resource_id: alert.entity_id,
              account_id: alert.account_id,
              resource_type: alert.entity_type,
              alert_rule_id: alert.alert_rule_id,
              notification_for,
              ticket_fields,
            },
          },
          onSuccess: () => {
            successToast({
              title:
                'Successfully created ticket for ' +
                state.selectedProject?.label,
            });
            onClose();
            onCreate?.(
              {
                ...data,
                clientType: state.clientType?.value,
                channelName: state.selectedProject?.label,
              },
              alert,
            );
          },
          onError: error => {
            errorToast({
              title:
                'Failed to create ticket for ' + state.selectedProject?.label,
              description: error?.description,
            });
            onClose();
          },
        }),
      );
    },
    [
      alert,
      state.selectedProject?.label,
      state.clientType,
      dispatch,
      notification_for,
      onClose,
      onCreate,
      state?.ticketFieldFinalValues,
    ],
  );

  const fieldObject = useCallback(
    (field, name) => {
      const options = field.allowed_values?.map(v => ({
        label: v.value ?? v?.name,
        value: v.id,
        data: { id: v.id, value: v.name || v?.value },
        type: field.type,
      }));
      switch (field.type) {
        case 'option':
          return {
            [name]: {
              type: 'react-select',
              label: field.name,
              isRequired: true,
              options,
              value: state?.ticketFieldValues?.[name] || {},
              onChange: op => {
                updateState({
                  ticketFieldValues: {
                    ...state.ticketFieldValues,
                    [name]: op,
                  },
                  ticketFieldFinalValues: {
                    ...state.ticketFieldFinalValues,
                    [name]: op?.data,
                  },
                });
              },
            },
          };
        case 'array':
          return {
            [name]: {
              type: 'react-select',
              label: field.name,
              isRequired: true,
              options,
              isMulti: true,
              value: state?.ticketFieldValues?.[name] || [],
              onChange: op => {
                updateState({
                  ticketFieldValues: {
                    ...state.ticketFieldValues,
                    [name]: op,
                  },
                  ticketFieldFinalValues: {
                    ...state.ticketFieldFinalValues,
                    [name]: map(op, o => o?.data),
                  },
                });
              },
            },
          };
        default:
          return {};
      }
    },
    [state.ticketFieldValues],
  );

  const customFields = useMemo(() => {
    let fields: Record<string, Field> = {};

    each(ticketFields.data, (field, name) => {
      fields = { ...fields, ...fieldObject(field, name) };
    });

    return fields;
  }, [fieldObject, ticketFields.data]);

  return (
    <Box>
      <Modal
        styles={{ modal: { size: '2xl' } }}
        isOpen={isOpen}
        onClose={onClose}
        header={
          <StackedHeader
            upper={'Create Ticket'}
            lower={
              (alert.entity_name ?? '') +
              (alert?.entity_id ? ' (' + alert.entity_id + ')' : '')
            }
            type={IconTypes.Automation}
          />
        }
        body={
          <Stack spacing={6}>
            <Stack spacing={6}>
              {notification_for === NotificationFor.Alerts && (
                <DetailsIdentifier
                  label="Policy blueprint"
                  value={alert.policy_name}
                />
              )}
              <DetailsIdentifier
                label="Environment"
                value={<EnvironmentName environmentId={environmentId} />}
              />
              <DetailsIdentifier
                label="Select client"
                align="center"
                value={
                  <Box w="200px">
                    <Select
                      options={state.clientOptions}
                      value={state.clientType}
                      onChange={opt =>
                        updateState({ clientType: opt, selectedProject: {} })
                      }
                      isLoading={clients.isLoading}
                    />
                  </Box>
                }
              />
              <DetailsIdentifier
                label="Select project"
                align="center"
                value={
                  <Box w="200px">
                    <Select
                      isDisabled={clients.isLoading}
                      options={state.projectOptions}
                      isLoading={clients?.isLoading}
                      value={state.selectedProject}
                      onChange={opt => updateState({ selectedProject: opt })}
                    />
                  </Box>
                }
              />
            </Stack>
            <Form
              isLoading={ticketFields.isLoading || ticketInfo.isLoading}
              schema={{
                summary: {
                  type: 'text',
                  label: 'Summary',
                  placeholder: 'Alert Summary',
                  value: ticketInfo.data.summary ?? '',
                  isDisabled: true,
                },
                description: {
                  type: 'textArea',
                  label: 'Description',
                  value: ticketInfo.data.description ?? '',
                  isDisabled: true,
                  styles: {
                    textArea: {
                      h: '150px',
                      fontSize: 14,
                    },
                  },
                },
                ...(state.clientType?.value === 'jira' ? customFields : {}),
              }}
              buttonOptions={{
                back: {
                  isVisible: true,
                  name: 'Cancel',
                  onClick: onClose,
                },
                submit: {
                  name: 'Create',
                  isDisabled: !isValid,
                  isLoading: alertAction.isLoading,
                },
              }}
              handleSubmit={handleCreateTicket}
            />
          </Stack>
        }
      />
    </Box>
  );
};
