import { RefObject, useEffect, useState } from 'react';
import '@pixi/unsafe-eval';

import { Application, Graphics } from 'pixi.js';
import '../style.css';

interface UseAppProps {
  ref: RefObject<any>;
}

const disposePixiApp = (app: Application) => {
  /**
   * Remove stage correctly
   * reference: https://github.com/pixijs/pixijs/issues/2233#issuecomment-192227185
   */
  if ('gl' in app.renderer) {
    (app.renderer as any).gl.getExtension('WEBGL_lose_context')?.loseContext();
  }
};

export const usePixiApp = (props: UseAppProps) => {
  const { ref } = props;
  const [app] = useState<Application>(() => {
    console.log('new app created');
    const app = new Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      backgroundColor: 0xf5f5f5,
      width: 1000,
      height: 600,
    });

    const g = new Graphics();
    g.beginFill(0x000000, 1);
    g.drawCircle(100, 100, 100);
    g.endFill();
    app.stage.addChild(g);

    return app;
  });

  useEffect(() => {
    if (!app) return;
    ref.current?.appendChild(app.view);
    const { offsetHeight: height, offsetWidth: width } = ref.current;
    app.view.height = height;
    app.view.width = width;
    app.renderer.resize(width, height);
  }, [app, ref]);

  return app;
};
