import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Stack, useDisclosure } from '@chakra-ui/react';
import { findIndex } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButton } from 'components/DataEntry';
import { AIInsightIcon } from 'components/Icons';
import { TabItemProps, Tabs } from 'components/Navigation';
import { MetadataDrawerSummary } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataDrawerSummary';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { SummarizeResource } from 'containers/SharedState/Components/SummarizeResource';

export const ResourceMetadata: FC = () => {
  const { tabIndex } = useActiveResourceContext();
  const {
    resourceType,
    activeTabIndex,
    activeTabName,
    resourceInsight,
    resourceId,
    resourceUuid,
    resourceAccountId,
  } = useSelector(selectActiveResource);
  const summarizeResource = useDisclosure();
  const dispatch = useDispatch();
  const {
    getResourceDetails,
    getResourceInsight,
    getVulnerabilitySummary,
    getEntitySeverity,
    getAccessMapData,
    getGcpResourceDetails,
    getResourceRiskContext,
    getAMIHosts,
  } = useActiveResourceActions();

  // load resource data only when the drawer is opened
  useEffect(() => {
    const type = resourceType?.toLowerCase();
    if (
      type?.startsWith('gcp') ||
      type?.startsWith('azure') ||
      resourceType === NativeResources.Aks
    ) {
      // getResourceInsight();
      getGcpResourceDetails();
    } else {
      getResourceDetails();
      getResourceInsight();
    }
  }, [
    getResourceDetails,
    getResourceInsight,
    getGcpResourceDetails,
    resourceType,
  ]);

  useEffect(() => {
    getResourceRiskContext();
    getEntitySeverity();
  }, [getResourceRiskContext, getEntitySeverity]);

  useEffect(() => {
    getVulnerabilitySummary();
  }, [getVulnerabilitySummary]);

  useEffect(() => {
    if (resourceType === NativeResources.AmazonMachineImage) getAMIHosts();
  }, [getAMIHosts, resourceType]);

  useEffect(() => {
    getAccessMapData();
  }, [getAccessMapData]);

  const { getMetadataDrawerTabs } = useMetadataDrawerTabs();

  const tabs: TabItemProps[] = getMetadataDrawerTabs(resourceType);

  useEffect(() => {
    // if tab name is present set the index accordingly
    if (!activeTabName) {
      dispatch(
        actions.setActiveTabName(
          tabs[activeTabIndex]?.title.toLowerCase() ?? '',
        ),
      );
    } else {
      const index = findIndex(
        tabs,
        t => t?.title.toLowerCase() === activeTabName,
      );
      if (index === -1) {
        dispatch(actions.setActiveTabIndex(0));
        dispatch(actions.setActiveTabName(tabs[0].title.toLowerCase()));
      } else {
        dispatch(actions.setActiveTabIndex(index));
      }
    }
  }, [activeTabIndex, activeTabName, dispatch, tabs]);

  return (
    <Stack w="full" h="full" spacing={5}>
      <Box>
        <PrimaryButton
          bg="white"
          color="primary"
          onClick={() => summarizeResource.onOpen()}
          iconSize={5}
          leftIcon={<AIInsightIcon />}
          py={1}
        >
          SUMMARIZE
        </PrimaryButton>
      </Box>
      <MetadataDrawerSummary />
      <Box h="full">
        <Tabs
          items={tabs}
          currentIndex={tabIndex}
          onChange={index => {
            dispatch(actions.setActiveTabIndex(index));
            dispatch(actions.setActiveTabName(tabs[index].title.toLowerCase()));
          }}
          //rightComponent={<SearchBox onChange={} />}
        />
      </Box>
      {summarizeResource.isOpen && (
        <SummarizeResource
          resource={{
            ...resourceInsight.data,
            resource_id: resourceId,
            account_id: resourceAccountId,
            uuid: resourceUuid,
            native_name: resourceType,
          }}
          useDisclosure={summarizeResource}
        />
      )}
    </Stack>
  );
};
