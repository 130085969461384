import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { formatTooltip } from 'components/DataDisplay';
import { IconTypes, VirtualMachineIcon } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { CountCards } from 'containers/Dashboard/utils/CountCards';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';

export const InsightsForNonHumanIdentities: React.FC = () => {
  const { insightsForNonHumanIdentities } = useSelector(selectIdentityOverview);
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  const styles = {
    iconColor: 'critical',
    borderColor: 'gray.200',
    isLoading: insightsForNonHumanIdentities.isLoading,
  };

  const items = [
    {
      label: 'Unrotated credentials',
      iconType: IconTypes.SyncPause,
      count: insightsForNonHumanIdentities.data?.['Unrotated credentials'] ?? 0,
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          iconType: IconTypes.SyncPause,
          panel: PanelsEnum.UnrotatedCredentials,
          total:
            insightsForNonHumanIdentities.data?.['Unrotated credentials'] ?? 0,
        });
        /*navigate(
          `/findings?tab=AllFindings&group_id=07fe60b5-ff78-4943-9a4d-5dac0e29468b`,
        );*/
      },
      tooltip: formatTooltip({
        header: 'Unrotated credentials',
        content:
          'Credentials that are not being periodically rotated (manually or automatically)',
        width: 48,
      }),
      ...styles,
    },
    {
      label: 'Cloud access keys',
      iconType: IconTypes.Key,
      count: insightsForNonHumanIdentities.data?.['Cloud access keys'] ?? 0,
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          iconType: IconTypes.Key,
          panel: PanelsEnum.CloudAccessKeys,
          total: insightsForNonHumanIdentities.data?.['Cloud access keys'] ?? 0,
        });
        /*navigate(
          `/inventory/summary?category=${Categories.Credential}&resource_type=${NativeResources.IamUserAccessKey}`,
        );*/
      },
      tooltip: formatTooltip({
        header: 'Cloud access keys',
        content:
          'IAM keys and other user keys used to access public clouds or SaaS',
        width: 48,
      }),
      ...styles,
    },
    {
      label: 'Expired certificates',
      iconType: IconTypes.Certificate,
      count: insightsForNonHumanIdentities.data?.['Expired certificates'] ?? 0,
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          iconType: IconTypes.Certificate,
          panel: PanelsEnum.ExpiredCertificates,
          total:
            insightsForNonHumanIdentities.data?.['Expired certificates'] ?? 0,
        });
        /*navigate(
          `/visibility/security-graph?query=${SearchQueryMapping.Show_expired_certificates}`,
        );*/
      },
      tooltip: formatTooltip({
        header: 'Expired certificates',
        content: 'SSL/TLS certificates that have expired',
        width: 32,
      }),
      ...styles,
    },
    {
      label: 'Exposed credentials',
      iconType: IconTypes.Credential,
      count: insightsForNonHumanIdentities.data?.['Exposed credentials'] ?? 0,
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          iconType: IconTypes.Credential,
          panel: PanelsEnum.ExposedCredentials,
          total:
            insightsForNonHumanIdentities.data?.['Exposed credentials'] ?? 0,
        });
        /*navigate(
          `/visibility/security-graph?query=${getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_internet_accessible,
            [Categories.Credential],
          )}`,
        );*/
      },
      tooltip: formatTooltip({
        header: 'Exposed credentials',
        content: 'Unauthorized presence of credentials outside key stores',
        width: 48,
      }),
      ...styles,
    },
  ];

  return (
    <>
      <DashboardWidget
        label="Insights for Non-Human Identities"
        content={renderResourcesWithIconAndCount(items)}
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
