import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Stack } from '@chakra-ui/react';
import startsWith from 'lodash/startsWith';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { AddWhitelist } from 'containers/Inventory/CloudInventory/Components/AddWhitelist';
import { InventoryTableTitle } from 'containers/Inventory/CloudInventory/Components/InventoryTableTitle';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { useInventory } from 'containers/Inventory/CloudInventory/hooks/useInventory';
import { SummarizeResource } from 'containers/SharedState/Components/SummarizeResource';

import { selectInventory } from '../../selector';
import { actions } from '../../slice';
import { AddContext } from '../AddContext';

import { useInstanceTableColumns } from './useInstanceTableColumns';

export const InstanceInventory: FC = () => {
  const dispatch = useDispatch();
  const { instanceInventory, instanceResourceType } = useSelector(
    selectInventory,
  );
  const { getCloudNativeName } = useResourceType();
  const { resourceUuid, onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const {
    tableColumns,
    openWhitelist,
    summarizeResource,
  } = useInstanceTableColumns();
  const {
    isOpenAddContext,
    setIsOpenAddContext,
    currentInstance,
  } = useInventoryContext();

  const { getResourceData, getVpcs } = useInventory();

  useEffect(() => {
    getResourceData();
  }, [getResourceData]);

  useEffect(() => {
    getVpcs();
  }, [getVpcs]);

  const onClose = (isSubmit?: boolean) => {
    setIsOpenAddContext(false);
    isSubmit && getResourceData();
  };

  return (
    <Box h="full">
      <Stack spacing={4} h="full" w="full">
        <InventoryTableTitle
          title={getCloudNativeName(instanceResourceType)}
          iconType={instanceResourceType as NativeResources}
          value={
            <Box>
              {instanceInventory.isLoading
                ? 0
                : instanceInventory.page.totalCount}
              {instanceInventory.page.totalCount > 1 ? ' Entities' : ' Entity'}
            </Box>
          }
        />
        <Table
          columns={tableColumns}
          data={instanceInventory.data}
          pagination={{
            totalCount: instanceInventory?.page.totalCount,
            pageInfo: instanceInventory.page.info,
            onChange: pageInfo => {
              dispatch(actions.updatePageInfo(pageInfo));
            },
          }}
          styles={{ header: { zIndex: 800 } }}
          isLoading={instanceInventory?.isLoading}
          isRowActive={r => r.entity_uuid === resourceUuid}
          cursor={r =>
            r?.native_name === NativeResources.SnowflakePrivileges ||
            r?.native_name === NativeResources.GitHubUser ||
            r?.native_name === NativeResources.GitLabUser ||
            r?.native_name === NativeResources.GitHubGroup ||
            r?.native_name === NativeResources.GitLabGroup
              ? 'default'
              : startsWith(r?.native_name, 'Okta') ||
                startsWith(r?.native_name, 'Azure')
              ? 'inherit'
              : 'pointer'
          }
          onRowClick={r => {
            if (
              r?.native_name !== NativeResources.SnowflakePrivileges &&
              r?.native_name !== NativeResources.GitHubUser &&
              r?.native_name !== NativeResources.GitLabUser &&
              r?.native_name !== NativeResources.GitHubGroup &&
              r?.native_name !== NativeResources.GitLabGroup
            ) {
              updateActiveResource({
                resourceType: r?.native_name as NativeResources,
                uuid: r.uuid,
                accountId: r.account,
                resourceId: r.resource_id,
                cloudProvider: r?.provider,
              });
              onOpenMetadata();
            }
          }}
        />
      </Stack>
      {summarizeResource.isOpen && (
        <SummarizeResource
          resource={{
            ...currentInstance,
            resource_id: currentInstance?.resource_id,
            account_id: currentInstance?.account,
            uuid: currentInstance?.uuid,
            native_name: currentInstance?.native_name,
          }}
          useDisclosure={summarizeResource}
        />
      )}
      {isOpenAddContext && (
        <AddContext isOpen={isOpenAddContext} onClose={onClose} />
      )}
      {openWhitelist.isOpen && (
        <AddWhitelist
          isOpen={openWhitelist.isOpen}
          onClose={openWhitelist.onClose}
        />
      )}
    </Box>
  );
};
