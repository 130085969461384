import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

import { overviewDashboardWidgets } from './widget';

export const overviewDashboardConfig: IDashboardLayout = {
  id: 'overview_dashboard',
  title: 'Overview Dashboard',
  widgets: [
    {
      type: 'card',
      i: 'summary',
      x: 0,
      y: 0,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'entities',
      x: 0,
      y: 3.8,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'computeEntities',
      x: 0,
      y: 7.6,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataSourcesSummaryCard',
      x: 0,
      y: 11.4,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'identities',
      x: 0,
      y: 15.2,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'infrastructure',
      x: 0,
      y: 19,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'findingsToPrioritizeAcrossAllCategories',
      x: 2.4,
      y: 0,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'criticalAndHighSeverityFindingsByCategory',
      x: 5.6,
      y: 0,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'runtimeInsights',
      x: 8.8,
      y: 0,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'cloudCompliance',
      x: 2.4,
      y: 7.6,
      w: 4.8,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'governanceScore',
      x: 7.2,
      y: 7.6,
      w: 4.8,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'policyManagement',
      x: 2.4,
      y: 15.2,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'openAlertsAndResolutions',
      x: 5.6,
      y: 15.2,
      w: 6.4,
      h: 7.6,
      isResizable: false,
      static: true,
    },
  ],
  available: overviewDashboardWidgets,
};
