import React from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { TopFindings } from 'containers/Dashboard/utils/TopFindings';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';

export const FindingsToPrioritize: React.FC = () => {
  const { identityAuthorizationFindingsCategorySummary } = useSelector(
    selectIdentityOverview,
  );

  return (
    <TopFindings
      categoryClass={AlertCategoryClass.IdentityAuthorization}
      accounts={identityAuthorizationFindingsCategorySummary.data}
      tooltip={{
        tooltip: (
          <Stack>
            <Box>
              Ariksa delivers prioritized findings based on multi-factor
              analyses to reduce noise and increase your productivity.
            </Box>
            <Box>
              Findings are unique issues across individual resources. Each issue
              is an alert. Ariksa helps you uncover patterns of alerts so that
              you can address them holistically. You can investigate individual
              alerts as well
            </Box>
          </Stack>
        ),
        header: 'Review findings and enforce policies…',
        footerText: 'Ok',
      }}
    />
  );
};
