import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { map, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getIconColor } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';

export const VulnerablePubliclyExposedEntities: React.FC = () => {
  const { publiclyAccessibleSources } = useSelector(selectCloudPostureOverview);
  const navigate = useNavigate();
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  return (
    <>
      <DashboardWidget
        //isLoading={publiclyAccessibleSources.isLoading}
        label="Vulnerable Publicly Exposed Entities"
        content={renderResourcesWithIconAndCount(
          map(
            orderBy(
              [] as Record<string, any>[],
              ['resource_count'],
              ['desc'],
            )?.slice(0, 4),
            o => ({
              label: o.agnostic_name!,
              iconType: o.agnostic_class!,
              count: formatNumber(o?.resource_count),
              iconColor: getIconColor(o.agnostic_class!),
              borderColor: getIconColor(o.agnostic_class!),
              onClick: () => {
                allEntitiesDisclosure.onOpen();
                setDetails({
                  panel: PanelsEnum.PubliclyAccessibleEntities,
                  iconType: o.agnostic_class!,
                  resourceType: o.agnostic_class,
                  resourceTypeClass: 'agnostic',
                  total: o?.resource_count ?? 0,
                });
                /*navigate(
              `/visibility/security-graph?query=${getSearchQuery(
                SearchQueryMapping.Show_resource_type_with_internet_accessible,
                [o.agnostic_class!],
              )}`,
            );*/
              },
            }),
          ),
        )}
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
