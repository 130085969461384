import React, { useEffect, useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import {
  Box,
  ListItem,
  Stack,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { each } from 'lodash';
import { useSelector } from 'react-redux';

import { formatTooltip } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { CustomerJourneyBulbIcon, IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import {
  DashboardWidgetTooltipIcon,
  renderResourcesWithIconAndCount,
} from 'containers/Dashboard/utils/utils';

export const IdentitiesWithAccessToSensitiveData: React.FC = () => {
  const { identitiesWithAccessToSensitiveData } = useSelector(
    selectIdentityOverview,
  );
  const [data, setData] = useState<Record<string, any>>({});
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  useEffect(() => {
    let items = {};
    each(
      identitiesWithAccessToSensitiveData.data?.items,
      o => (items[o.provider_or_source_type + ' ' + o.agnostic] = o.count),
    );
    setData(items);
  }, [identitiesWithAccessToSensitiveData.data]);

  const categories = [
    {
      label: 'Users',
      iconType: IconTypes.IamUser,
      count: formatNumber(data?.['Cloud User'] || 0),
      isLoading: identitiesWithAccessToSensitiveData.isLoading,
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.SensitiveDataUsers,
          iconType: IconTypes.IamUser,
          total: data?.['Cloud User'] || 0,
        });
        /*navigate(
          `/visibility/security-graph?query=${getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_access_to_sensitive_data,
            ['User'],
          )}`,
        );*/
      },
      iconTooltip: formatTooltip({
        header: 'Users',
        content: 'Cloud-native and federated users',
        width: 32,
      }),
    },
    {
      label: 'Roles',
      iconType: IconTypes.Role,
      count: formatNumber(data?.['Cloud Role'] || 0),
      isLoading: identitiesWithAccessToSensitiveData.isLoading,
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.SensitiveDataRoles,
          iconType: IconTypes.IamUser,
          total: data?.['Cloud Role'] || 0,
        });
        /*navigate(
          `/visibility/security-graph?query=${getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_access_to_sensitive_data,
            ['Role'],
          )}`,
        )*/
      },
      iconTooltip: formatTooltip({
        header: 'Roles',
        content: 'Cloud-native roles for public clouds, SaaS',
        width: 32,
      }),
    },
    {
      label: 'Cross-Account',
      iconType: IconTypes.CrossAccount,
      count: formatNumber(data?.['Cross Account Role'] || 0),
      isLoading: identitiesWithAccessToSensitiveData.isLoading,
      iconTooltip: formatTooltip({
        header: 'Cross account',
        content: 'Other accounts that have been onboarded',
        width: 32,
      }),
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.SensitiveDataCrossAccount,
          iconType: IconTypes.CrossAccount,
          total: data?.['Cross Account Role'] || 0,
        });
      },
    },
    {
      label: 'Third-party',
      iconType: IconTypes.ThirdParty,
      count: formatNumber(data?.['Third Party Account'] || 0),
      isLoading: identitiesWithAccessToSensitiveData.isLoading,
      iconTooltip: formatTooltip({
        header: 'Third-party',
        content: 'Other accounts that have not been onboarded',
        width: 32,
      }),
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.SensitiveDataThirdParty,
          iconType: IconTypes.ThirdParty,
          total: data?.['Third Party Account'] || 0,
        });
      },
    },
  ];

  return (
    <>
      <DashboardWidget
        label="Identities with Sensitive Data Access"
        content={renderResourcesWithIconAndCount(categories)}
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
        tooltip={{
          tooltip: (
            <Stack spacing={3}>
              <Box>
                Ariksa automatically assess identity risks to sensitive data by
                evaluating permissions, policies and paths to data.
              </Box>
              <Box>To right-size identity access:</Box>
              <UnorderedList color="primary" spacing={2}>
                <ListItem pl={2}>Deploy Outpost for Data</ListItem>
                <ListItem pl={2}>Retain user activity logs</ListItem>
                <ListItem pl={2}>Remediate using Ariksa</ListItem>
              </UnorderedList>
            </Stack>
          ),
          header: 'Identities with Sensitive Data Access',
          showArrow: false,
          icon: <CustomerJourneyBulbIcon />,
          button: <DashboardWidgetTooltipIcon />,
        }}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
