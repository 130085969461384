import React, { useState } from 'react';

import { Box, Flex, Square } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { PrimaryIconButton } from 'components/DataEntry';
import { LeftArrowIcon, RightArrowIcon } from 'components/Icons';
import { AIBrainIcon } from 'components/Icons/ReactCustomIcons/AIBrainIcon';

export const AiBrainButton = () => {
  const [mouseIn, setMouseIn] = useState(false);
  const [side, toggleSide] = useToggle(false);
  const navigate = useNavigate();

  return (
    <Flex
      pos={'fixed'}
      zIndex={1000}
      bottom={6}
      left={side ? 'auto' : 4}
      right={side ? 4 : 'auto'}
      gap={0}
      onMouseMove={() => setMouseIn(true)}
      onMouseOut={() => {
        setMouseIn(false);
      }}
    >
      {side && (
        <Square
          cursor={'pointer'}
          opacity={mouseIn ? 1 : 0}
          onClick={() => {
            setMouseIn(false);
            toggleSide();
          }}
        >
          <LeftArrowIcon />
        </Square>
      )}
      <Flex px={2}>
        <Box
          aria-label={''}
          bg={'primary'}
          color={'white'}
          boxSize={12}
          p={1.5}
          borderRadius={6}
          cursor="pointer"
          onClick={() => navigate('/home/ai-assistant')}
        >
          <AIBrainIcon />
        </Box>
      </Flex>
      {!side && (
        <Square
          cursor={'pointer'}
          opacity={mouseIn ? 1 : 0}
          onClick={() => {
            setMouseIn(false);
            toggleSide();
          }}
        >
          <RightArrowIcon />
        </Square>
      )}
    </Flex>
  );
};
