/**
 *
 * Onboard Azure Account
 *
 */

import React, { memo, useReducer } from 'react';

import { CloudProviders } from '@ariksa/cloud-account';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Form } from 'components/DataEntry/Form';
import { AzureIcon } from 'components/Icons';
import { errorToast } from 'components/Toast';
import { selectCloudAccountWizard } from 'containers/Setup/CloudAccounts/CloudAccountWizard/selectors';
import { actions } from 'containers/Setup/CloudAccounts/CloudAccountWizard/slice';

import { formStyles } from '../../styles';

interface Props {}

export const OnboardMicrosoftAzureForm = memo((props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onboardingAccount } = useSelector(selectCloudAccountWizard);
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      name: '',
      accountNameUsedError: '',
      subscriptionId: '',
      applicationId: '',
      tenantId: '',
      secret: '',
    },
  );

  const handleSubmit = data => {
    const {
      accountName,
      secret,
      tenantId,
      subscriptionId,
      applicationId,
    } = data;

    const onError = err => {
      if (err?.code === 409) {
        updateState({ accountNameUsedError: err.description ?? '-' });
      } else {
        errorToast({ title: err.title, description: err.description });
      }
    };

    dispatch(
      actions.onboardCloudAccount({
        q: {
          cloudAccountCreateRequest: {
            name: accountName,
            cloud_type: CloudProviders.Azure,
            azure: {
              client_credential: secret,
              authority: tenantId,
              subscription_id: subscriptionId,
              client_id: applicationId,
            },
          },
        },
        onSuccess: () => navigate('/setup/accounts'),
        onError,
      }),
    );
  };
  const handleReset = () => {
    navigate('/setup/accounts');
  };

  return (
    <>
      <Form
        title="Add Microsoft Azure"
        titleIcon={<AzureIcon />}
        isTitleIconFilled={false}
        schema={{
          accountName: {
            type: 'text',
            label: 'Account Name',
            placeholder: 'Enter account name',
            isRequired: true,
            error: state.accountNameUsedError,
            onChange: value => {
              updateState({
                name: value,
                accountNameUsedError: value.match(/^[a-zA-Z0-9-]*$/i)
                  ? ''
                  : 'Only Alphanumeric, hyphen is allowed!',
              });
            },
          },
          tenantId: {
            type: 'text',
            label: 'Tenant ID',
            placeholder: 'abcddf01-2945-6789-0a8c-def089345678',
            value: state.tenantId,
            isRequired: true,
            onChange: value => updateState({ tenantId: value }),
          },
          applicationId: {
            type: 'text',
            label: 'Application ID',
            placeholder: 'fsdddf01-1245-1289-128c-12308934qasw',
            value: state.applicationId,
            isRequired: true,
            onChange: value => updateState({ applicationId: value }),
          },
          secret: {
            type: 'text',
            label: 'Secret',
            placeholder: '******************',
            value: state.secret,
            htmlInputType: 'password',
            isRequired: true,
            onChange: value => updateState({ secret: value }),
          },
          subscriptionId: {
            type: 'text',
            label:
              'Subscription ID (Leave Subscription ID blank to onboard all subscriptions under this Tenant)',
            placeholder: 'seqwqw01-2345-6789-0abc-def012345sde',
            value: state.subscriptionId,
            onChange: value => updateState({ subscriptionId: value }),
          },
        }}
        buttonOptions={{
          submit: {
            name: 'Okay',
            isLoading: onboardingAccount.isLoading,
            isDisabled:
              !state.name ||
              !state.secret ||
              !state.applicationId ||
              !state.tenantId ||
              !state.name ||
              state.accountNameUsedError ||
              onboardingAccount.isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        styles={formStyles()}
        handleSubmit={handleSubmit}
      />
    </>
  );
});
