import React from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Center, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { find, map } from 'lodash';
import { useSelector } from 'react-redux';

import {
  renderCloudIcon,
  renderContextTags,
  renderTime,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ActionButton } from 'components/DataEntry';
import {
  AIInsightIcon,
  CheckmarkCircleOutlineIcon,
  TagIcon,
} from 'components/Icons';
import { ResourceTypeIconTooltip } from 'containers/App/hooks/useResourceType';
import { renderVulnerabilities } from 'containers/Inventory/CloudInventory/Components/utils';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { selectInventory } from 'containers/Inventory/CloudInventory/selector';
import {
  CloudAccountName,
  useCloudAccountId,
} from 'containers/Setup/CloudAccounts/utils';

export const useInstanceTableColumns = () => {
  const {
    instanceResourceType,
    currentCategory,
    instanceAnalysisSummary,
    instanceInventory,
  } = useSelector(selectInventory);
  const { toCloudAccountId, renderStackedAccountNameID } = useCloudAccountId();
  const { data: summary } = instanceAnalysisSummary;

  const { setCurrentInstance, setIsOpenAddContext } = useInventoryContext();

  const openWhitelist = useDisclosure();
  const summarizeResource = useDisclosure();

  const onClickActionButton = (e, row) => {
    e.stopPropagation();
    setCurrentInstance(row);
  };

  const renderActions = ({ row }) => {
    return (
      <Center>
        <ActionButton
          label={'Allow list'}
          icon={<CheckmarkCircleOutlineIcon />}
          onClick={e => {
            onClickActionButton(e, row);
            openWhitelist.onOpen();
          }}
        />
        <ActionButton
          label={'Add context'}
          icon={<TagIcon />}
          onClick={e => {
            onClickActionButton(e, row);
            setIsOpenAddContext(true);
          }}
        />
        <ActionButton
          label={'Summarize resource'}
          icon={<AIInsightIcon />}
          onClick={e => {
            onClickActionButton(e, row);
            summarizeResource.onOpen();
          }}
        />
      </Center>
    );
  };

  let columns: TableColumnProps[];

  const nameCloudId = {
    header: <Box pl="thLeftPaddingWithIcon">Name/Cloud ID</Box>,
    accessor: '',
    render: ({ row }) => (
      <WithResourceIcon
        resourceType={instanceResourceType}
        iconSize="small"
        iconTooltip={
          <ResourceTypeIconTooltip resourceType={instanceResourceType} />
        }
      >
        <StackedCell upper={row?.name} lower={row?.resource_id} />
      </WithResourceIcon>
    ),
    align: 'left',
  };
  /*const entityType = {
    header: 'Entity Type',
    accessor: 'native_name',
    render: ({ value }) => (
      <>
        {renderResourceType({
          type: value,
          resourceTypes: resourceTypes.data,
        })}
      </>
    ),
    align: 'left',
  };*/
  const deployedInVpc = {
    header: 'Cloud Account',
    align: 'left',
    render: ({ row }) => {
      const accountName = <CloudAccountName accountId={row?.account} />;
      return (
        <StackedCell
          upper={accountName}
          lower={toCloudAccountId(row?.account)}
        />
      );
    },
  };
  const regionVpc = {
    header: 'Deployed In',
    align: 'left',
    render: ({ row }) => (
      <StackedCell upper={row?.vpc ?? row?.vpc_id} lower={row?.region} />
    ),
  };
  const deployedInAccount = {
    header: 'Deployed In',
    align: 'left',
    render: ({ row }) => {
      return renderStackedAccountNameID(row?.account);
    },
  };
  const ownedBy = {
    header: 'Owner',
    accessor: 'owner',
    align: 'left',
    styles: { width: '60px', cell: { maxWidth: '60px' } },
  };

  const getTruncateLength = () => {
    switch (instanceResourceType) {
      case NativeResources.Ec2Instance:
        return 10;
      default:
        return 15;
    }
  };

  const context = {
    header: 'Context',
    align: 'left',
    render: ({ row }) => renderContextTags(row, getTruncateLength()),
  };

  const actions = {
    header: 'Action',
    accessor: '',
    render: renderActions,
    styles: { width: '60px', cell: { maxWidth: '60px' } },
  };

  const renderVulnerabilitiesLoading = label => (
    <HStack>
      <Box>{label}</Box>
      {instanceAnalysisSummary.isLoading && !instanceInventory.isLoading && (
        <CustomSpinner loading={instanceAnalysisSummary.isLoading} />
      )}
    </HStack>
  );

  const os = {
    header: renderVulnerabilitiesLoading('OS'),
    accessor: 'os_name',
    align: 'left',
    styles: { width: '100px', cell: { maxWidth: '100px' } },
    render: ({ row }) => (
      <StackedCell
        upper={summary?.[row?.uuid]?.os_name || '-'}
        lower={summary?.[row?.uuid]?.os_version || '-'}
      />
    ),
  };

  const technology = {
    header: renderVulnerabilitiesLoading('Technology'),
    accessor: 'technologies',
    styles: { width: '60px', cell: { maxWidth: '60px' } },
    render: ({ row }) =>
      summary?.[row?.uuid]?.applications?.length ? (
        <CustomTooltip
          label={
            <Stack>
              {map(summary?.[row?.uuid]?.applications, o => (
                <Box>{o}</Box>
              ))}
            </Stack>
          }
        >
          {summary?.[row?.uuid]?.applications?.length ?? 0}
        </CustomTooltip>
      ) : (
        0
      ),
  };

  const vulnerabilities = {
    header: renderVulnerabilitiesLoading('Vulnerabilities'),
    accessor: 'vulnerabilities',
    align: 'left',
    render: ({ row }) =>
      renderVulnerabilities({
        value: summary?.[row?.uuid]?.vulnerabilities,
      }),
  };

  const cloud = {
    header: 'Cloud',
    render: ({ row }) => (
      <Center>{renderCloudIcon({ provider: row?.provider })}</Center>
    ),
    //styles: { width: '150px', cell: { maxWidth: '150px' } },
  };

  const tags = {
    header: 'Tags',
    accessor: 'tags',
    //align: 'left',
    render: ({ value }) =>
      !!value?.length ? (
        <CustomTooltip
          label={
            <Stack>
              {map(value, o => (
                <HStack>
                  <Box color="orange">{o?.key}:</Box>
                  <Box>{o?.value}</Box>
                </HStack>
              ))}
            </Stack>
          }
        >
          {value?.length}
        </CustomTooltip>
      ) : (
        0
      ),
    styles: { width: '60px', cell: { maxWidth: '60px' } },
  };

  const persona = {
    header: 'Persona',
    accessor: 'user_tags',
    align: 'left',
    render: ({ value, row }) => {
      return find(value, o => o.key?.toLowerCase() === 'persona')?.value ?? '-';
    },
  };

  const account_locator = {
    header: 'Deployed in',
    render: ({ row }) => toCloudAccountId(row?.account),
    align: 'left',
  };
  const created_modified = [
    { header: 'Created', accessor: '', align: 'left' },
    {
      header: 'Modified',
      accessor: 'last_modified',
      align: 'left',
      render: renderTime,
    },
  ];

  const commonColumns = [nameCloudId, cloud];

  const ecs = [
    deployedInAccount,
    os,
    technology,
    vulnerabilities,
    context,
    tags,
    actions,
  ];

  const ecsService = [deployedInAccount, regionVpc, tags, actions];

  const virtualMachine = [
    deployedInVpc,
    os,
    technology,
    vulnerabilities,
    context,
    actions,
  ];

  const ecr = [
    deployedInAccount,
    //vulnerabilities,
    {
      header: 'Type',
      align: 'left',
      render: ({ row }) => (!!row?.public ? 'Public' : 'Private'),
    },
    {
      header: 'URL / Registry',
      render: ({ row }) => (
        <StackedCell upper={row?.repository_uri} lower={row?.registry_id} />
      ),
      align: 'left',
    },
    {
      header: 'Immutable',
      accessor: 'image_tag_mutability',
      render: ({ value }) => (value ? 'Yes' : 'No'),
      align: 'left',
    },
    {
      header: 'Images',
      render: ({ row }) => row?.container_image_count ?? 0,
      align: 'left',
    },
    context,
    actions,
  ];

  const database = [
    deployedInVpc,
    { header: 'Technology', accessor: '', align: 'left' },
    { header: 'Deployment type', accessor: '', align: 'left' },
    context,
    tags,
    actions,
  ];

  const user = [
    { header: 'Unique Name', accessor: 'arn', align: 'left' },
    deployedInAccount,
    context,
    tags,
    /*{ header: 'Last Activity', accessor: '', align: 'left' },*/
    actions,
  ];

  const other = [deployedInVpc, context, tags, actions];

  const data = [
    ...commonColumns,
    deployedInAccount,
    /*{
      header: 'PII',
      accessor: 'contexts',
      align: 'left',
      render: ({ row }) => {
        let pii = findPath(
          [...row?.user_tags, ...row?.contexts],
          o => o.key === 'pii',
        );
        if (!!pii) return pii?.value === 'false' ? 'No' : 'Yes';
        return '-';
      },
    },*/
    context,
    tags,
    actions,
  ];

  const snowflakeRole = [
    { header: 'Role Type', accessor: '', align: 'left' },
    { header: 'Privileges', accessor: '', align: 'left' },
    deployedInAccount,
    ...created_modified,
  ];
  const snowflakeUser = [
    deployedInAccount,
    ...created_modified,
    { header: 'Status', accessor: '', align: 'left' },
  ];
  const snowflakeDatabase = [deployedInAccount, context, ...created_modified];
  const snowflakeOther = [deployedInAccount, context, tags, actions];

  //switch (getResourceType(instanceResourceType)?.cloud_agnostic_class) {
  switch (instanceResourceType) {
    case NativeResources.DocumentDb:
    case NativeResources.DynamoDb:
    case NativeResources.RelationalDatabaseService:
    case NativeResources.Neptune:
      columns = database;
      break;
    case NativeResources.Ec2Instance:
    case NativeResources.LambdaFunction:
    case NativeResources.Container:
    case NativeResources.AmazonMachineImage:
    case NativeResources.GcpComputeEngine:
    case NativeResources.GcpCloudFunctions:
    case NativeResources.GcpContainer:
    case NativeResources.AzureVirtualMachine:
    case NativeResources.AzureContainer:
    case NativeResources.AzureVirtualMachineScaleSets:
    case NativeResources.AzureVirtualMachineScaleSetInstance:
    case NativeResources.AzureFunctions:
      columns = virtualMachine;
      break;
    case NativeResources.ElasticContainerService:
    case NativeResources.EcsCluster:
    case NativeResources.ElasticKubernetesService:
    case NativeResources.Gcpgke:
    case NativeResources.Aks:
      columns = ecs;
      break;
    case NativeResources.IamUser:
    case NativeResources.OktaUser:
    case NativeResources.AzureAdUser:
    case NativeResources.AccountRoot:
    case NativeResources.IamRole:
    case NativeResources.IamGroup:
    case NativeResources.OktaGroup:
    case NativeResources.AzureAdGroup:
      columns = user;
      break;
    case NativeResources.ElasticContainerRegistry:
      columns = ecr;
      break;
    case NativeResources.SnowflakeRoles:
      columns = snowflakeRole;
      break;
    case NativeResources.SnowflakeUsers:
      columns = snowflakeUser;
      break;
    case NativeResources.SnowflakeDatabases:
    case NativeResources.SnowflakeTables:
      columns = snowflakeDatabase;
      break;
    case NativeResources.SnowflakeSchemas:
    case NativeResources.SnowflakePrivileges:
      columns = snowflakeOther;
      break;
    case NativeResources.EcsService:
      //case NativeResources.EcsTask:
      columns = ecsService;
      break;
    default:
      columns = other;
  }

  let tableColumns = [...commonColumns, ...columns];
  if (currentCategory.split('_')[0] === 'Data') tableColumns = data;

  return {
    tableColumns,
    openWhitelist,
    context,
    created_modified,
    ownedBy,
    summarizeResource,
  };
};
