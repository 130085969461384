import {
  NativeResources,
  RiskContextResponse,
  SunburstDashboard,
} from '@ariksa/inventory-core/api';
import { VulnerabilitiesCount } from '@ariksa/reporting/api';
import {
  BySeverityDashboard,
  BySourcesDashboard,
  ContainerInfo,
  ScannerInfo,
  VulnerabilityPriortization,
} from '@ariksa/scan-analysis/api';
import { IPagedQueryState } from 'services/utils/PagedQueryState';
import { IQueryState } from 'services/utils/QueryState';

import { Sorted } from 'components/DataDisplay/NewTable/types';
import { SideNavKey } from 'components/Navigation';

export interface vulnerabilityState {
  selectedTab: SideNavKey;
  vulnerabilitiesBySeverity: IQueryState<any>;
  vulnerabilitiesBySources: IQueryState<BySourcesDashboard>;
  vulnerabilitiesBySeverityCounts: IQueryState<BySeverityDashboard>;
  hosts: IQueryState<any>;
  vulnerabilities: IPagedQueryState<any>;
  cveHosts: IPagedQueryState<any>;
  cves: IPagedQueryState<Record<string, any>[]>;
  containers: IQueryState<ContainerInfo[]>;
  containerVulnerability: IQueryState<any>;
  searchTerm: string;
  tablesTabIndex: number;
  sort: Sorted;
  cveHostsRiskContext: IQueryState<Record<string, RiskContextResponse[]>>;
  cveHostsVulnerabilities: Record<string, IQueryState<any>>;
  publishedAt: Record<string, any>;
  filters: Record<string, any>;
  cveDetails: IQueryState<any>;
  vulnerabilityPrioritization: IQueryState<VulnerabilityPriortization>;
  vulnerableImages: IQueryState<SunburstDashboard>;
}

export const CONTAINER_ON_VIRTUAL_MACHINE =
  NativeResources.Ec2Instance + '_Container';

export type ContainerState = vulnerabilityState;
