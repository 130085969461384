import React from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { isEmpty, map, slice } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AriksaIcon,
  getIcon,
  NoDataAvailable,
  WithResourceIcon,
} from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ApiIcon, IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { SearchQueryMapping } from 'containers/Dashboard/types';
import { getSearchQuery } from 'containers/Dashboard/utils';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { renderEmptyRows } from 'containers/Dashboard/utils/utils';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

export const WellKnownPorts: React.FC = () => {
  const { vmPortUsage } = useSelector(selectCloudPostureOverview);
  const navigate = useNavigate();

  const columns = [
    {
      header: <Box pl={9}>PORT</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={IconTypes.InternetGateway}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
          color="critical"
        >
          {row.port}
        </WithResourceIcon>
      ),
    },
    { header: 'ENTITIES', accessor: 'count' },
    {
      header: 'RISKS',
      render: ({ row }) => (
        <HStack spacing={1}>
          {map(row.contexts, c => (
            <CustomTooltip label={c}>
              <AriksaIcon
                type={c}
                color="critical"
                bg="white"
                useCustomColor
                borderColor="gray.200"
                border
                size="small"
              />
            </CustomTooltip>
          ))}
        </HStack>
      ),
    },
  ];

  return (
    <DashboardWidget
      label="Well-known Ports Permitting 0.0.0.0/0"
      isLoading={vmPortUsage.isLoading}
      noData={isEmpty(vmPortUsage.data)}
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
      content={
        <DashboardTable columns={columns} data={vmPortUsage.data} />
        /*<Stack w="full" h="full">
          {map(slice(vmPortUsage.data, 0, 3), (o, index) => (
            <Center
              h="full"
              justifyContent="left"
              w="full"
              key={index + '-key-vm-port-usage'}
            >
              <HStack w="full">
                <HStack
                  onClick={() => {
                    const url = securityGraphRedirectUrl({
                      query: getSearchQuery(
                        SearchQueryMapping.Show_vm_with_open_ports_and_protocol_tcp_udp_and_cidr,
                        [o?.port],
                      ),
                    });
                    navigate(url);
                  }}
                  cursor="pointer"
                  w="25%"
                >
                  <Center boxSize={5} color="primary">
                    <Center>
                      <ApiIcon />
                    </Center>
                  </Center>
                  <Box fontWeight={600}>Port {o?.port}</Box>
                </HStack>
                <HStack spacing={1} w="15%">
                  {map(o.cloud_providers, c => (
                    <Box>
                      <Center boxSize={5}>{getIcon(c)}</Center>
                    </Box>
                  ))}
                </HStack>

                <HStack spacing={1} w="25%">
                  <Box>{o?.count}</Box>
                  <Box>
                    virtual machine
                    {o?.count === 1 ? '' : 's'}
                  </Box>
                </HStack>
                <HStack spacing={1} w="35%">
                  {map(o.contexts, c => (
                    <CustomTooltip label={c}>
                      <AriksaIcon
                        type={c}
                        color="critical"
                        bg="white"
                        useCustomColor
                        borderColor="gray.200"
                        border
                        size="small"
                      />
                    </CustomTooltip>
                  ))}
                </HStack>
              </HStack>
            </Center>
          ))}
          {renderEmptyRows(vmPortUsage.data?.length)}
        </Stack>*/
      }
    />
  );
};
