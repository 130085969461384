import React from 'react';

import { NativeResources, Resources } from '@ariksa/inventory-core/api';

import { SNSTopicMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/App/SNS/SNSTopicMetadata';
import { ECSClusterMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Cluster/ECSCluster/ECSClusterMetadata';
import { GitlabRepositoryMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Code/GitLabRepository/GitlabRepositoryMetadata';
import { ContainerMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/Container/ContainerMetadata';
import { ECSContainerInstanceMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/ECS/ECSContainerInstance';
import { ECSServiceMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/ECS/ECSServiceMetadata';
import { AccessKeyMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Credential/AccessKey/AccessKeyMetadata';
import { KeyPairMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Credential/KeyPair/KeyPairMetadata';
import { SnowflakeDatabaseMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/Snowflake/SnowflakeDatabaseMetadata';
import { SnowflakeTableMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/Snowflake/SnowflakeTableMetadata';
import { SnowflakeRoleMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Identity/SnowflakeRole/SnowflakeRoleMetadata';
import { SnowflakeUserMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Identity/SnowflakeUser/SnowflakeUserMetadata';
import { DHCPMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Network/DHCP/DHCPMetadata';
import { PrefixListMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Network/PrefixList/PrefixListMetadata';
import { ConfigurationMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Organization/ConfigurationMetadata';
import { GcpMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Summary/GCP/GcpMetadata';

import { APIGatewayRestAPIMetadata } from '../../App/APIGateway/APIGatewayRestAPIMetadata';
import { ElastiCacheMetadata } from '../../App/ElastiCache/ElastiCacheMetadata';
import { ElasticMapReduceMetadata } from '../../App/ElasticMapReduce/ElasticMapReduceMetadata';
import { ElasticSearchDomain } from '../../App/ElasticSearch/ElasticSearchDomain';
import { SagemakerMetadata } from '../../App/Sagemaker/SagemakerMetadata';
import { SQSMetadata } from '../../App/SQS/SQSMetadata';
import { WafClassicMetadata } from '../../App/WAF/WafClassicMetadata';
import { WafV2Metadata } from '../../App/WAF/WafV2Metadata';
import { KubernetesMetadata } from '../../Cluster/EKS/KubernetesMetadata';
import { NeptuneClusterMetadata } from '../../Cluster/NeptuneCluster/NeptuneClusterMetadata';
import { RDSClusterMetadata } from '../../Cluster/RdsCluster/RDSClusterMetadata';
import { RedshiftClusterMetadata } from '../../Cluster/RedshiftCluster/RedshiftClusterMetadata';
import { AutoScalingGroupMetadata } from '../../Compute/AutoScalingGroup/AutoScalingGroupMetadata';
import { ECSMetadata } from '../../Compute/ECS/ECSMetadata';
import { LambdaMetadata } from '../../Compute/Lambda/LambdaMetadata';
import { VMMetadata } from '../../Compute/VirtualMachine/VMMetadata';
import { AcmCertificateMetadata } from '../../Credential/Certificate/AcmCertificateMetadata';
import { RdsCertificateMetadata } from '../../Credential/Certificate/RdsCertificateMetadata';
import { KMSMetadata } from '../../Credential/KMS/KMSMetadata';
import { SecretMetadata } from '../../Credential/Secret/SecretMetadata';
import { DocumentDBMetadata } from '../../Data/DocumentDB/DocumentDBMetadata';
import { DynamoDBMetadata } from '../../Data/DynamoDB/DynamoDBMetadata';
import { EBSMetadata } from '../../Data/EBS/EBSMetadata';
import { EFSMetadata } from '../../Data/EFS/EFSMetadata';
import { NeptuneInstanceMetadata } from '../../Data/Neptune/NeptuneInstanceMetadata';
import { ObjectStorageMetadata } from '../../Data/ObjectStorage';
import { RDSInstanceMetadata } from '../../Data/RDS/RDSInstanceMetadata';
import { ElasticContainerRegistryMetadata } from '../../ECR/ElasticContainerRegistryMetadata';
import { ElasticBeanstalkMetadata } from '../../ElasticBeanstalk/ElasticBeanstalkMetadata';
import { AccountMetadata } from '../../Identity/AccountRoot/AccountMetadata';
import { AzureADGroupMetadata } from '../../Identity/AzureADGroup/AzureADGroupMetadata';
import { AzureADUserMetadata } from '../../Identity/AzureADUser/AzureADUserMetadata';
import { IAMGroupMetadata } from '../../Identity/IAMGroup/IAMGroupMetadata';
import { IAMRoleMetadata } from '../../Identity/IAMRole/IAMRoleMetadata';
import { IAMUserMetadata } from '../../Identity/IAMUser/IAMUserMetadata';
import { OktaDomainMetadata } from '../../Identity/OktaDomain/OktaDomainMetadata';
import { OktaGroupMetadata } from '../../Identity/OktaGroup/OktaGroupMetadata';
import { OktaUserMetadata } from '../../Identity/OktaUser/OktaUserMetadata';
import { CloudTrailMetadata } from '../../Logging/CloudTrail/CloudTrailMetadata';
import { CloudFrontMetadata } from '../../Network/CloudFront/CloudFrontMetadata';
import { ElasticLoadBalancerMetadata } from '../../Network/ElasticLoadBalancer/ElasticLoadBalancerMetadata';
import { InternetGatewayMetadata } from '../../Network/InternetGateway/InternetGatewayMetadata';
import { NATGatewayMetadata } from '../../Network/NATGateway/NATGatewayMetadata';
import { NetworkACLMetadata } from '../../Network/NetworkACL/NetworkACLMetadata';
import { NetworkInterfaceMetadata } from '../../Network/NetworkInterface/NetworkInterfaceMetadata';
import { Route53HealthCheckMetadata } from '../../Network/Route53/Route53HealthCheckMetadata';
import { Route53HostedZoneMetadata } from '../../Network/Route53/Route53HostedZoneMetadata';
import { RouteTableMetadata } from '../../Network/RouteTable/RouteTableMetadata';
import { SecurityGroupOverview } from '../../Network/SecurityGroup/SecurityGroupOverview';
import { SubnetMetadata } from '../../Network/Subnet/SubnetMetadata';
import { TransitGatewayMetadata } from '../../Network/TransitGateway/TransitGatewayMetadata';
import { TransitGatewayAttachmentMetadata } from '../../Network/TransitGatewayAttachment/TransitGatewayAttachmentMetadata';
import { VirtualInterfaceMetadata } from '../../Network/VirtualInterface/VirtualInterfaceMetadata';
import { VPCMetadata } from '../../Network/VPC/VPCMetadata';
import { OktaOrganizationMetadata } from '../../Organization/OktaOrganizationMetadata';
import { OrganizationMetadata } from '../../Organization/OrganizationMetadata';
import { OrgUnitMetadata } from '../../Organization/OrgUnitMetadata';
import { IAMPolicyMetadata } from '../../Policy/IAMPolicyMetadata';
import { IdentityPolicyMetadata } from '../../Policy/IdentityPolicyMetadata';
import { ResourcePolicyMetadata } from '../../Policy/ResourcePolicyMetadata';
import { ResourceOverview } from '../../ResourceMetadata/ResourceOverview';
import { EbsSnapshotMetadata } from '../../Snapshot/EbsSnapshotMetadata';
import { RdsSnapshotMetadata } from '../../Snapshot/RdsSnapshotMetadata';

export const getSummary = (resource_type: string) => {
  const type = resource_type?.toLowerCase();
  if (
    type?.startsWith('gcp') ||
    type?.startsWith('azure') ||
    resource_type === NativeResources.Aks
  )
    return <GcpMetadata />;
  switch (resource_type) {
    case NativeResources.AsgResourcePolicy:
    case NativeResources.OrganizationPolicy:
    case NativeResources.ResourcePolicy:
      return <ResourcePolicyMetadata />;
    case NativeResources.AccountPasswordIdentityPolicy:
      return <IdentityPolicyMetadata />;
    case NativeResources.IamPolicy:
      return <IAMPolicyMetadata />;
    case Resources.Role:
    case NativeResources.IamRole:
      return <IAMRoleMetadata />;
    case NativeResources.NetworkAcl:
      return <NetworkACLMetadata />;
    case Resources.User:
    case NativeResources.IamUser:
      return <IAMUserMetadata />;
    case NativeResources.ElasticFileSystem:
      return <EFSMetadata />;
    case Resources.BlockStorage:
    case NativeResources.ElasticBlockStorage:
      return <EBSMetadata />;
    case NativeResources.KeyManagementService:
      return <KMSMetadata />;
    case NativeResources.RelationalDatabaseService:
      return <RDSInstanceMetadata />;
    case NativeResources.IamUserAccessKey:
      return <AccessKeyMetadata />;
    case NativeResources.RdsCluster:
      return <RDSClusterMetadata />;
    case NativeResources.LambdaFunction:
      return <LambdaMetadata />;
    case NativeResources.DynamoDb:
      return <DynamoDBMetadata />;
    case Resources.Notification:
    case NativeResources.SimpleNotificationService:
      return <SNSTopicMetadata />;
    case NativeResources.AwsOrganization:
      return <OrganizationMetadata />;
    case NativeResources.OrganizationUnit:
      return <OrgUnitMetadata />;
    case NativeResources.SimpleQueueService:
      return <SQSMetadata />;
    case NativeResources.Subnet:
      return <SubnetMetadata />;
    case NativeResources.SecurityGroup:
      return <SecurityGroupOverview />;
    case NativeResources.VirtualPrivateCloud:
      return <VPCMetadata />;
    case NativeResources.VirtualInterface:
      return <VirtualInterfaceMetadata />;
    case NativeResources.AwsConfigRule:
      return <ConfigurationMetadata />;
    case NativeResources.SimpleStorageService:
    case Resources.ObjectStorage:
      return <ObjectStorageMetadata />;
    case NativeResources.ElasticKubernetesService:
      return <KubernetesMetadata />;
    case Resources.VirtualMachine:
    case NativeResources.Ec2Instance:
      return <VMMetadata />;
    case NativeResources.ElasticSearch:
      return <ElasticSearchDomain />;
    case NativeResources.RedshiftCluster:
      return <RedshiftClusterMetadata />;
    case NativeResources.ElasticContainerService:
      return <ECSMetadata />;
    case NativeResources.EcsCluster:
      return <ECSClusterMetadata />;
    case NativeResources.ElasticContainerRegistry:
      return <ElasticContainerRegistryMetadata />;
    case NativeResources.EcsContainerInstance:
      return <ECSContainerInstanceMetadata />;
    case NativeResources.EcsService:
      return <ECSServiceMetadata />;
    case NativeResources.EbsSnapshot:
      return <EbsSnapshotMetadata />;
    case NativeResources.Secret:
      return <SecretMetadata />;
    case NativeResources.AcmCertificate:
      return <AcmCertificateMetadata />;
    case NativeResources.RdsCertificate:
      return <RdsCertificateMetadata />;
    case NativeResources.CloudFront:
      return <CloudFrontMetadata />;
    case NativeResources.Wafv1:
      return <WafClassicMetadata />;
    case NativeResources.Wafv2:
      return <WafV2Metadata />;
    case NativeResources.ElasticBeanstalk:
      return <ElasticBeanstalkMetadata />;
    case NativeResources.InternetGateway:
      return <InternetGatewayMetadata />;
    case NativeResources.NatGateway:
      return <NATGatewayMetadata />;
    case NativeResources.TransitGateway:
      return <TransitGatewayMetadata />;
    case NativeResources.TransitGatewayAttachment:
      return <TransitGatewayAttachmentMetadata />;
    case NativeResources.SubnetRouteTable:
    case NativeResources.TransitGatewayRouteTable:
      return <RouteTableMetadata />;
    case NativeResources.SagemakerNotebookInstances:
      return <SagemakerMetadata />;
    case NativeResources.OktaUser:
      return <OktaUserMetadata />;
    case NativeResources.OktaGroup:
      return <OktaGroupMetadata />;
    case NativeResources.OktaDomain:
      return <OktaDomainMetadata />;
    case NativeResources.OktaOrganization:
      return <OktaOrganizationMetadata />;
    case NativeResources.AzureAdUser:
      return <AzureADUserMetadata />;
    case NativeResources.AzureAdGroup:
      return <AzureADGroupMetadata />;
    case NativeResources.AutoscalingGroup:
    case NativeResources.Ec2AutoScalingGroup:
      return <AutoScalingGroupMetadata />;
    case NativeResources.Route53HostedZone:
      return <Route53HostedZoneMetadata />;
    case NativeResources.Route53HealthCheck:
      return <Route53HealthCheckMetadata />;
    case NativeResources.RdsSnapshot:
      return <RdsSnapshotMetadata />;
    case NativeResources.ElasticLoadBalancer:
    case NativeResources.ElasticLoadBalancerv2:
      return <ElasticLoadBalancerMetadata />;
    case NativeResources.ElasticMapReduce:
      return <ElasticMapReduceMetadata />;
    case NativeResources.ApiGatewayRestApi:
      return <APIGatewayRestAPIMetadata />;
    case NativeResources.IamGroup:
      return <IAMGroupMetadata />;
    case NativeResources.ElastiCache:
      return <ElastiCacheMetadata />;
    case NativeResources.DocumentDb:
      return <DocumentDBMetadata />;
    case NativeResources.NeptuneCluster:
      return <NeptuneClusterMetadata />;
    case NativeResources.Neptune:
      return <NeptuneInstanceMetadata />;
    case NativeResources.ElasticNetworkInterface:
      return <NetworkInterfaceMetadata />;
    case NativeResources.CloudTrail:
      return <CloudTrailMetadata />;
    case NativeResources.PrefixList:
      return <PrefixListMetadata />;
    case NativeResources.DhcpOptions:
      return <DHCPMetadata />;
    case NativeResources.SshKeyPair:
      return <KeyPairMetadata />;
    case NativeResources.AwsAccount:
    case NativeResources.AccountRoot:
      return <AccountMetadata />;
    case NativeResources.SnowflakeUsers:
      return <SnowflakeUserMetadata />;
    case NativeResources.SnowflakeRoles:
      return <SnowflakeRoleMetadata />;
    case NativeResources.SnowflakeDatabases:
      return <SnowflakeDatabaseMetadata />;
    case NativeResources.SnowflakeTables:
      return <SnowflakeTableMetadata />;
    case NativeResources.Container:
      return <ContainerMetadata />;
    case NativeResources.GitLabProject:
    case NativeResources.GitHubRepository:
    case NativeResources.BitBucketRepository:
      return <GitlabRepositoryMetadata />;
    default:
      return <ResourceOverview />;
  }
};
