import {
  CountSummary,
  DashboardApiGetDrawerConfigRequest,
  DashboardApiGetDrawerDataRequest,
  DashboardDrawerConfig,
  DataSourcesSummary,
  EntityRow,
  ResourceApiGetDataSourcesSummaryRequest,
  ResourceSummaryApiGetSummaryByTypeRequest,
  SearchApiIsMapAvailableRequest,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummary,
  AggregatedAlertsSummaryResponse,
  AlertHistoryResponse,
  AlertLogsApiGetTicketSummaryRequest,
  AlertsApiGetAggregatedAlertsSummaryRequest,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetAlertingResourcesCountRequest,
  AlertsApiGetAlertSummaryRequest,
  AlertsApiGetCountByResourceUuidsRequest,
  AlertsApiGetFindingsCategoriesSummaryRequest,
  AlertSummaryResponse,
  TicketSummaryResponse,
} from '@ariksa/notification/api';
import {
  ChartsApiGetDashboardResourcesHistoryRequest,
  ChartsApiGetDashboardResourcesRequest,
  ChartsApiGetTotalAlertsRequest,
  InventoryApiGetResourceCountRequest,
  ResourceCount,
  ResourceCountResponse,
  TotalAlertsResponse,
} from '@ariksa/reporting/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  InventoryService,
  NotificationService,
  ReportingService,
} from 'api/services';
import { doGetResourceCategories } from 'containers/Inventory/CloudInventory/saga';
import { doGetAnalyzedSources } from 'containers/Visibility/Data/saga';
import { doGetVulnerabilitiesBySeverity } from 'containers/Visibility/Vulnerabilities/saga';

import { actions } from './slice';

export function* dashboardSaga() {
  yield takeLatestAction(actions.getCloudSummary, doGetCloudSummary);
  yield takeLatestAction(actions.getResourcesCount, doGetResourcesCount);
  yield takeLatestAction(actions.getAlertingResources, doGetAlertingResources);
  yield takeLatestAction(actions.getOpenFindings, doGetOpenFindings);
  yield takeLatestAction(actions.getOpenAlerts, doGetOpenAlerts);
  yield takeLatestAction(
    actions.getOpenFindingHistory,
    doGetOpenFindingHistory,
  );
  yield takeLatestAction(
    actions.getCloudPostureOpenFindingHistory,
    doGetOpenFindingHistory,
  );
  yield takeLatestAction(
    actions.getDataSecurityOpenFindingHistory,
    doGetOpenFindingHistory,
  );
  yield takeLatestAction(
    actions.getIdentityAuthorizationOpenFindingHistory,
    doGetOpenFindingHistory,
  );
  yield takeLatestAction(actions.getIdentityCount, doGetIdentityCount);
  yield takeLatestAction(actions.getComputeCount, doGetComputeCount);
  yield takeLatestAction(actions.getNetworkCount, doGetNetworkCount);
  yield takeLatestAction(actions.getCredentialCount, doGetCredentialCount);
  yield takeLatestAction(actions.getDomainsCount, doGetCredentialCount);

  //state of your cloud
  yield takeLatestAction(actions.getInventorySummary, doGetInventorySummary);
  yield takeLatestAction(actions.getEntitiesHistory, doGetEntitiesHistory);
  yield takeLatestAction(
    actions.getInventoryResourcesSummary,
    doGetResourceCategories,
  );
  yield takeLatestAction(actions.getDataCount, doGetDataCount);
  yield takeLatestAction(actions.getAlertsHistory, doGetAlertsHistory);
  yield takeLatestAction(
    actions.getAnalyzedDataSourcesCount,
    doGetAnalyzedSources,
  );
  yield takeLatestAction(
    actions.getFindingsCategorySummary,
    doGetFindingsCategorySummary,
  );
  yield takeLatestAction(
    actions.getSummaryOfAllDataSources,
    doGetSummaryOfAllDataSources,
  );
  yield takeLatestAction(
    actions.getInfrastructurePostureSummary,
    doGetInfrastructurePostureSummary,
  );

  yield takeLatestAction(
    actions.getVulnerableResourcesSummary,
    doGetVulnerableResourcesSummary,
  );
  yield takeLatestAction(
    actions.getCriticalAndHighVulnerabilities,
    doGetVulnerabilitiesBySeverity,
  );

  yield takeLatestAction(actions.getDrawerConfig, doGetDrawerConfig);
  yield takeLatestAction(actions.getAllEntities, doGetAllEntities);
  yield takeLatestAction(actions.getAlertCount, doGetAlertCount);
  yield takeLatestAction(
    actions.getSecurityGraphAvailability,
    doGetSecurityGraphAvailability,
  );
}

//get state of your cloud summary
export function* doGetCloudSummary(
  ctx: QueryContext<CountSummary, ResourceSummaryApiGetSummaryByTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.ResourceSummary.getSummaryByType(ctx.params),
    { errorMsg: 'Failed to get cloud summary!', cacheKey: 'cloudSummary' },
  );
}

//get state of your cloud summary
export function* doGetInventorySummary(
  ctx: QueryContext<CountSummary, ResourceSummaryApiGetSummaryByTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.ResourceSummary.getSummaryByType(ctx.params),
    {
      errorMsg: 'Failed to get inventory summary!',
      cacheKey: 'inventorySummary',
    },
  );
}

//get entities history
export function* doGetEntitiesHistory(
  ctx: QueryContext<
    Record<string, number>,
    ChartsApiGetDashboardResourcesHistoryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getDashboardResourcesHistory(ctx.params),
    {
      errorMsg: 'Failed to get entities history!',
      cacheKey: 'entitiesHistory',
    },
  );
}

//get count and change
export function* doGetDataCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'dataReportingResourcesCount',
    },
  );
}

//get resources count
export function* doGetResourcesCount(
  ctx: QueryContext<ResourceCount, ChartsApiGetDashboardResourcesRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getDashboardResources(ctx.params),
    { errorMsg: 'Failed to get resources count!', cacheKey: 'resourcesCount' },
  );
}

//get alerting resources
export function* doGetAlertingResources(
  ctx: QueryContext<number, AlertsApiGetAlertingResourcesCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertingResourcesCount(ctx.params),
    {
      errorMsg: 'Failed to get alerting resources count!',
      cacheKey: 'alertingResources',
    },
  );
}

//get open alerts
export function* doGetOpenAlerts(
  ctx: QueryContext<AlertSummaryResponse, AlertsApiGetAlertSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertSummary(ctx.params),
    { errorMsg: 'Failed to get open alert count!', cacheKey: 'openAlerts' },
  );
}

//get ticket summary
export function* doGetTicketSummary(
  ctx: QueryContext<TicketSummaryResponse, AlertLogsApiGetTicketSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertsLogs.getTicketSummary(ctx.params),
    { errorMsg: 'Failed to get ticket summary!', cacheKey: 'ticketSummary' },
  );
}

//get findings summary
export function* doGetOpenFindings(
  ctx: QueryContext<
    AggregatedAlertsSummaryResponse,
    AlertsApiGetAggregatedAlertsSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAggregatedAlertsSummary(ctx.params),
    { errorMsg: 'Failed to get open findings!', cacheKey: 'openFindings' },
  );
}

//get findings history
export function* doGetOpenFindingHistory(
  ctx: QueryContext<AlertHistoryResponse, AlertsApiGetAlertHistoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertHistory(ctx.params),
    {
      errorMsg: 'Failed to get open finding history!',
      cacheKey: 'openFindingHistory',
    },
  );
}

//get governance scores
export function* doGetAlertsHistory(
  ctx: QueryContext<TotalAlertsResponse, ChartsApiGetTotalAlertsRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getTotalAlerts(ctx.params),
    {
      errorMsg: 'Failed to get alerts history!',
      cacheKey: 'alertsHistory',
    },
  );
}

//get findings category summary
export function* doGetFindingsCategorySummary(
  ctx: QueryContext<
    AggregatedAlertsSummary[],
    AlertsApiGetFindingsCategoriesSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getFindingsCategoriesSummary(ctx.params),
    {
      errorMsg: 'Failed to get findings category summary!',
      cacheKey: 'findingsCategorySummary',
    },
  );
}

//get data sources summary (for data security)
export function* doGetSummaryOfAllDataSources(
  ctx: QueryContext<
    DataSourcesSummary,
    ResourceApiGetDataSourcesSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getDataSourcesSummary(ctx.params),
    {
      errorMsg: 'Failed to get data sources summary!',
      cacheKey: 'summaryOfAllDataSources',
    },
  );
}

//get Infrastructure Posture Summary
export function* doGetInfrastructurePostureSummary(
  ctx: QueryContext<CountSummary, ResourceSummaryApiGetSummaryByTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.ResourceSummary.getSummaryByType(ctx.params),
    {
      errorMsg: 'Failed to get infrastructure posture summary!',
      cacheKey: 'infrastructurePostureSummary',
    },
  );
}

/*get vulnerable resources summary*/
export function* doGetVulnerableResourcesSummary(
  ctx: QueryContext<CountSummary, ResourceSummaryApiGetSummaryByTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.ResourceSummary.getSummaryByType(ctx.params),
    {
      errorMsg: 'Failed to get vulnerable resources summary!',
      cacheKey: 'vulnerableResourcesSummary',
    },
  );
}

export function* doGetIdentityCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'identityReportingResourcesCount',
    },
  );
}

export function* doGetComputeCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'computeReportingResourcesCount',
    },
  );
}

export function* doGetNetworkCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'networkReportingResourcesCount',
    },
  );
}

export function* doGetCredentialCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'credentialReportingResourcesCount',
    },
  );
}

export function* doGetDomainsCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'domainsReportingResourcesCount',
    },
  );
}

export function* doGetAllEntities(
  ctx: QueryContext<
    EntityRow[],
    DashboardApiGetDrawerDataRequest & { total: number }
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Dashboard.getDrawerData(ctx.params),
    {
      errorMsg: 'Failed to get entities!',
    },
  );
}

export function* doGetAlertCount(
  ctx: QueryContext<
    Record<string, number>,
    AlertsApiGetCountByResourceUuidsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getCountByResourceUuids(ctx.params),
    {
      errorMsg: 'Failed to get alert count!',
    },
  );
}

export function* doGetDrawerConfig(
  ctx: QueryContext<DashboardDrawerConfig, DashboardApiGetDrawerConfigRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Dashboard.getDrawerConfig(ctx.params),
    {
      errorMsg: 'Failed to get config!',
    },
  );
}

export function* doGetSecurityGraphAvailability(
  ctx: QueryContext<Record<string, boolean>, SearchApiIsMapAvailableRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Search.isMapAvailable(ctx.params),
    {
      errorMsg: 'Failed to get security graph availability!',
    },
  );
}
