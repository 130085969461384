import React from 'react';

import { UserIcon } from 'components/Icons';
import { CDashboard } from 'components/Visualization';
import { identityDashboardConfig } from 'containers/Dashboard/IdentityOverview/config';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';

export const IdentityAuthorization: React.FC = () => {
  return (
    <DashboardOverviewWrapperCard
      label="Identity & Privileges"
      content={<CDashboard dashboardWidgets={identityDashboardConfig} />}
      icon={<UserIcon />}
    />
  );
};
