import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { each, orderBy } from 'lodash';
import { useSelector } from 'react-redux';

import { WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const DataFootprintByRegion: React.FC = () => {
  const { dataSourcesByRegion } = useSelector(selectDataDashboard);
  const [clouds, setClouds] = useState(0);
  const { regions } = dataSourcesByRegion.data;

  useEffect(() => {
    let clouds = new Set();
    each(regions, o => !!o?.cloud && clouds.add(o?.cloud));
    setClouds(clouds.size);
  }, [regions]);

  const columns = [
    {
      header: <Box pl={9}>SOURCE</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.cloud}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
        >
          {row?.region}
        </WithResourceIcon>
      ),
      styles: { td: { w: '60%' } },
    },
    {
      header: 'SOURCE COUNT',
      render: ({ row }) => formatNumber(row.region_count, 1),
      styles: {
        td: { w: '40%' },
        cell: { textAlign: 'right', w: '58%' },
        header: { textAlign: 'center' },
      },
    },
  ];

  return (
    <DashboardWidget
      label="Data Footprint By Region"
      subHeader={
        clouds +
        ' cloud' +
        (clouds === 1 ? ', ' : 's, ') +
        regions?.length +
        ' region' +
        (regions?.length === 1 ? '' : 's')
      }
      content={
        <DashboardTable
          columns={columns}
          data={orderBy(regions, o => o.region_count, ['desc'])}
          isLoading={dataSourcesByRegion.isLoading}
        />
      }
    />
  );
};
