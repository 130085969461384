import React from 'react';

import { formatTooltip } from 'components/DataDisplay';
import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { ComputeEntities } from 'containers/Dashboard/CloudPostureOverview/Components/ComputeEntities';
import { Credentials } from 'containers/Dashboard/CloudPostureOverview/Components/Credentials';
import { DataSources } from 'containers/Dashboard/CloudPostureOverview/Components/DataSources';
import { Domains } from 'containers/Dashboard/CloudPostureOverview/Components/Domains';
import { HighlyPrivilegedMachineIdentities } from 'containers/Dashboard/CloudPostureOverview/Components/HighlyPrivilegedMachineIdentities';
import { InfrastructureFindingsToPrioritize } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructureFindingsToPrioritize';
import { InfrastructurePostureFindings } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructurePostureFindings';
import { InfrastructurePostureSummary } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructurePostureSummary';
import { InfrastructureThatLacksEncryption } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructureThatLacksEncryption';
import { InsightsForCloudNetworking } from 'containers/Dashboard/CloudPostureOverview/Components/InsightsForCloudNetworking';
import { NetworkEntities } from 'containers/Dashboard/CloudPostureOverview/Components/NetworkEntities';
import { TopPubliclyAccessibleMachinesAndDataSources } from 'containers/Dashboard/CloudPostureOverview/Components/TopPubliclyAccessibleMachinesAndDataSources';
import { VulnerablePubliclyExposedEntities } from 'containers/Dashboard/CloudPostureOverview/Components/VulnerablePubliclyExposedEntities';
import { WellKnownPorts } from 'containers/Dashboard/CloudPostureOverview/Components/WellKnownPorts';
import { CloudCompliance } from 'containers/Dashboard/Overview/Components/CloudCompliance';
import { CriticalAndHighSeverityFindingsByCategory } from 'containers/Dashboard/Overview/Components/CriticalAndHighSeverityFindingsByCategory';
import { DataSourcesSummaryCard } from 'containers/Dashboard/Overview/Components/DataSourcesSummaryCard';
import { Entities } from 'containers/Dashboard/Overview/Components/Entities';
import { FindingsToPrioritizeAcrossAllCategories } from 'containers/Dashboard/Overview/Components/FindingsToPrioritizeAcrossAllCategories';
import { GovernanceScore } from 'containers/Dashboard/Overview/Components/GovernanceScore';
import { Identities } from 'containers/Dashboard/Overview/Components/Identities';
import { Infrastructure } from 'containers/Dashboard/Overview/Components/Infrastructure';
import { OpenAlertsAndResolutions } from 'containers/Dashboard/Overview/Components/OpenAlertsAndResolutions';
import { PolicyManagement } from 'containers/Dashboard/Overview/Components/PolicyManagement';
import { RuntimeInsights } from 'containers/Dashboard/Overview/Components/RuntimeInsights';
import { Summary } from 'containers/Dashboard/Overview/Components/Summary';

export const overviewDashboardWidgets: Record<string, WidgetProps> = {
  summary: {
    flex: true,
    components: {
      content: <InfrastructurePostureSummary />,
    },
  },
  computeEntities: {
    flex: true,
    components: {
      content: <ComputeEntities />,
    },
  },
  dataSources: {
    flex: true,
    components: {
      content: <DataSources />,
    },
  },
  networkEntities: {
    flex: true,
    components: {
      content: <NetworkEntities />,
    },
  },
  domains: {
    flex: true,
    components: {
      content: <Domains />,
    },
  },
  credentials: {
    flex: true,
    components: {
      content: <Credentials />,
    },
  },
  infrastructureFindingsToPrioritize: {
    flex: true,
    components: {
      content: <InfrastructureFindingsToPrioritize />,
    },
  },
  infrastructurePostureFindings: {
    flex: true,
    components: {
      content: <InfrastructurePostureFindings />,
    },
  },
  publiclyAccessibleEntities: {
    flex: true,
    components: {
      content: <TopPubliclyAccessibleMachinesAndDataSources />,
    },
  },
  vulnerablePubliclyExposedEntities: {
    flex: true,
    components: {
      content: <VulnerablePubliclyExposedEntities />,
    },
  },
  infrastructureThatLacksEncryption: {
    flex: true,
    components: {
      content: <InfrastructureThatLacksEncryption />,
    },
  },
  highlyPrivilegedMachineIdentities: {
    flex: true,
    components: {
      content: <HighlyPrivilegedMachineIdentities />,
    },
  },
  wellKnownPorts: {
    flex: true,
    components: {
      content: <WellKnownPorts />,
    },
  },
  insightsForCloudNetworking: {
    flex: true,
    components: {
      content: <InsightsForCloudNetworking />,
    },
  },
};
