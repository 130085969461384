import {
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  ResourceApiGetZonesByRegionForAccountRequest,
} from '@ariksa/inventory-core/api';
import {
  DefaultApiGetCrossAccountsForScanningRequest,
  DefaultApiUpdateScannerSettingsRequest,
  ScannerCrossAccounResponse,
} from '@ariksa/scan-analysis/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { InventoryService, ScanAnalysis } from 'api/services';
import {
  doGetCloudAccountDataScannerInfo,
  doGetCloudAccountVulnerabilityScanner,
} from 'containers/Setup/CloudAccounts/saga';

import { actions } from './slice';

export function* outpostSaga() {
  yield takeLatestAction(
    actions.getVulnerabilityScanners,
    doGetCloudAccountVulnerabilityScanner,
  );
  yield takeLatestAction(
    actions.getDataScanners,
    doGetCloudAccountDataScannerInfo,
  );
  yield takeLatestAction(actions.generateOutpostToken, doGenerateOutpostToken);
  yield takeLatestAction(actions.getZones, doGetZones);
  yield takeLatestAction(actions.rescanResources, doRescanResources);
  yield takeLatestAction(actions.getAccountsScanned, doGetAccountsScanned);
  yield takeLatestAction(actions.getNetworks, doGetNetworks);
  yield takeLatestAction(actions.getSubnets, doGetSubnets);
}

function* doGenerateOutpostToken(ctx: QueryContext<any>) {
  //yield call(ctx.fetch, () => );
}

export function* doGetZones(
  ctx: QueryContext<string[], ResourceApiGetZonesByRegionForAccountRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getZonesByRegionForAccount(ctx.params),
    {
      errorMsg: 'Failed to get zones!',
    },
  );
}

export function* doRescanResources(
  ctx: QueryContext<any, DefaultApiUpdateScannerSettingsRequest>,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.updateScannerSettings(ctx.params),
    {
      errorMsg: 'Failed to rescan resources!',
    },
  );
}

export function* doGetAccountsScanned(
  ctx: QueryContext<
    ScannerCrossAccounResponse,
    DefaultApiGetCrossAccountsForScanningRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getCrossAccountsForScanning(ctx.params),
    {
      errorMsg: 'Failed to get accounts scanned!',
    },
  );
}

export function* doGetSubnets(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2Request>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.InsightV2.insightV2(ctx.params),
    {
      errorMsg: 'Failed to get subnets!',
    },
  );
}

export function* doGetNetworks(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2Request>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.InsightV2.insightV2(ctx.params),
    {
      errorMsg: 'Failed to get networks!',
    },
  );
}
