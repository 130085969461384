import React, { memo, useState } from 'react';

import { DataClassifiers } from '@ariksa/data-scanning/api';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { getIcon, WithResourceIcon } from 'components/DataDisplay';
import { FormAction } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import {
  SideNav,
  SideNavKey,
  SideNavMenuItemProps,
} from 'components/Navigation';
import { DataClassifierTable } from 'containers/Setup/DataConfiguration/Components/DataClassifier/DataClassifierTable';
import { DataClassifierWizard } from 'containers/Setup/DataConfiguration/Components/DataClassifier/DataClassifierWizard';
import { DataConfigurationContextProvider } from 'containers/Setup/DataConfiguration/context';
import { useInjector } from 'utils/inject';

import { dataClassificationSaga } from './saga';
import { sliceKey, reducer } from './slice';

export const DataConfiguration = memo(() => {
  useInjector(sliceKey, reducer, dataClassificationSaga);
  const { contentHeight } = usePageContentContext();
  const [selectedTab, setSelectedTab] = useState<SideNavKey>('types');
  const [currentRecord, setCurrentRecord] = useState<DataClassifiers>(
    {} as DataClassifiers,
  );
  const [actionType, setActionType] = useState<FormAction>('Add');
  const configurationActions = useDisclosure();

  const menuItems: SideNavMenuItemProps[] = [
    /*{
      title: 'Data Sources',
      key: 'data_sources',
      icon: <Box color="primary">{getIcon(IconTypes.Database)}</Box>,
      component: configurationActions.isOpen ? (
        <DataClassificationWizard />
      ) : (
        <DataClassificationTable />
      ),
    },*/
    {
      title: 'Data Types',
      key: 'types',
      icon: <Box color="primary">{getIcon(IconTypes.SensitiveData)}</Box>,
      component: configurationActions.isOpen ? (
        <DataClassifierWizard />
      ) : (
        <DataClassifierTable />
      ),
    },
    {
      title: 'Data Exclusions',
      key: 'exclusions',
      icon: <Box color="primary">{getIcon(IconTypes.Disable)}</Box>,
      component: configurationActions.isOpen ? (
        <DataClassifierWizard />
      ) : (
        <DataClassifierTable />
      ),
    },
  ];

  return (
    <DataConfigurationContextProvider
      value={{
        selectedTab,
        setSelectedTab,
        currentRecord,
        setCurrentRecord,
        actionType,
        setActionType,
        isOpenForm: configurationActions.isOpen,
        onOpenForm: configurationActions.onOpen,
        onCloseForm: configurationActions.onClose,
      }}
    >
      <HStack
        spacing={customTheme.sizes.interCardSpacing}
        h={contentHeight + 'px'}
        w="full"
      >
        <SideNav
          menuItems={menuItems}
          showContentHeader={false}
          navHeader={
            <WithResourceIcon
              resourceType={IconTypes.Configuration}
              bgColor="primary"
              iconSize="small"
            >
              <Box>Data Configuration</Box>
            </WithResourceIcon>
          }
          current={selectedTab}
          onChange={tab => {
            setSelectedTab(tab);
            configurationActions.onClose();
          }}
        />
      </HStack>
    </DataConfigurationContextProvider>
  );
});
