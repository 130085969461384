import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

import { overviewDashboardWidgets } from './widget';

export const infrastructureDashboardConfig: IDashboardLayout = {
  id: 'overview_dashboard',
  title: 'Overview Dashboard',
  widgets: [
    {
      type: 'card',
      i: 'summary',
      x: 0,
      y: 0,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'computeEntities',
      x: 0,
      y: 3.8,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataSources',
      x: 0,
      y: 7.6,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'networkEntities',
      x: 0,
      y: 11.4,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'domains',
      x: 0,
      y: 15.2,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'credentials',
      x: 0,
      y: 19,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'infrastructureFindingsToPrioritize',
      x: 2.4,
      y: 0,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'infrastructurePostureFindings',
      x: 5.6,
      y: 0,
      w: 6.4,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'publiclyAccessibleEntities',
      x: 2.4,
      y: 7.6,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'vulnerablePubliclyExposedEntities',
      x: 5.6,
      y: 7.6,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'infrastructureThatLacksEncryption',
      x: 8.8,
      y: 7.6,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'highlyPrivilegedMachineIdentities',
      x: 2.4,
      y: 15.2,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'wellKnownPorts',
      x: 5.6,
      y: 15.2,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'insightsForCloudNetworking',
      x: 8.8,
      y: 15.2,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
  ],
  available: overviewDashboardWidgets,
};
