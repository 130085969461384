import React from 'react';

import { useSelector } from 'react-redux';

import { CloudServiceIcon } from 'components/Icons';
import { AnalyzedCard } from 'containers/Visibility/Data/Components/Dashboard/Cards/AnalyzedCard';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const SaaSCard = () => {
  const {
    analyzedSources,
    summaryOfAllDataSources,
    sensitiveDataByAgnosticSources,
  } = useSelector(selectDataDashboard);
  const dataSources = summaryOfAllDataSources.data?.resource_type_counts;
  const sensitiveData = sensitiveDataByAgnosticSources.data;

  return (
    <AnalyzedCard
      label="SaaS"
      isLoading={analyzedSources.isLoading}
      icon={<CloudServiceIcon />}
      total={dataSources?.SaaS ?? 0}
      analyzed={analyzedSources.data?.SaaS ?? 0}
      sensitiveDataCount={sensitiveData?.SaaS ?? 0}
    />
  );
};
