import React, { useState } from 'react';

import { PanelsEnum, Resources } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { GlobeIcon, IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { CountCards } from 'containers/Dashboard/utils/CountCards';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';

export const InternetExposedHosts: React.FC = () => {
  const { internetExposedEntities } = useSelector(selectVulnerabilityOverview);
  const { data, isLoading } = internetExposedEntities;
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  const items = [
    {
      label: 'Virtual Machines',
      count: (data?.items?.['Virtual Machine'] as number) || 0,
      isLoading: isLoading,
      iconType: IconTypes.Ec2Instance,
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.PubliclyExposedWithVulnerabilities,
          iconType: IconTypes.Ec2Instance,
          total: (data?.items?.['Virtual Machine'] as number) || 0,
          resourceType: Resources.VirtualMachine,
          resourceTypeClass: 'agnostic',
        });
        /*navigate(
          '/visibility/security-graph?query_name=' +
          SearchQueryNameMapping.Show_VMs_that_is_publicly_exposed_with_critical_high_vulnerabilities,
        )*/
      },
      iconColor: 'primary',
    },
    {
      label: 'Serverless',
      count: (data?.items?.['Serverless'] as number) || 0,
      isLoading: isLoading,
      iconType: IconTypes.LambdaFunction,
      iconColor: 'orange',
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.PubliclyExposedWithVulnerabilities,
          iconType: IconTypes.LambdaFunction,
          total: (data?.items?.['LambdaFunction'] as number) || 0,
          resourceType: Resources.ServerlessCompute,
          resourceTypeClass: 'agnostic',
        });
      },
    },
    {
      label: 'Containers',
      count: (data?.items?.['Containers'] as number) || 0,
      isLoading: isLoading,
      iconType: IconTypes.Container,
      iconColor: 'skyBlue.300',
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.PubliclyExposedWithVulnerabilities,
          iconType: IconTypes.Container,
          total: (data?.items?.['Containers'] as number) || 0,
          resourceType: Resources.Containers,
          resourceTypeClass: 'agnostic',
        });
      },
    },
    {
      label: 'Kubernetes',
      count: (data?.items?.['Kubernetes'] as number) || 0,
      isLoading: isLoading,
      iconType: IconTypes.Kubernetes,
      iconColor: 'blue.300',
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.PubliclyExposedWithVulnerabilities,
          iconType: IconTypes.Kubernetes,
          total: (data?.items?.['Kubernetes'] as number) || 0,
          resourceType: Resources.Kubernetes,
          resourceTypeClass: 'agnostic',
        });
      },
    },
  ];

  return (
    <>
      <DashboardWidget
        label="Exposed Entities with Vulnerabilities"
        isLoading={isLoading}
        content={renderResourcesWithIconAndCount(items)}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        vulnerable
        {...details}
      />
    </>
  );
};
