import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { DataFootprintBySourceTypesComponent } from 'containers/Dashboard/utils/DataFootprintBySourceTypesComponent';

export const DataFootprintBySourceTypes: FC = () => {
  const { dataSourcesPieChart } = useSelector(selectDataSecurityOverview);

  return (
    <DataFootprintBySourceTypesComponent
      dataSources={dataSourcesPieChart.data}
      isLoading={dataSourcesPieChart.isLoading}
    />
  );
};
