import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { each, orderBy, sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import { WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const DataSourcesByAccounts: React.FC = props => {
  const { sourcesByAccount } = useSelector(selectDataDashboard);
  const { accountMapping, renderCloudAccountTooltip } = useCloudAccountId();
  const [clouds, setClouds] = useState(0);
  const { accounts } = sourcesByAccount.data;

  useEffect(() => {
    let clouds = new Set();
    each(accounts, o => clouds.add(accountMapping[o?.account_id!]?.cloud_type));
    setClouds(clouds.size);
  }, [accounts, accountMapping]);

  const columns = [
    {
      header: <Box pl={9}>SOURCE</Box>,
      render: ({ row }) => {
        const account = accountMapping[row?.account_id];
        return (
          <WithResourceIcon
            resourceType={account?.cloud_type!}
            tooltip={renderCloudAccountTooltip(row?.account_id)}
            iconFilled={false}
            iconStyles={{ w: 5, h: 5 }}
          >
            {account?.name ?? account?.cloud_account_id}
          </WithResourceIcon>
        );
      },
      styles: { td: { w: '60%' } },
    },
    {
      header: 'SOURCE COUNT',
      render: ({ row }) => formatNumber(row.account_count, 1),
      styles: {
        td: { w: '40%' },
        cell: { textAlign: 'right', w: '58%' },
        header: { textAlign: 'center' },
      },
    },
  ];

  return (
    <DashboardWidget
      label="Data Sources By Accounts"
      subHeader={
        clouds +
        ' cloud' +
        (clouds === 1 ? ', ' : 's, ') +
        (accounts?.length ?? 0) +
        ' account' +
        (accounts?.length === 1 ? '' : 's')
      }
      content={
        <DashboardTable
          columns={columns}
          data={orderBy(accounts, o => o.account_count, ['desc'])}
          isLoading={sourcesByAccount.isLoading}
        />
      }
    />
  );
};
