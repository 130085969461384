import React, { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { getSummary } from './getSummary';

export const CloudResourceOverview: FC = () => {
  const {
    resourceType,
    resourceDetails,
    resourceInsight,
  } = useActiveResourceContext();

  return resourceInsight.isLoading || resourceDetails.isLoading ? (
    <CustomSpinner
      loading={resourceInsight.isLoading || resourceDetails.isLoading}
      size="lg"
    />
  ) : (
    <Box h="full" overflow="auto">
      {getSummary(resourceType)}
    </Box>
  );
};
