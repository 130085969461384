import { SideNavMenuItemStyles, SideNavStyles } from './types';

export const defaultStyles: SideNavStyles = {
  menu: {
    w: 'full',
    fontSize: '15px',
    //overflow: 'scroll',
    h: 'full',
    allowMultiple: true,
    //p: 2,
  },
  menuItem: {
    //py: 3,
    justifyContent: 'space-between',
    fontSize: '15px',
    _hover: { color: 'primary', cursor: 'pointer' },
    _focus: {},
  },
  selectedSecondary: {
    bg: 'selectedSideNavMenuBg',
    //borderRight: '4px solid',
    //borderColor: 'primary',
    color: 'primary',
    pr: 4,
    borderRadius: 6,
  },
  selectedPrimary: {
    color: 'primary',
  },
  componentWrapper: {
    overflow: 'auto',
    w: 'full',
    maxW: 'full',
    flex: 1,
    h: 'full',
  },
};

export const menuItemStyles: SideNavMenuItemStyles = {
  titleWrapper: {
    spacing: 4,
  },
  title: {
    fontSize: 'md',
  },
  iconStyles: {
    boxSize: 8,
    mr: 4,
  },
};
