/**
 *
 * Cloud Account Wizard
 *
 */

import React, { useEffect, useReducer } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box } from '@chakra-ui/react';
import { filter, find, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getIcon } from 'components/Icons/Components';
import { usePageContentContext } from 'components/Layout';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { AriksaOutpostForData } from 'containers/Setup/Outpost/Components/OutpostWizard/AriksaOutpostForData';
import { AriksaOutpost } from 'containers/Setup/Outpost/Components/OutpostWizard/AriksaOutpostForVulnerability';
import { actions } from 'containers/Setup/Outpost/slice';
import { OutpostProps } from 'containers/Setup/Outpost/types';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useSearchParams } from 'hooks/useSearchParams';

export const OutpostWizard = () => {
  const params = useParams<{
    type: string;
  }>();
  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const { contentHeight } = usePageContentContext();
  const cloudAccounts = useSelector(selectOnboardedCloudAccounts);

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      accountOptions: [],
      selectedAccount: {},
      regionOptions: [],
      selectedRegion: {},
      selectedTab: CloudProviders.Aws,
      zoneOptions: [],
      selectedZone: {},
      network: '',
      networkOptions: [],
      subnet: '',
      subnetOptions: [],
      virtualNetworkResourceGroup: '',
    },
  );

  useEffect(() => {
    let options = map(
      filter(cloudAccounts.data, o => o.cloud_type === state.selectedTab),
      o => ({
        label: o?.name + ' (Account ID: ' + o?.cloud_account_id + ')',
        value: o.uuid,
        data: o,
        icon: getIcon(state.selectedTab as string),
      }),
    );
    updateState({ accountOptions: options, selectedAccount: options?.[0] });
  }, [cloudAccounts.data, state.selectedTab]);

  useEffect(() => {
    if (!!searchParams.account_id) {
      updateState({
        selectedAccount:
          find(
            state.accountOptions,
            o => o.value === searchParams.account_id,
          ) || {},
      });
    }
  }, [searchParams, state.accountOptions]);

  useEffect(() => {
    if (!!state.selectedAccount?.value) {
      dispatch(
        sharedStateActions.getRegions({
          q: { accountId: [state.selectedAccount?.value] },
          onSuccess: res => {
            const options = map(res, o => ({ label: o, value: o }));
            updateState({
              regionOptions: options,
              selectedRegion: options?.[0],
            });
            if (isEmpty(options)) {
              updateState({ zoneOptions: [], selectedZone: {} });
            } else if (state.selectedTab === CloudProviders.Gcp) {
              //get zones of a region
              dispatch(
                actions.getZones({
                  q: {
                    accountUuid: state.selectedAccount?.value,
                    project: state.selectedAccount?.data?.cloud_account_id,
                    region: options[0]?.value,
                  },
                  onSuccess: r => {
                    const items = map(r, o => ({ label: o, value: o }));
                    updateState({
                      zoneOptions: items,
                      selectedZone: items?.[0],
                    });
                  },
                }),
              );
            }
          },
        }),
      );
    }
  }, [state.selectedAccount, dispatch, state.selectedTab]);

  useEffect(() => {
    if (
      !!state.selectedAccount?.value &&
      !!state.selectedRegion?.value &&
      state.selectedTab === CloudProviders.Gcp
    ) {
      //get zones of a region
      dispatch(
        actions.getZones({
          q: {
            accountUuid: state.selectedAccount?.value,
            project: state.selectedAccount?.data?.cloud_account_id,
            region: state.selectedRegion?.value,
          },
          onSuccess: r => {
            const items = map(r, o => ({ label: o, value: o }));
            updateState({
              zoneOptions: items,
              selectedZone: items?.[0],
            });
          },
        }),
      );
    }
  }, [
    state.selectedAccount,
    dispatch,
    state.selectedTab,
    state.selectedRegion,
  ]);

  const getComponent = () => {
    const props: OutpostProps = {
      accountOptions: state.accountOptions,
      selectedAccount: state.selectedAccount,
      regionOptions: state.regionOptions,
      selectedRegion: state.selectedRegion,
      selectedTab: state.selectedTab,
      zoneOptions: state.zoneOptions,
      selectedZone: state.selectedZone,
      networkOptions: state.networkOptions,
      network: state.network,
      subnet: state.subnet,
      subnetOptions: state.subnetOptions,
      virtualNetworkResourceGroup: state.virtualNetworkResourceGroup,
      updateValue(field: string, value: any) {
        updateState({ [field]: value });
      },
    };
    switch (params?.type) {
      case 'data':
        return <AriksaOutpostForData {...props} />;
      default:
        return <AriksaOutpost {...props} />;
    }
  };

  return <Box h={contentHeight + 'px'}>{getComponent()}</Box>;
};
