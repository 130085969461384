import React, { FC, useEffect, useState } from 'react';

import { Box, Stack, HStack, Checkbox } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import { RulesForResource } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Compliance/RulesForResource';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { ComplianceContextProvider } from 'containers/Compliance/Components/hooks/context';
import { selectSharedState } from 'containers/SharedState/selectors';

export interface Props {}

export const Compliance: FC<Props> = () => {
  const { blueprints } = useSelector(selectSharedState);
  const [blueprintOptions, setBlueprintsOptions] = useState<
    Record<string, any>[]
  >([]);
  const [currentBlueprint, setCurrentBlueprint] = useState<Record<string, any>>(
    {},
  );
  const {
    resourceType,
    resourceUuid,
    resourceAccountId,
    resourceInsight,
  } = useSelector(selectActiveResource);

  const [showFailed, setShowFailed] = useState(false);

  //set blueprint options
  useEffect(() => {
    if (!isEmpty(blueprints.data)) {
      setBlueprintsOptions(
        map(blueprints.data, o => ({ label: o?.name, value: o })),
      );
    }
  }, [blueprints.data]);

  useEffect(() => {
    setCurrentBlueprint(blueprintOptions[0]);
  }, [blueprintOptions]);

  return (
    <Stack w="full" h="full">
      <HStack justify="space-between">
        <Box w={64}>
          <Select
            options={blueprintOptions}
            value={currentBlueprint}
            onChange={setCurrentBlueprint}
            styles={{
              container: styles => ({ ...styles, zIndex: 999 }),
            }}
          />
        </Box>
        <Checkbox
          isChecked={showFailed}
          onChange={() => setShowFailed(!showFailed)}
        >
          Show Fail Only
        </Checkbox>
      </HStack>
      <Box w="full" h="xl">
        <ComplianceContextProvider>
          <RulesForResource
            showFailed={showFailed}
            showRuleId={false}
            selectedResource={{
              ...resourceInsight.data,
              resource_id: resourceUuid,
              type: resourceType,
              account_id: resourceAccountId,
            }}
            selectedBlueprint={currentBlueprint?.value}
            tableStyles={{ header: { position: 'relative', zIndex: 800 } }}
            fromCompliance={false}
          />
        </ComplianceContextProvider>
      </Box>
    </Stack>
  );
};
