import {
  AIAgentsApi,
  AIBasedAPISelectionParameterExtractionApi,
  AriksaAIApi,
} from '@ariksa/ai-engine';
import { AuditTrailServiceApi } from '@ariksa/audittrail';
import { WorkflowApi } from '@ariksa/awm';
import {
  CloudAccountApi,
  CodeRepositoryApi,
  TemplateApi,
} from '@ariksa/cloud-account';
import {
  AudittrailApi,
  BlueprintApi,
  ChecklistApi,
  CommandsApi,
  ComplianceApi,
  ComplianceStandardsApi,
  EnvironmentApi,
  PermissionsApi,
  RemediationApi,
  ReportApi,
  RulesApi,
} from '@ariksa/compliance-policies';
import {
  DataClassificationApi,
  DataExclusionsApi,
  DataTypesApi,
  FileDataApi,
  OverviewApi,
  ResourcesApi,
  SensitiveDataApi,
  VisibilityDataApi,
} from '@ariksa/data-scanning';
import { DefaultApi as EbpfCollectorApi } from '@ariksa/ebpf-data-collector';
import { EventLogApi } from '@ariksa/event-log/api';
import {
  AllowlistApi,
  AudittrailApi as InventoryAudittrailApi,
  CloudInsightApi,
  ConfigApi,
  ContextApi,
  DashboardApi,
  EbpfApi,
  IACApi,
  InsightApi,
  InsightV2Api,
  MapApi,
  RelationshipApi,
  ResourceApi,
  ResourceSummaryApi,
  SearchApi,
  SearchV2Api,
  TagsApi,
  VulnerabilitiesApi,
} from '@ariksa/inventory-core';
import { ItemApi as JitApi } from '@ariksa/jit';
import { AssessmentApi, NetworkApi } from '@ariksa/network-analysis';
import {
  AlertLogsApi,
  AlertsApi,
  AlertWorkflowApi,
  ArchiveAlertsApi,
  ClientsApi,
  EscalateAlertsApi,
  ForwardAlertsApi,
  SlaApi,
  SnoozedAlertsApi,
  SuppressedAlertsApi,
} from '@ariksa/notification';
import { JourneyApi, QueryFilterApi } from '@ariksa/profile';
import { FaqApi } from '@ariksa/profile/api';
import {
  ChartsApi,
  InventoryApi as ReportingInventoryApi,
  ReportsApi,
  VulnerabilitiesApi as ReportingVulnerabilitiesApi,
} from '@ariksa/reporting';
import { DefaultApi, ItemApi } from '@ariksa/scan-analysis';

import { BASE_URL } from '../service_urls';

import { TokenConfig } from './TokenCredential';

//const credentials = new TokenCredential();
const config = new TokenConfig();

export const JitService = {
  Jit: new JitApi(config, BASE_URL),
};

export const EbpfCollector = {
  Default: new EbpfCollectorApi(config, BASE_URL),
};

export const NotificationService = {
  Clients: new ClientsApi(config, BASE_URL),
  AlertWorkflow: new AlertWorkflowApi(config, BASE_URL),
  Alerts: new AlertsApi(config, BASE_URL),
  AlertsLogs: new AlertLogsApi(config, BASE_URL),
  SnoozedAlerts: new SnoozedAlertsApi(config, BASE_URL),
  ForwardAlerts: new ForwardAlertsApi(config, BASE_URL),
  ArchiveAlerts: new ArchiveAlertsApi(config, BASE_URL),
  EscalateAlerts: new EscalateAlertsApi(config, BASE_URL),
  SuppressedAlerts: new SuppressedAlertsApi(config, BASE_URL),
  SLA: new SlaApi(config, BASE_URL),
};

export const CloudAccountService = {
  CloudAccount: new CloudAccountApi(config, BASE_URL),
  Template: new TemplateApi(config, BASE_URL),
  CodeRepository: new CodeRepositoryApi(config, BASE_URL),
};

export const InventoryService = {
  ResourceSummary: new ResourceSummaryApi(config, BASE_URL),
  Resource: new ResourceApi(config, BASE_URL),
  Tags: new TagsApi(config, BASE_URL),
  Insight: new InsightApi(config, BASE_URL),
  Relationship: new RelationshipApi(config, BASE_URL),
  InsightV2: new InsightV2Api(config, BASE_URL),
  AllowList: new AllowlistApi(config, BASE_URL),
  Map: new MapApi(config, BASE_URL),
  SearchServiceV2: new SearchV2Api(config, BASE_URL),
  SearchService: new SearchApi(config, BASE_URL),
  Search: new SearchApi(config, BASE_URL),
  Vulnerability: new VulnerabilitiesApi(config, BASE_URL),
  Context: new ContextApi(config, BASE_URL),
  Iac: new IACApi(config, BASE_URL),
  AudittrailApi: new InventoryAudittrailApi(config, BASE_URL),
  Config: new ConfigApi(config, BASE_URL),
  CloudInsight: new CloudInsightApi(config, BASE_URL),
  Ebpf: new EbpfApi(config, BASE_URL),
  Dashboard: new DashboardApi(config, BASE_URL),
};

export const ComplianceService = {
  Compliance: new ComplianceApi(config, BASE_URL),
  ComplianceStandards: new ComplianceStandardsApi(config, BASE_URL),
  Blueprint: new BlueprintApi(config, BASE_URL),
  Environment: new EnvironmentApi(config, BASE_URL),
  Remediation: new RemediationApi(config, BASE_URL),
  RemediationCommands: new CommandsApi(config, BASE_URL),
  Checklist: new ChecklistApi(config, BASE_URL),
  Rules: new RulesApi(config, BASE_URL),
  Reports: new ReportApi(config, BASE_URL),
  AuditTrail: new AudittrailApi(config, BASE_URL),
  Permissions: new PermissionsApi(config, BASE_URL),
};

export const DataScanningService = {
  FileData: new FileDataApi(new TokenConfig(), BASE_URL),
  //Snowflake: new SnowflakeApi(new TokenConfig(), BASE_URL),
  DataTypes: new DataTypesApi(new TokenConfig(), BASE_URL),
  DataExclusions: new DataExclusionsApi(new TokenConfig(), BASE_URL),
  DataClassification: new DataClassificationApi(new TokenConfig(), BASE_URL),
  Visibility: new VisibilityDataApi(new TokenConfig(), BASE_URL),
  Overview: new OverviewApi(new TokenConfig(), BASE_URL),
  Resources: new ResourcesApi(new TokenConfig(), BASE_URL),
  SensitiveData: new SensitiveDataApi(new TokenConfig(), BASE_URL),
};

export const AIEngineService = {
  AIParamExtraction: new AIBasedAPISelectionParameterExtractionApi(
    new TokenConfig(),
    BASE_URL,
  ),
  AriksaAI: new AriksaAIApi(config, BASE_URL),
  AIAgent: new AIAgentsApi(config, BASE_URL),
};

export const ScanAnalysis = {
  Item: new ItemApi(config, BASE_URL),
  Default: new DefaultApi(config, BASE_URL),
};

export const ReportingService = {
  Reports: new ReportsApi(config, BASE_URL),
  Charts: new ChartsApi(config, BASE_URL),
  Inventory: new ReportingInventoryApi(config, BASE_URL),
  Vulnerability: new ReportingVulnerabilitiesApi(config, BASE_URL),
};

export const AuditTrailApiService = {
  AuditTrail: new AuditTrailServiceApi(config, BASE_URL),
};

export const ProfileService = {
  QueryFilter: new QueryFilterApi(new TokenConfig(), BASE_URL),
  FAQ: new FaqApi(new TokenConfig(), BASE_URL),
  Journey: new JourneyApi(new TokenConfig(), BASE_URL),
};

export const NetworkAssessmentService = {
  Assessment: new AssessmentApi(new TokenConfig(), BASE_URL),
  Network: new NetworkApi(new TokenConfig(), BASE_URL),
};

export const NetworkAssessmentNetworkServiceApi = new NetworkApi(
  new TokenConfig(),
  BASE_URL,
);
export const NetworkAssessmentServiceApi = new AssessmentApi(
  new TokenConfig(),
  BASE_URL,
);

//from @ariksa/event-log
export const EventLogService = new EventLogApi(new TokenConfig(), BASE_URL);

//from @ariksa/awm
export const WorkflowService = new WorkflowApi(new TokenConfig(), BASE_URL);

export function authTokenHeader() {
  const access_token = sessionStorage.getItem('authentication');
  return {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
}
