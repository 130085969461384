import { AIBasedAPISelectionParameterExtractionApiExtractParametersRequest } from '@ariksa/ai-engine';
import { AIAgentsApiGetResourceSummaryRequest } from '@ariksa/ai-engine/api';
import { WorkflowApiGetLatestRequest, WorkflowRead } from '@ariksa/awm/api';
import {
  BlueprintApiAddExceptionRequest,
  BlueprintApiGetBlueprintsRequest,
  BlueprintExceptionRead,
  PageBluePrintsRead,
} from '@ariksa/compliance-policies/api';
import { FileDataApiGetScannersRequest } from '@ariksa/data-scanning/api';
import {
  TagsApiGetTagKeysRequest,
  TagsApiGetTagValueRequest,
} from '@ariksa/inventory-core';
import {
  ContextApiGetRiskContextResRequest,
  InsightV2ApiInsightV2AllRequest,
  InsightV2Pagination,
  LabelResponse,
  ResourceApiGetRegionsRequest,
  RiskContextResponse,
  TagsApiGetLabelsRequest,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertResourceResponse,
  AlertsApiGetResourceDetailsRequest,
  AlertWorkflowResponse,
  ClientsApiGetClientDetailsRequest,
  ClientsApiNotifyClientRequest,
} from '@ariksa/notification/api';
import {
  FaqApiGetAllQuestionsWithAnswersRequest,
  FaqRead,
  FeatureRead,
} from '@ariksa/profile/api';
import { ReportsApiSendToEsRequest } from '@ariksa/reporting';
import { ScannerInfo } from '@ariksa/scan-analysis/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  AIEngineService,
  ComplianceService,
  DataScanningService,
  InventoryService,
  NotificationService,
  ProfileService,
  ReportingService,
  ScanAnalysis,
  serviceClient,
  WorkflowService,
} from 'app/api';
import { TagValuesResponse } from 'containers/SharedState/types';

import { actions } from './slice';

export function* sharedStateSaga() {
  yield takeLatestAction(actions.getRegions, doGetRegions);
  yield takeLatestAction(actions.getTagKeys, doGetTagKeys);
  yield takeLatestAction(actions.getTagValues, doGetTagValues);
  yield takeLatestAction(actions.getLastDiscoveryTime, doGetLastDiscoveryTime);
  yield takeLatestAction(actions.notifyClient, doNotifyClient);
  yield takeLatestAction(actions.getFAQ, doGetFAQ);
  yield takeLatestAction(actions.getLabels, doGetLabels);
  yield takeLatestAction(actions.getInsightV2All, doGetInsightV2All);
  yield takeLatestAction(actions.getRiskContext, doGetRiskContext);
  yield takeLatestAction(actions.getBlueprints, doGetBlueprints);
  yield takeLatestAction(
    actions.exportAlertsToElasticSearch,
    doExportAlertsToElasticSearch,
  );
  yield takeLatestAction(
    actions.getAlertResourceTypes,
    doGetAlertResourceTypes,
  );
  yield takeLatest(actions.getClients.type, doGetClients);
  yield takeLatestAction(actions.getWorkflows, doGetWorkflows);
  yield takeLatestAction(actions.addException, doAddException);
  yield takeLatestAction(
    actions.extractParametersForSearch,
    doExtractParametersForSearch,
  );
  yield takeLatestAction(
    actions.getSnapshotStatus,
    doGetCloudAccountSnapshotStatus,
  );
  yield takeLatestAction(actions.getAlertListStatus, doGetAlertListStatus);
  yield takeLatestAction(actions.getMyTasks, doGetMyTasks);
  yield takeLatestAction(actions.getAIResourceSummary, doGetAIResourceSummary);
  yield takeLatestAction(actions.getDataScannerInfo, doGetDataScannerInfo);
  yield takeLatestAction(
    actions.getVulnerabilityScannerInfo,
    doGetVulnerabilityScannerInfo,
  );
}

/*get regions*/
function* doGetRegions(
  ctx: QueryContext<string[], ResourceApiGetRegionsRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getRegions(ctx.params),
    {
      map: r => r?.data?.regions,
      errorMsg: 'Failed to get regions.',
    },
  );
}

/*get tag keys*/
function* doGetTagKeys(ctx: QueryContext<string[], TagsApiGetTagKeysRequest>) {
  yield call(ctx.fetch, () => InventoryService.Tags.getTagKeys(ctx.params), {
    errorMsg: 'Failed to get tag keys.',
  });
}

/*get tag values*/
function* doGetTagValues(
  ctx: QueryContext<TagValuesResponse, TagsApiGetTagValueRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.getTagValue(ctx.params), {
    map: r => ({ key: ctx.params.keys, values: r?.data }),
    errorMsg: 'Failed to get tag values.',
  });
}

export function* doGetLastDiscoveryTime(
  ctx: QueryContext<WorkflowRead[], WorkflowApiGetLatestRequest>,
) {
  yield call(ctx.fetch, () => WorkflowService.getLatest(ctx.params), {
    errorMsg: 'Failed to get last refreshed time.',
  });
}

function* doNotifyClient(
  ctx: QueryContext<any, ClientsApiNotifyClientRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.notifyClient(ctx.params),
    { errorMsg: 'Failed to notify client.' },
  );
}

function* doGetFAQ(
  ctx: QueryContext<FaqRead[], FaqApiGetAllQuestionsWithAnswersRequest>,
) {
  yield call(
    ctx.fetch,
    () => ProfileService.FAQ.getAllQuestionsWithAnswers(ctx.params),
    { errorMsg: 'Failed to get question and answers.' },
  );
}

export function* doGetLabels(
  ctx: QueryContext<LabelResponse[], TagsApiGetLabelsRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.getLabels(ctx.params), {
    errorMsg: 'Failed to get labels!',
  });
}

export function* doGetInsightV2All(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2AllRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.InsightV2.insightV2All(ctx.params),
    {
      errorMsg: 'Failed to get data!',
    },
  );
}

export function* doGetRiskContext(
  ctx: QueryContext<
    Record<string, RiskContextResponse[]>,
    ContextApiGetRiskContextResRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getRiskContextRes(ctx.params),
    {
      errorMsg: 'Failed to get risk context!',
    },
  );
}

export function* doGetAlertResourceTypes(
  ctx: QueryContext<AlertResourceResponse, AlertsApiGetResourceDetailsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getResourceDetails(ctx.params),
    {
      errorMsg: 'Failed to get resource types!',
    },
  );
}

//get blueprints
export function* doGetBlueprints(
  ctx: QueryContext<PageBluePrintsRead, BlueprintApiGetBlueprintsRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.getBlueprints(ctx.params),
    { errorMsg: 'Failed to get blueprints!' },
  );
}

//export Alerts
export function* doExportAlertsToElasticSearch(
  ctx: QueryContext<any, ReportsApiSendToEsRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ReportingService.Reports.sendToEs(ctx.params),
    {
      errorMsg: 'Failed to export alerts to Elastic Search.',
      successMsg: 'Successfully sent alerts to Elastic Search.',
    },
  );
}

export function* doGetClients(
  action: PayloadAction<ClientsApiGetClientDetailsRequest>,
) {
  yield call(serviceClient, {
    fn: async () =>
      NotificationService.Clients.getClientDetails(action.payload),
    onSuccess: actions.clientsLoaded,
    onError: actions.clientsLoadingError,
    mapResponse: res => ({
      clientName: action.payload.clientName,
      data: res?.data,
    }),
    errorTitle: 'Failed to get clients!',
  });
}

/*get alert workflows*/
function* doGetWorkflows(ctx: QueryContext<AlertWorkflowResponse[]>) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertWorkflow.getWorkflow({}),
    { errorMsg: 'Failed to get workflows!' },
  );
}

/*add exception*/
function* doAddException(
  ctx: QueryContext<BlueprintExceptionRead, BlueprintApiAddExceptionRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.addException(ctx.params),
    { errorMsg: 'Failed to add exception!' },
  );
}

/*extract parameters for search*/
function* doExtractParametersForSearch(
  ctx: QueryContext<
    any,
    AIBasedAPISelectionParameterExtractionApiExtractParametersRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => AIEngineService.AIParamExtraction.extractParameters(ctx.params),
    { errorMsg: 'Failed to extract parameters for search!' },
  );
}

export function* doGetCloudAccountSnapshotStatus(
  ctx: QueryContext<WorkflowRead[], WorkflowApiGetLatestRequest>,
) {
  yield call(ctx.fetch, () => WorkflowService.getLatest(ctx.params), {
    errorMsg: 'Failed to get last discovery time!',
  });
}

//get alert workflow status (for ex. for slack, jira tickets etc.)
function* doGetAlertListStatus(
  ctx: QueryContext<AlertLogsResponse[], AlertLogsApiGetLogsForIdsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertsLogs.getLogsForIds(ctx.params),
    { errorMsg: 'Failed to get alert status!' },
  );
}

//get alert workflow status (for ex. for slack, jira tickets etc.)
function* doGetMyTasks(ctx: QueryContext<FeatureRead[]>) {
  yield call(ctx.fetch, () => ProfileService.Journey.getAllFeatures(), {
    errorMsg: 'Failed to get my tasks!',
    cacheKey: 'myTasks',
  });
}

function* doGetAIResourceSummary(
  ctx: QueryContext<any, AIAgentsApiGetResourceSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => AIEngineService.AIAgent.getResourceSummary(ctx.params),
    {
      errorMsg: 'Failed to get resource summary!',
    },
  );
}

export function* doGetDataScannerInfo(
  ctx: QueryContext<Record<string, any>, FileDataApiGetScannersRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.FileData.getScanners(ctx.params),
    {
      errorMsg: 'Failed to get data scanner info!',
      cacheKey: 'dataScannerInfo',
    },
  );
}

export function* doGetVulnerabilityScannerInfo(
  ctx: QueryContext<Record<string, ScannerInfo[]>>,
) {
  yield call(ctx.fetch, () => ScanAnalysis.Item.getVulnerabilityScanners(), {
    errorMsg: 'Failed to get data scanner info!',
    cacheKey: 'vulnerabilityScannerInfo',
  });
}
