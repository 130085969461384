import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { DataFootprintBySourceTypesComponent } from 'containers/Dashboard/utils/DataFootprintBySourceTypesComponent';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const DataFootprintBySourceTypes: FC = () => {
  const { dataSourcesPieChart } = useSelector(selectDataDashboard);

  return (
    <DataFootprintBySourceTypesComponent
      dataSources={dataSourcesPieChart.data}
      isLoading={dataSourcesPieChart.isLoading}
    />
  );
};
