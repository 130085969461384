import React, { useState } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { map, split } from 'lodash';

import { H2, H4, Tag, WithResourceIcon } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { DownIcon, IconTypes, RightIcon } from 'components/Icons';
import { useNavBarContext } from 'components/Navigation/NavBar/context';
import {
  defaultStyles,
  linkStyles,
} from 'components/Navigation/NavBar/TopNavBar/RightComponent/FAQ/styles';
import { useFAQ } from 'components/Navigation/NavBar/TopNavBar/RightComponent/FAQ/useFAQ';
import { Drawer } from 'components/Overlay';

interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const AriksaFAQ = (props: Props) => {
  const { isOpen, onClose } = props;
  const { faq } = useNavBarContext();
  const { isLoading, searchTerm, setSearchTerm } = faq;
  const { keywords, links, onClickLink } = useFAQ({ onClose });
  const [showKeywords, setShowKeywords] = useState(false);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      styles={{ drawer: { size: 'xl' } }}
      closeButton
      header={
        <WithResourceIcon
          resourceType={IconTypes.QuestionMark}
          bgColor="primary"
          iconSize="medium"
        >
          <H2>Questions? Look here.</H2>
        </WithResourceIcon>
      }
      body={
        <Stack spacing={5}>
          <Box>
            <SearchBox
              value={searchTerm}
              onClick={() => setShowKeywords(true)}
              onChange={value => {
                setShowKeywords(false);
                setSearchTerm(value);
              }}
              placeholder="Search..."
            />
          </Box>
          {isLoading && (
            <Center w="full" h="full">
              <CustomSpinner loading={isLoading} />
            </Center>
          )}
          {showKeywords && (
            <>
              <Box>
                {map(keywords, o => (
                  <Tag
                    label={o}
                    onClick={() => {
                      setSearchTerm(o);
                      setShowKeywords(false);
                    }}
                    styles={{ tag: { mr: 1, mb: 1, cursor: 'pointer' } }}
                  />
                ))}
              </Box>
              <Divider />
            </>
          )}
          {!isLoading && (
            <Accordion allowMultiple pl={1}>
              {map(faq.data, (o, index) => (
                <Box pb={6}>
                  <AccordionItem {...defaultStyles.item}>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton {...defaultStyles.button}>
                          <HStack spacing={3}>
                            <Box color="primary">
                              {isExpanded ? <DownIcon /> : <RightIcon />}
                            </Box>
                            <H4 textAlign="left">{o?.question}</H4>
                          </HStack>
                        </AccordionButton>
                        <AccordionPanel py={0} pr={0} pl={8}>
                          <Stack spacing={3}>
                            <Stack>
                              {map(split(o?.answer, '\n'), ans => (
                                <Box>{ans}</Box>
                              ))}
                            </Stack>
                            <Stack spacing={1}>
                              {map(o?.link_keywords, k => (
                                <Box
                                  onClick={() => onClickLink(links[k]?.url)}
                                  {...linkStyles}
                                >
                                  {links[k]?.label}
                                </Box>
                              ))}
                            </Stack>
                          </Stack>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Box>
              ))}
            </Accordion>
          )}
        </Stack>
      }
    />
  );
};
