import React, { ReactNode } from 'react';

import { CenterProps, Flex, HStack, Box } from '@chakra-ui/react';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { AriksaIcon } from 'components/Icons/Components';
import { IconSize, IconType } from 'components/Icons/types';

interface Props {
  resourceType: IconType;
  bgColor?: string;
  color?: string;
  children: ReactNode;
  iconSize?: IconSize;
  iconFilled?: boolean;
  iconTooltip?: ReactNode;
  spacing?: number;
  iconStyles?: CenterProps;
  tooltip?: ReactNode;
  useCustomColor?: boolean;
}

export const WithResourceIcon = (props: Props) => {
  const {
    resourceType,
    children,
    bgColor,
    iconSize = 'sm',
    iconTooltip,
    spacing = 4,
    iconStyles,
    tooltip,
    ...rest
  } = props;

  const renderIcon = () => (
    <AriksaIcon
      type={resourceType}
      bg={bgColor}
      size={iconSize}
      style={iconStyles}
      {...rest}
    />
  );

  const renderChildren = () => (
    <Box w="full" overflow="hidden" textOverflow="ellipsis">
      {children}
    </Box>
  );

  return (
    <HStack spacing={spacing} w="full">
      <Flex>
        {iconTooltip ? (
          <CustomTooltip label={iconTooltip}>{renderIcon()}</CustomTooltip>
        ) : (
          renderIcon()
        )}
      </Flex>
      {tooltip ? (
        <CustomTooltip label={tooltip}>{renderChildren()}</CustomTooltip>
      ) : (
        renderChildren()
      )}
    </HStack>
  );
};
