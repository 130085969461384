import React, { ReactNode } from 'react';

import { Box, Center, HStack, Stack, useDisclosure } from '@chakra-ui/react';

import { IdeaGlitterIcon, RightArrowIcon } from 'components/Icons';
import { Popover } from 'components/Overlay';

export interface DashboardOverviewTooltipProps {
  tooltip?: ReactNode;
  header?: string;
  footerText?: string;
  onClickFooter?();
  onClickButton?();
  icon?: ReactNode;
  button?: ReactNode;
  showArrow?: boolean;
}

export const DashboardOverviewTooltip: React.FC<DashboardOverviewTooltipProps> = props => {
  const {
    tooltip,
    header,
    footerText,
    onClickFooter,
    icon,
    button,
    onClickButton,
    showArrow = true,
  } = props;
  const show = useDisclosure();
  return (
    <Popover
      isOpen={show.isOpen}
      onClose={show.onClose}
      closeButton={false}
      portal
      placement="auto"
      button={
        <Box
          onMouseOver={() => show.onOpen()}
          onMouseOut={() => show.onClose()}
          onClick={() => {
            if (!!onClickButton) {
              //show.onClose();
              onClickButton();
            } else show.onOpen();
          }}
        >
          {button ?? (
            <Box boxSize={5} color="primary">
              <Center>
                <IdeaGlitterIcon />
              </Center>
            </Box>
          )}
        </Box>
      }
      body={
        <Stack spacing={4}>
          <HStack color="primary" fontSize="17px" spacing={4}>
            <Box boxSize={10}>
              <Center w={8} h={8}>
                {icon || <IdeaGlitterIcon />}
              </Center>
            </Box>
            <Box>{header}</Box>
          </HStack>
          <Box borderBottom="1px solid" borderColor="primary" />
          <Box color="#000" pt={2} fontSize="sm">
            {tooltip}
          </Box>
          {showArrow && (
            <HStack
              w="full"
              justify="flex-end"
              pt={3}
              color="primary"
              onClick={() => onClickFooter?.() || show.onClose()}
            >
              <Box _hover={{ cursor: 'pointer' }}>{footerText}</Box>
              <Box
                boxSize={4}
                border="1px solid"
                borderColor="primary"
                borderRadius="full"
                p="1px"
              >
                <RightArrowIcon />
              </Box>
            </HStack>
          )}
        </Stack>
      }
      styles={{
        content: {
          w: 72,
          px: 4,
          py: 3,
          borderColor: 'primary',
          onMouseOver: () => show.onOpen(),
          onMouseOut: () => show.onClose(),
        },
      }}
      arrow={false}
    />
  );
};
