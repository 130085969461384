import React from 'react';

import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { isNumber, isString, map, round, truncate } from 'lodash';
import { customTheme } from 'theme';

import formatNumber, {
  chartNumberFormatter,
} from 'components/DataDisplay/Utils/formatNumber';

import { limitedString } from '../../../../utils/string';
import { CustomTooltip } from 'components/Visualization/Charts/CustomTooltip';
const colors = customTheme.colors;
export const LIGHT_COLORS = [
  colors.blue['200'],
  colors.purple['200'],
  colors.skyBlue['200'],
  colors.green['200'],
  colors.red['200'],
  colors.yellow['200'],
];

export const DARK_COLORS = [
  colors.blue['300'],
  colors.purple['300'],
  colors.skyBlue['300'],
  colors.green['300'],
  colors.red['300'],
  colors.yellow['300'],
];

export const customizedXAxisTick = props => {
  const { x, y, payload, style, sliceLength = 8 } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        fill="#666"
        textAnchor="middle"
        fontSize={14}
        fontWeight={600}
        {...style}
      >
        {isString(payload.value)
          ? limitedString(payload.value, sliceLength)
          : payload.value}
      </text>
    </g>
  );
};

export const rotatedXAxisTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={-15}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-38)"
        fontSize="11px"
      >
        {payload.value}
      </text>
    </g>
  );
};

export const customizedYAxisTick = (props, divideBy: number = 0) => {
  const { x, y, payload, style } = props;
  return (
    <g transform={`translate(${x},${y + 2})`}>
      <text
        x={0}
        fill="#666"
        alignmentBaseline="middle"
        textAnchor="end"
        fontSize={14}
        {...style}
      >
        {!!divideBy
          ? round(payload.value / divideBy)
          : isNumber(payload.value)
          ? chartNumberFormatter(payload.value, 2)
          : payload.value}
      </text>
    </g>
  );
};

export const customizedYAxisTickWithFormatNumber = props => {
  const { x, y, payload, style } = props;
  return (
    <g transform={`translate(${x},${y + 2})`}>
      <text
        x={0}
        fill="#666"
        alignmentBaseline="middle"
        textAnchor="end"
        fontSize={14}
        {...style}
      >
        {formatNumber(payload.value)}
      </text>
    </g>
  );
};

export const customizedRightYAxisTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y + 4})`}>
      <text fill="#666" fontSize={12}>
        {payload.value}
      </text>
    </g>
  );
};

export const customizedLegend = (value, entry, index) => {
  return (
    <span style={{ fontSize: '14px', color: '#666' }}>
      {truncate(value, { length: 20 })}
    </span>
  );
};

export const customLegends = props => {
  return (
    <Stack
      h="full"
      justify="center"
      w={props?.width}
      fontSize={props?.fontSize ?? 'xs'}
    >
      {map(props.payload, o => (
        <HStack
          onClick={() => props?.onClick(o)}
          cursor={!!props?.onClick ? 'pointer' : 'default'}
        >
          <Box boxSize={2.5} bg={o.color} borderRadius={2} />
          <Box flex={1}>
            {o?.value} ({formatNumber(o?.payload?.value, 1)})
          </Box>
        </HStack>
      ))}
    </Stack>
  );
};

/*export const renderLegend = props => {
  const { payload } = props;

  return (
    <Flex overflow="auto" pos="relative" height="fit-content">
      <Stack spacing={0} overflow="auto" h="full">
        {map(payload, (entry, index) => (
          <Box key={`legend-${index}`}>
            {startCase(truncate(entry.value, { length: 20 }))}
          </Box>
        ))}
        {map(payload, (entry, index) => (
          <Box key={`legend-${index}`}>
            {startCase(truncate(entry.value, { length: 20 }))}
          </Box>
        ))}
      </Stack>
    </Flex>
  );
};*/

export const customChartTooltip = props => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    const { payload: obj } = payload[0] ?? {};

    return (
      <>
        <CustomTooltip>
          <Stack fontSize={'sm'}>
            <Text as="b">{obj.name}</Text>
            <Text>
              Number of data sources:{' '}
              <Text as="b">{obj['Number of Data Sources']}</Text>
            </Text>
            <Text>
              Sensitive data count:{' '}
              <Text as="b">{obj['Sensitive data count']}</Text>
            </Text>
          </Stack>
        </CustomTooltip>
      </>
    );
  }

  return null;
};
