import React from 'react';

import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { BucketsCard } from 'containers/Visibility/Data/Components/Dashboard/Cards/BucketsCard';
import { DatabasesCard } from 'containers/Visibility/Data/Components/Dashboard/Cards/DatabasesCard';
import { DisksCard } from 'containers/Visibility/Data/Components/Dashboard/Cards/DisksCard';
import { SaaSCard } from 'containers/Visibility/Data/Components/Dashboard/Cards/SaaSCard';
import { DataFootprintBySourceTypes } from 'containers/Visibility/Data/Components/Dashboard/Charts/DataFootprintBySourceTypes';
import { SensitiveDataByRegionPieChart } from 'containers/Visibility/Data/Components/Dashboard/Charts/SensitiveDataByRegionPieChart';
import { DataFootprintByRegion } from 'containers/Visibility/Data/Components/Dashboard/Tables/DataFootprintByRegion';
import { DataSourcesByAccounts } from 'containers/Visibility/Data/Components/Dashboard/Tables/DataSourcesByAccounts';
import { DocumentsByTypes } from 'containers/Visibility/Data/Components/Dashboard/Tables/DocumentsByTypes';
import { HighRiskDataSourcesWithSensitiveData } from 'containers/Visibility/Data/Components/Dashboard/Tables/HighRiskDataSourcesWithSensitiveData';
import { RuntimeInsightsForDataSources } from 'containers/Visibility/Data/Components/Dashboard/Tables/RuntimeInsightsForDataSources';
import { SensitiveDataByType } from 'containers/Visibility/Data/Components/Dashboard/Tables/SensitiveDataByType';

export const availableWidgets1: { [key: string]: WidgetProps } = {
  buckets: {
    flex: true,
    components: {
      content: <BucketsCard />,
    },
  },
  databases: {
    flex: true,
    components: {
      content: <DatabasesCard />,
    },
  },
  disks: {
    flex: true,
    components: {
      content: <DisksCard />,
    },
  },
  saas: {
    flex: true,
    components: {
      content: <SaaSCard />,
    },
  },
  highRiskDataSourcesWithSensitiveData: {
    flex: true,
    components: {
      content: <HighRiskDataSourcesWithSensitiveData />,
    },
  },
  runtimeInsightsForDataSources: {
    flex: true,
    components: {
      content: <RuntimeInsightsForDataSources />,
    },
  },
  dataSourcesByAccounts: {
    flex: true,
    components: {
      content: <DataSourcesByAccounts />,
    },
  },
  dataFootprintByRegion: {
    flex: true,
    components: {
      content: <DataFootprintByRegion />,
    },
  },
  dataFootprintBySourceTypes: {
    flex: true,
    components: {
      content: <DataFootprintBySourceTypes />,
    },
  },
  documentsByTypes: {
    flex: true,
    components: {
      content: <DocumentsByTypes />,
    },
  },
  sensitiveDataByType: {
    flex: true,
    components: {
      content: <SensitiveDataByType />,
    },
  },
  sensitiveDataByRegion: {
    flex: true,
    components: {
      content: <SensitiveDataByRegionPieChart />,
    },
  },
};
