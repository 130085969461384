/**
 *
 * Jira
 *
 */

import React, { memo, useCallback, useEffect, useReducer } from 'react';

import { Clients } from '@ariksa/notification';
import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { map, includes, each } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { QuestionCircleIcon } from 'app/components';
import { Form, FormSchema } from 'app/components/DataEntry/Form';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { actions } from 'containers/Setup/Integrations/slice';

import { formStyles } from '../../../styles';

import { JIRAHelp } from './JIRAHelp';

interface Props {
  handleSubmit: (data: any) => void;
}

export const JiraForm = memo((props: Props) => {
  const { handleSubmit } = props;

  const { jiraProjectDetails, client, clients, validateClient } = useSelector(
    selectIntegrations,
  );
  const { actionType, onCancel, currentRecord } = useIntegrationsContext();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      projects: [],
      users: [],
      issueTypeOptions: [],
      selectedProject: {},
      selectedUser: {},
      issueType: {},
      isEdit: false,
      isHelpOpen: false,
      name: '',
      user: '',
      url: '',
      nameError: '',
      names: [],
      validateProjects: false,
      validateToken: false,
      validateClient: false,
      showValidatedMessage: false,
    },
  );
  const dispatch = useDispatch();

  const toggleIsHelpOpen = () => updateState({ isHelpOpen: !state.isHelpOpen });

  const getJiraProjects = useCallback(
    (user, url, token) => {
      dispatch(
        actions.loadJiraProjectDetails({
          q: {
            jiraClientRequest: {
              user,
              url,
              token,
            },
          },
          onSuccess: () => updateState({ validateProjects: true }),
          onError: () => updateState({ validateProjects: false }),
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    let names: string[] = [];
    each(clients.jira, o => names.push(o.name));
    updateState({ names });
  }, [clients.jira]);

  //set isEdit true if opened in edit
  useEffect(() => {
    updateState({ isEdit: actionType === 'Update' });
  }, [actionType]);

  //set fields
  useEffect(() => {
    if (state.isEdit)
      updateState({
        name: currentRecord?.name,
        user: currentRecord?.jira?.user,
        url: currentRecord?.jira?.url,
        validateToken: true,
        validateProjects: true,
        validateClient: true,
        showValidatedMessage: true,
        selectedProject: {
          label: currentRecord.jira?.project_name,
          value: currentRecord.jira?.project_id,
        },
        selectedUser: {
          label: currentRecord.jira?.user,
          value: currentRecord.jira?.account_id,
        },
        issueType: {
          label: currentRecord?.jira?.default_issue_type,
          value: currentRecord?.jira?.default_issue_type,
        },
      });
  }, [currentRecord, state.isEdit]);

  //set list of projects and users
  useEffect(() => {
    if (!state.isEdit) {
      const clientInfoData = jiraProjectDetails.data;
      const projects = map(clientInfoData?.projects, o => ({
        label: o.name,
        value: o.id,
        data: o,
      }));
      const users = map(
        projects?.[0]?.data?.users as Record<string, any>[],
        o => ({
          value: o?.accountId,
          label: o?.displayName,
        }),
      );
      const issueTypeOptions = map(projects?.[0]?.data?.issue_types, o => ({
        label: o,
        value: o,
      }));
      updateState({
        projects,
        users,
        issueTypeOptions,
        selectedProject: projects?.[0],
        selectedUser: users?.[0],
        issueType: issueTypeOptions?.[0],
      });
    }
  }, [jiraProjectDetails.data, state.isEdit]);

  //set list of projects and users
  useEffect(() => {
    if (!state.isEdit) {
      const users = map(
        state.selectedProject?.data?.users as Record<string, any>[],
        o => ({
          value: o?.accountId,
          label: o?.displayName,
        }),
      );
      const issueTypeOptions = map(
        state.selectedProject?.data?.issue_types,
        o => ({
          label: o,
          value: o,
        }),
      );
      updateState({
        users,
        issueTypeOptions,
        selectedUser: users?.[0],
        issueType: issueTypeOptions?.[0],
      });
    }
  }, [state.selectedProject, state.isEdit]);

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
    url: yup.string().url('Url is not valid!'),
    user: yup.string().email('Email is not valid!'),
  });

  const validateJiraClient = data => {
    const { name, user, url, token, project_id, account_id, issue_type } = data;
    dispatch(
      actions.validateClient({
        q: {
          clientConfig: {
            client_name: Clients.Jira,
            name,
            status: true,
            jira: {
              user,
              url,
              token,
              project_id: project_id.value,
              project_name: project_id.label,
              account_id: account_id.value,
              default_issue_type: issue_type?.value,
            },
          },
        },
        onSuccess: () =>
          updateState({ validateClient: true, showValidatedMessage: true }),
        onError: () =>
          updateState({ validateClient: false, showValidatedMessage: true }),
      }),
    );
  };

  const restFields: FormSchema =
    state.validateProjects || state.isEdit
      ? {
          project_id: {
            type: 'react-select',
            label: 'Project',
            isRequired: true,
            value: state.isEdit
              ? {
                  label: currentRecord.jira?.project_name,
                  value: currentRecord.jira?.project_id,
                }
              : state.selectedProject,
            onChange: v =>
              updateState({
                selectedProject: v,
                validateClient: false,
                showValidatedMessage: false,
              }),
            options: state.projects,
            isLoading: jiraProjectDetails.isLoading,
            isDisabled:
              jiraProjectDetails.isLoading || client.isLoading || state.isEdit,
          },
          account_id: {
            type: 'react-select',
            isRequired: true,
            label: 'Default assignee',
            value: state.isEdit
              ? {
                  label: currentRecord.jira?.user,
                  value: currentRecord.jira?.account_id,
                }
              : state.selectedUser,
            onChange: v =>
              updateState({
                selectedUser: v,
                validateClient: false,
                showValidatedMessage: false,
              }),
            options: state.users,
            isLoading: jiraProjectDetails.isLoading,
            isDisabled:
              jiraProjectDetails.isLoading || client.isLoading || state.isEdit,
          },
          issue_type: {
            type: 'react-select',
            isRequired: true,
            label: 'Default issue type',
            value: state.isEdit
              ? {
                  label: currentRecord?.jira?.default_issue_type,
                  value: currentRecord?.jira?.default_issue_type,
                }
              : state.issueType,
            onChange: v => updateState({ issueType: v, validateClient: false }),
            options: state.issueTypeOptions,
            isLoading: jiraProjectDetails.isLoading,
            isDisabled:
              jiraProjectDetails.isLoading || client.isLoading || state.isEdit,
          },
        }
      : {};

  const token: FormSchema = state.isEdit
    ? {}
    : {
        token: {
          htmlInputType: 'password',
          passwordShow: true,
          type: 'text',
          label: 'Api Token',
          isRequired: true,
          onChange: v =>
            updateState({
              validateToken: !!v,
              validateProjects: false,
              showValidatedMessage: false,
            }),
          helpIcon: (
            <Box
              onClick={toggleIsHelpOpen}
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
          isHelpOpen: state.isHelpOpen,
          helpComponent: (
            <JIRAHelp onClose={() => updateState({ isHelpOpen: false })} />
          ),
        },
      };

  return (
    <Form
      formValidation={schemaForm}
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          value: state?.name,
          onChange: value => {
            const flag = includes(state.names, value);
            let err = '';
            if (!value.match(/^[a-zA-Z0-9-]*$/i))
              err = 'Only Alphanumeric, hyphen is allowed!';
            else if (flag) err = 'Name already exists';
            updateState({
              name: value,
              nameError: err,
            });
          },
          error: state.nameError,
          tooltip: 'Only alphanumeric, hyphen, underscore and space is allowed',
          isDisabled: jiraProjectDetails.isLoading || client.isLoading,
        },
        url: {
          type: 'text',
          label: 'URL',
          placeholder: 'https://example.atlassian.net/',
          isRequired: true,
          value: state?.url,
          onChange: v =>
            updateState({
              url: v,
              validateProjects: false,
              showValidatedMessage: false,
            }),
          isDisabled: state.isEdit,
          tooltip: 'Enter valid url',
        },
        user: {
          type: 'text',
          label: 'User ID',
          placeholder: 'example@mail.com',
          isRequired: true,
          value: state?.user,
          onChange: v =>
            updateState({
              user: v,
              validateProjects: false,
              showValidatedMessage: false,
            }),
          isDisabled: state.isEdit,
          tooltip: 'Enter valid email',
        },
        ...token,
        ...restFields,
        ...(state.showValidatedMessage && !state.isEdit
          ? {
              validatedMessage: {
                type: 'custom',
                component: () => {
                  const err = validateClient.error?.title;
                  const success = !err;
                  return (
                    <Box w="full">
                      <Alert
                        status={success ? 'success' : 'error'}
                        borderRadius={6}
                        title={success ? `` : validateClient.error?.title}
                      >
                        <AlertIcon />
                        {success
                          ? `All fields are valid. Please click on "Add Client" to proceed.`
                          : validateClient.error?.description}
                      </Alert>
                    </Box>
                  );
                },
              },
            }
          : {}),
      }}
      buttonOptions={{
        submit: {
          isVisible: true,
          isLoading:
            jiraProjectDetails.isLoading ||
            client.isLoading ||
            validateClient.isLoading,
          name: state.isEdit
            ? 'Update Client'
            : !state.validateProjects
            ? 'Get Projects & Users'
            : !state.validateClient
            ? 'Validate'
            : 'Add Client',
          isDisabled:
            (state.validateProjects &&
              !state.isEdit &&
              (!state.issueType ||
                !state.projects.length ||
                !state.users.length)) ||
            !state.validateToken ||
            !state.name ||
            !state.user ||
            !state.url,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: jiraProjectDetails.isLoading || client.isLoading,
          onClick: () => {
            onCancel();
          },
        },
      }}
      showHelp
      styles={formStyles}
      handleSubmit={data =>
        !state.validateProjects
          ? getJiraProjects(data.user, data.url, data.token)
          : !state.validateClient
          ? validateJiraClient(data)
          : handleSubmit(data)
      }
    />
  );
});
