import React, { useEffect, FC, useState, useCallback } from 'react';

import { SupportedServices } from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack, Stack, Divider, Grid, GridItem } from '@chakra-ui/react';
import { forEach, lowerCase, map } from 'lodash';
import { useCompliancePermissionsService } from 'services/ComplianceService/useInventoryPermissionsService';
import { useDataScanningService } from 'services/DataScanning';
import { useInventoryInsightService } from 'services/Inventory/useInventoryInsightService';
import { customTheme } from 'theme';

import {
  DetailsIdentifier,
  formatBytes,
  WithResourceIcon,
} from 'components/DataDisplay';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { S3RiskAnalysisRadarChart } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/ObjectStorage/S3RiskAnalysisRadarChart';
import {
  renderRating,
  renderRiskAnalysis,
  renderSpinner,
} from 'containers/Visibility/Data/Components/DataTable/utils';
import { SENSITIVITY_CATEGORIES } from 'containers/Visibility/Data/types';

interface Props {
  row: Record<string, any>;
  isExpanded: boolean;
}

export const DataSourcesSubcomponent: FC<Props> = props => {
  const { row, isExpanded } = props;
  const { dataSourceSensitiveInfo } = useDataScanningService();
  const { canAccessEntityCount } = useInventoryInsightService();
  const { riskAnalysis } = useCompliancePermissionsService();
  const [sensitiveDataCount, setSensitiveDataCount] = useState<
    Record<string, any>
  >({});
  const [canAccessData, setCanAccessData] = useState<Record<string, any>>({});
  const [isCodeRepo, setIsCodeRepo] = useState(false);
  const canAccess = {
    Users: 'User',
    Roles: 'Role',
    Machines: 'VirtualMachines',
    'Cross-account': 'CrossAccount',
    'Third-party': 'ThirdParty',
  };

  useEffect(() => {
    setIsCodeRepo(
      row?.native_name === SupportedServices.GitLabBranch ||
        row?.native_name === SupportedServices.GitHubBranch ||
        row?.native_name === SupportedServices.BitBucketBranch ||
        row?.native_name === NativeResources.GitLabProject ||
        row?.native_name === NativeResources.GitHubRepository ||
        row?.native_name === NativeResources.BitBucketRepository,
    );
  }, [row]);

  useEffect(() => {
    isExpanded &&
      row?.resource_id &&
      dataSourceSensitiveInfo.sync({
        source: row?.resource_id,
      });
  }, [dataSourceSensitiveInfo, row, isExpanded]);

  useEffect(() => {
    isExpanded &&
      !(
        row?.native_name === SupportedServices.GitLabBranch ||
        row?.native_name === SupportedServices.GitHubBranch ||
        row?.native_name === SupportedServices.BitBucketBranch ||
        row?.native_name === NativeResources.GitLabProject ||
        row?.native_name === NativeResources.GitHubRepository ||
        row?.native_name === NativeResources.BitBucketRepository
      ) &&
      canAccessEntityCount.sync({
        uuid: row?.id,
        accountId: row?.account,
        nativeResourceType: row?.native_name,
      });
  }, [canAccessEntityCount, row, isExpanded]);

  //get risk analysis info
  useEffect(() => {
    !!row?.account &&
      !(
        row?.native_name === SupportedServices.GitLabBranch ||
        row?.native_name === SupportedServices.GitHubBranch ||
        row?.native_name === SupportedServices.BitBucketBranch ||
        row?.native_name === NativeResources.GitLabProject ||
        row?.native_name === NativeResources.GitHubRepository ||
        row?.native_name === NativeResources.BitBucketRepository
      ) &&
      isExpanded &&
      riskAnalysis.sync({
        resourceUuid: row?.id,
        accountId: row?.account,
      });
  }, [riskAnalysis, row, isCodeRepo, isExpanded]);

  useEffect(() => {
    let d: Record<string, any> = {};
    forEach(
      dataSourceSensitiveInfo.data?.sensitive_data_categories,
      o => (d[o?.category] = o),
    );
    setSensitiveDataCount(d);
  }, [dataSourceSensitiveInfo.data]);

  useEffect(() => {
    let d: Record<string, any> = {};
    forEach(canAccessEntityCount.data, o => {
      d[o.access_type] = {};
      forEach(o.entity_counts, (c, key) => (d[o.access_type][key] = c));
    });
    setCanAccessData(d);
  }, [canAccessEntityCount.data]);

  const renderCanAccess = (type, key) => (
    <Box>
      {canAccessData?.[type]?.[key] ??
        (type === 'internal' && (key === 'CrossAccount' || key === 'ThirdParty')
          ? ''
          : 0)}
    </Box>
  );

  const getRating = useCallback(() => {
    return !isCodeRepo && !!riskAnalysis?.data?.overall_risk
      ? riskAnalysis?.data?.overall_risk?.replace(' Risk', '')
      : 'None';
  }, [isCodeRepo, riskAnalysis?.data]);

  const Container = ({ children, pr = 4 }) => (
    <Box
      py={4}
      pl={4}
      pr={pr}
      border="1px solid"
      borderColor={customTheme.colors.gray['200']}
      borderRadius={6}
      h="full"
    >
      {children}
    </Box>
  );

  const centerHeaderStyles = {
    color: customTheme.colors.gray['270'],
    fontSize: '13px',
    textAlign: 'center',
  };

  return (
    <Box w="full" h="340px">
      <Stack w="full" spacing={3} h="full">
        <HStack justify="space-between" px={0}>
          <DetailsIdentifier
            label="Sensitivity Score"
            value={renderRating(
              dataSourceSensitiveInfo?.data?.sensitivity_score,
              'medium',
              dataSourceSensitiveInfo?.isLoading,
            )}
            styles={{ label: { fontWeight: 400, color: 'black' } }}
          />
          <DetailsIdentifier
            label="Risk Rating"
            value={renderRating(
              getRating(),
              isCodeRepo || !riskAnalysis?.data?.overall_risk
                ? 'gray.100'
                : lowerCase(getRating()),
              riskAnalysis.isLoading,
            )}
            styles={{ label: { fontWeight: 400, color: 'black' } }}
          />
          <DetailsField
            label="Total Size"
            value={formatBytes(dataSourceSensitiveInfo?.data?.total_size)}
          />
          <DetailsField
            label={
              row?.native_name === SupportedServices.SimpleStorageService
                ? 'Total Files'
                : 'Tables'
            }
            value={formatNumber(
              dataSourceSensitiveInfo?.data?.total_object_number,
            )}
          />
          <DetailsField
            label="Sensitive records"
            value={formatNumber(
              dataSourceSensitiveInfo?.data?.total_sensitive_data_number,
            )}
          />
        </HStack>
        <Divider color={customTheme.colors.gray['200']} />

        <HStack w="full" gap={3} h="full">
          <Box w="19%" h="full">
            <Container>
              <Stack spacing={3} h="full">
                <Grid gap={2} templateColumns="repeat(3, 1fr)" w="full">
                  <GridItem colSpan={2} fontWeight={600}>
                    Category
                  </GridItem>
                  <GridItem colSpan={1} {...(centerHeaderStyles as any)}>
                    COUNT
                  </GridItem>
                </Grid>
                <Stack h="full" justify="space-between" pb={3} pt={4}>
                  {dataSourceSensitiveInfo.isLoading && renderSpinner()}
                  {!dataSourceSensitiveInfo.isLoading &&
                    map(SENSITIVITY_CATEGORIES, (o, key) => (
                      <Grid gap={2} templateColumns="repeat(2, 1fr)">
                        <GridItem colSpan={1}>
                          <HStack>
                            <Box
                              bg={o?.toLowerCase()}
                              boxSize={4}
                              borderRadius={3}
                            />
                            <Box>{key}</Box>
                          </HStack>
                        </GridItem>
                        <GridItem colSpan={1} textAlign="right" pr={5}>
                          {formatNumber(sensitiveDataCount?.[key]?.count ?? 0)}
                        </GridItem>
                      </Grid>
                    ))}
                </Stack>
              </Stack>
            </Container>
          </Box>

          {!isCodeRepo && (
            <Box w="22%" h="full">
              <Container>
                <Stack w="full" spacing={3} h="full">
                  <Grid gap={2} templateColumns="repeat(4, 1fr)">
                    <GridItem colSpan={3} fontWeight={600}>
                      Key Risk Factors
                    </GridItem>
                    <GridItem colSpan={1} {...(centerHeaderStyles as any)}>
                      STATUS
                    </GridItem>
                  </Grid>
                  <Stack h="full" justify="space-between" pt={4} pb={3}>
                    {riskAnalysis.isLoading && renderSpinner()}
                    {!riskAnalysis.isLoading &&
                      map(riskAnalysis?.data?.risk_factors, o => (
                        <Grid gap={3} templateColumns="repeat(4, 1fr)">
                          <GridItem colSpan={3}>
                            <HStack>
                              <WithResourceIcon
                                resourceType={o.name}
                                color="critical"
                                iconFilled={false}
                                iconSize="xs"
                              >
                                <Box>{o.name}</Box>
                              </WithResourceIcon>
                            </HStack>
                          </GridItem>
                          <GridItem
                            colSpan={1}
                            alignItems="center"
                            display="flex"
                            justifyContent="center"
                          >
                            {renderRiskAnalysis(o?.status, o?.current_state)}
                          </GridItem>
                        </Grid>
                      ))}
                  </Stack>
                </Stack>
              </Container>
            </Box>
          )}

          {!isCodeRepo && (
            <Box w="25%" h="full">
              <Container>
                <Stack w="full" spacing={3} h="full">
                  <Grid gap={1} templateColumns="repeat(4, 1fr)" px={2}>
                    <GridItem colSpan={2} fontWeight={600}>
                      Access
                    </GridItem>
                    <GridItem colSpan={1} {...(centerHeaderStyles as any)}>
                      INTERNAL
                    </GridItem>
                    <GridItem colSpan={1} {...(centerHeaderStyles as any)}>
                      EXTERNAL
                    </GridItem>
                  </Grid>
                  <Stack h="full" justify="space-between" px={2} pt={4} pb={3}>
                    {canAccessEntityCount.isLoading && renderSpinner()}
                    {!canAccessEntityCount.isLoading &&
                      map(canAccess, (o, key) => (
                        <Grid gap={6} templateColumns="repeat(4, 1fr)" w="full">
                          <GridItem colSpan={2}>
                            <WithResourceIcon
                              resourceType={o}
                              color="primary"
                              iconFilled={false}
                              iconSize="xs"
                              spacing={2}
                            >
                              {key}
                            </WithResourceIcon>
                          </GridItem>
                          <GridItem colSpan={1} textAlign="right" pr={4}>
                            {renderCanAccess('internal', o)}
                          </GridItem>
                          <GridItem colSpan={1} textAlign="right" pr={4}>
                            {renderCanAccess('external', o)}
                          </GridItem>
                        </Grid>
                      ))}
                  </Stack>
                </Stack>
              </Container>
            </Box>
          )}
          <Box w="34%" h="full">
            {!isCodeRepo &&
              row.native_name !== NativeResources.SnowflakeDatabases && (
                <Container pr={2}>
                  <Stack w="full" h="full">
                    <Box fontWeight={600} px={2}>
                      Risk map
                    </Box>
                    <Box flex={1} w="full" h="300px">
                      <S3RiskAnalysisRadarChart
                        data={riskAnalysis?.data ?? {}}
                      />
                    </Box>
                  </Stack>
                </Container>
              )}
          </Box>
        </HStack>
      </Stack>
    </Box>
  );
};
