import React, { useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useDataDashboardContext } from 'containers/Visibility/Data/Components/hooks/context';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

import { DataTable } from './DataTable';

interface Props {
  showDataTypeSources?: boolean;
  setShowDataTypeSources?(val: boolean);
}

export const DataTables: React.FC<Props> = props => {
  const { selectedTab } = useSelector(selectDataDashboard);
  const { getTableData, getDataFootprint } = useDataDashboardContext();
  const dispatch = useDispatch();

  useEffect(() => {
    getTableData();
  }, [getTableData, selectedTab]);

  useEffect(() => {
    getDataFootprint();
  }, [getDataFootprint]);

  useEffect(() => {
    dispatch(sharedStateActions.getRegions({ q: {} }));
  }, [dispatch]);

  return (
    <Box flex={1} w="full" h="full">
      <ActiveResourceProvider>
        <DataTable {...props} />
      </ActiveResourceProvider>
    </Box>
  );
};
