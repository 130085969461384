import React from 'react';

import {
  Box,
  HStack,
  Stack,
  Center,
  Grid,
  GridItem,
  Flex,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { AwsIcon } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { widgetContentTableHeaderStyles } from 'components/Visualization/CDashboard/Widget/styles';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';

export const RuntimeInsightsForDataSources: React.FC = props => {
  const { sensitiveDataByRegion } = useSelector(selectDataDashboard);

  const columns = [
    { header: <Box pl={10}>SOURCE</Box> },
    { header: 'CONTEXT' },
    { header: 'DESCRIPTION', align: 'left' },
  ];

  return (
    <DashboardWidget
      label="Runtime Insights For Data Sources"
      subHeader="Last 7 days"
      content={
        <Box w="full" h="full">
          <DashboardTable columns={columns} data={[]} />
        </Box>
      }
    />
  );
};
