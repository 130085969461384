import React from 'react';

import { Box, Image, Stack, Center } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { H2 } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { registerLayout } from 'components/Visualization/ColumnarGraph/registerLayout';

import emptyGraph from './security-graph-template.png';

registerLayout();

interface Props {
  isLoading: boolean;
}

export const EmptySecurityGraph = (props: Props) => {
  return (
    <WithSpinner loadStatus={{ loading: props.isLoading }}>
      <Stack onContextMenu={e => e.preventDefault()} w="full" h="full">
        <Center my="auto" pb={10}>
          <Stack spacing={8}>
            <Stack spacing={1}>
              <Center pb={6}>
                <H2 color={customTheme.colors.gray['300']}>
                  Ariksa Security Graph
                </H2>
              </Center>
              <Center color={customTheme.colors.gray['250']}>
                Security graph helps you visualize relationships, dependencies,
                and interactions involving identities, infrastructure,
                applications,
              </Center>
              <Center color={customTheme.colors.gray['250']}>
                vulnerabilities, data and network to triage and investigate
                issues for rapid remediation
              </Center>
            </Stack>
            <Box>
              <Center>
                <Image w="700px" src={emptyGraph} alt="Data Security" />
              </Center>
            </Box>
          </Stack>
        </Center>
      </Stack>
    </WithSpinner>
  );
};
