import React, { ReactNode, useEffect, useState } from 'react';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Box,
  Flex,
  Td,
  Center,
  TableProps as ChakraTableProps,
} from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { customTheme } from 'theme';
import { Dict } from 'types/utils';

import { useSortableHeader } from 'components/DataDisplay/NewTable/hooks/useSortableHeader';
import {
  OnSort,
  Sorted,
  TableColumnProps,
  TableStyles,
} from 'components/DataDisplay/NewTable/types';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { NoDataAvailable } from 'components/DataDisplay/Utils';
import { IPagination, Pagination } from 'components/Navigation';

import { TableBody } from './TableBody';

import './style.css';

export interface TableProps extends Omit<ChakraTableProps, 'cursor'> {
  topHeader?: () => void;
  columns: TableColumnProps[];
  data: Record<string, any>[];
  onRowClick?: (row, index) => void;
  expanded?: any;
  rowIndex?: any;

  isRowActive?(row: any, index: number);

  pagination?: IPagination;
  subComponent?: ({ row, expanded, columns, index }) => void;
  isLoading?: boolean;
  isError?: boolean;
  styles?: TableStyles;
  cursor?: string | ((row: Record<string, any>, index: number) => string);
  onSort?: OnSort;
  sortBy?: Sorted;
  headerClassName?: string;
  testName?: string;
  rowProps?: Dict<any>;
  spinnerSize?: string;
  noDataMessage?: ReactNode | string;
}

export const TableCell: React.FC<any> = props => {
  return (
    <Td
      minWidth="120px"
      maxWidth="260px"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      style={{
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...props.styles,
      }}
      {...props.styles}
    >
      <>{props.children}</>
    </Td>
  );
};

export const CustomTable2: React.FC<TableProps> = props => {
  const {
    topHeader,
    columns,
    data,
    onRowClick,
    subComponent,
    isLoading,
    isError,
    expanded,
    rowIndex,
    styles,
    cursor,
    onSort,
    sortBy,
    headerClassName,
    testName,
    isRowActive,
    spinnerSize = 'lg',
    noDataMessage = 'No Data Available',
    ...rest
  } = props;

  const { sortableColumn } = useSortableHeader(onSort, sortBy);
  const [currentRowIndex, setCurrentRowIndex] = useState(-1);

  useEffect(() => {
    setCurrentRowIndex(rowIndex);
  }, [rowIndex]);

  return (
    <Box
      w="full"
      h="full"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      flex="1 1 0"
    >
      <Box
        overflowX="auto"
        w={'full'}
        h="full"
        flex="1 0 0"
        style={{ position: 'relative', ...props.styles }}
      >
        <Table
          //h="full"
          variant="simple"
          {...rest}
          data-test={`table-${testName}`}
          h={isLoading || isEmpty(data) ? 'full' : 'default'}
        >
          <>
            {topHeader?.()}
            <Thead
              h="12"
              backgroundColor={customTheme.colors.gray['50']}
              position="sticky"
              top={topHeader ? '40px' : '0'}
              bg={customTheme.colors.gray['50']}
              css={{
                'th:first-of-type': {
                  background: customTheme.colors.gray['50'],
                },
              }}
              {...styles?.header}
              className={headerClassName}
            >
              <Tr>
                {columns?.map(sortableColumn).map((col, index) => (
                  <Th
                    key={index + '-table-column-header'}
                    textAlign="center"
                    textTransform="none"
                    whiteSpace="nowrap"
                    fontSize="tableCell"
                    color="black"
                    letterSpacing="none"
                    style={{
                      textAlign:
                        col?.align ?? (index === 0 ? 'left' : 'center'),
                      ...col.styles,
                      ...col.styles?.headerCell,
                      ...styles?.headerCell,
                    }}
                    {...styles?.headerCell}
                  >
                    {col.renderHeader
                      ? col.renderHeader({ value: col.header })
                      : col.header}
                  </Th>
                ))}
              </Tr>
            </Thead>

              <Tbody w="full" h="full">
              {isLoading && (
                <Tr>
                  <Td colSpan={100} borderBottom="0">
                    <Box h="full" display="flex" alignItems="center">
                      <Center w="full" h="full">
                        <CustomSpinner size={spinnerSize} />
                      </Center>
                    </Box>
                  </Td>
                </Tr>
              )}
              {isError && (
                <Tr>
                  <Td colSpan={100} borderBottom="0">
                    <Box h="full" display="flex" alignItems="center">
                      <Center w="full">
                        <NoDataAvailable
                          text={'Unable to retrieve information'}
                        />
                      </Center>
                    </Box>
                  </Td>
                </Tr>
              )}
              {!isLoading && isEmpty(data) && !isError ? (
                <Tr>
                  <Td colSpan={100} borderBottom="0">
                    <Box h="full" display="flex" alignItems="center">
                      <Center w="full">
                        <NoDataAvailable text={noDataMessage ?? ''} />
                      </Center>
                    </Box>
                  </Td>
                </Tr>
              ) : (
                !isLoading &&
                map(data, (row, index) => (
                  <TableBody
                    key={index + '-row'}
                    styles={styles}
                    columns={columns}
                    row={row}
                    subComponent={subComponent}
                    onRowClick={onRowClick}
                    expanded={expanded}
                    index={index}
                    rowIndex={currentRowIndex}
                    cursor={cursor}
                    setCurrentRowIndex={setCurrentRowIndex}
                    isRowActive={isRowActive}
                  />
                ))
              )}
            </Tbody>
          </>
        </Table>
      </Box>
      {props.pagination && (
        <Box
          h={10}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt="4"
        >
          {/*table pagination*/}
          {props.pagination && (
            <Flex justify="flex-end" {...styles?.pagination}>
              <Pagination {...props.pagination} />
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};
