import { ReactNode } from 'react';

import { DataFootprintAnalyzed } from '@ariksa/data-scanning/api';
import {
  CountSummary,
  DashboardDrawerConfig,
  DataSourcesSummary,
  EntityRow,
  InventorySummary,
  PanelsEnum,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummary,
  AlertHistoryResponse,
  AlertSummaryResponse,
} from '@ariksa/notification/api';
import {
  ResourceCount,
  ResourceCountResponse,
  TotalAlertsResponse,
} from '@ariksa/reporting/api';
import { IPagedQueryState } from 'services/utils/PagedQueryState';
import { IQueryState } from 'services/utils/QueryState';

import { IconType } from 'components/Icons';

export interface DashboardState {
  alertingResources: IQueryState<number>;
  inventorySummary: IQueryState<CountSummary>;
  inventoryResourcesSummary: IQueryState<InventorySummary>;
  openFindings: IQueryState<Record<string, AggregatedAlertsSummary[]>>;

  cloudSummary: IQueryState<CountSummary>;
  resourcesCount: IQueryState<ResourceCount>;
  dataCount: IQueryState<ResourceCountResponse>;
  identityCount: IQueryState<ResourceCountResponse>;
  computeCount: IQueryState<ResourceCountResponse>;
  networkCount: IQueryState<ResourceCountResponse>;
  credentialCount: IQueryState<ResourceCountResponse>;
  domainsCount: IQueryState<ResourceCountResponse>;

  entitiesHistory: IQueryState<Record<string, number>>;
  alertsHistory: IQueryState<TotalAlertsResponse>;
  analyzedDataSourcesCount: IQueryState<DataFootprintAnalyzed>;

  openFindingHistory: IQueryState<AlertHistoryResponse>;
  identityAuthorizationOpenFindingsHistory: IQueryState<AlertHistoryResponse>;
  dataSecurityOpenFindingsHistory: IQueryState<AlertHistoryResponse>;
  cloudPostureOpenFindingsHistory: IQueryState<AlertHistoryResponse>;

  findingsCategorySummary: IQueryState<AggregatedAlertsSummary[]>;
  openAlerts: IQueryState<AlertSummaryResponse>;
  infrastructurePostureSummary: IQueryState<CountSummary>;

  summaryOfAllDataSources: IQueryState<DataSourcesSummary>;

  vulnerableResourcesSummary: IQueryState<CountSummary>;
  criticalAndHighVulnerabilities: IQueryState<Record<string, any>>;

  drawerConfig: IQueryState<DashboardDrawerConfig>;
  allEntities: IPagedQueryState<EntityRow[]>;
  alertCount: IQueryState<Record<string, number>>;
  securityGraphAvailability: IQueryState<Record<string, boolean>>;
  runtimeInsights: IQueryState<any>;
}

export type ContainerState = DashboardState;

export interface LegendProps {
  label: string;
  color?: string;
  count?: number;
  isLoading?: boolean;
  icon?: ReactNode;
  iconPadding?: number;
  spacing?: number; //for spacing between label and count
  labelSpacing?: number; //for spacing between label and color box

  onClick?();
}

export interface AllEntitiesDetailsProps {
  panel: PanelsEnum;
  iconType: IconType;
  resourceType?: string;
  resourceTypeClass?: 'native' | 'agnostic';
  total: number;
  iconBgColor?: string;
}

export interface ResourceIconAndCountProps {
  icon?: ReactNode;
  label: ReactNode;
  count: number;
  iconPadding?: number;
  iconColor?: string;
  iconType?: string;
  onClick?();
  isLoading?: boolean;
  iconTooltip?: ReactNode;
}

export const OverviewCardSpacing = 3;
export const CountFontSize = 18;

export const SearchQueryNameMapping = {
  Show_users_with_excess_permissions: 'Show_users_with_excess_permissions',
  Show_S3_with_internet_accessible: 'Show_S3_with_internet_accessible',
  Show_roles_with_excess_permissions: 'Show_roles_with_excess_permissions',
  Show_users_with_access_to_sensitive_data:
    'Show_users_with_access_to_sensitive_data',
  Show_roles_with_access_to_sensitive_data:
    'Show_roles_with_access_to_sensitive_data',
  Show_users_with_high_privileges: 'Show users with high privileges',
  Show_roles_with_high_privileges: 'Show roles with high privileges',
  Show_VMs_that_is_publicly_exposed_with_critical_high_vulnerabilities:
    'Show_VMs_that_is_publicly_exposed_with_critical_high_vulnerabilities',
  Show_VMs_with_critical_high_vulnerabilities_and_that_has_access_to_sensitive_data:
    'Show_VMs_with_critical_high_vulnerabilities_and_that_has_access_to_sensitive_data',
};

export const SearchQueryMapping = {
  Show_roles_with_high_privileges: 'SHOW Role with high_privileges = true',
  Show_VMs_that_is_publicly_exposed_with_critical_high_vulnerabilities:
    'SHOW VM with publicly_exposed = true AND critical_high_vulnerabilities = true',
  Show_VMs_with_critical_high_vulnerabilities_and_that_has_access_to_sensitive_data:
    'SHOW VM with critical_high_vulnerabilities = true AND access_to_sensitive_data = true',
  Show_unrotated_credentials: 'SHOW Credential WITH rotation_enabled = false',
  Show_exposed_credentials:
    'SHOW VirtualMachine WITH internet_accessible = true AND access_key_exposed = true OR  internet_accessible = true AND ssh_key_exposed = true',
  Show_expired_certificates: 'SHOW Certificate WITH expired = true',
  Show_VirtualMachine_with_internet_accessible_and_access_key_exposed:
    'SHOW VirtualMachine WITH internet_accessible = true AND access_key_exposed = true',
  Show_VirtualMachine_with_cidr_any_and_open_ports:
    'SHOW VirtualMachine WITH cidr = any AND open_ports = 22',
  Show_VirtualMachine_with_open_ports_equals_22_and_protocol_tcp:
    'SHOW VirtualMachine WITH open_ports = 22 AND protocol=tcp',

  //these queries require parameters
  Show_resource_type_with_high_privileges_and_resource_type_that_can_access_any:
    'SHOW resource_type with high_privileges = true AND resource_type THAT can access ANY',
  Show_resource_type_with_internet_accessible:
    'Show resource-name with internet_accessible = True',
  Show_resource_type_with_excess_permissions:
    'SHOW resource_type THAT has Excess Permissions To ANY',
  Show_vm_with_open_ports_and_protocol_tcp_udp_and_cidr:
    'Show_vm_with_open_ports_and_protocol_tcp_udp_and_cidr',
  Show_resource_type_with_encryption_false:
    'Show resource-name WITH encrypted = false',
  Show_resource_type_with_access_to_sensitive_data:
    'SHOW User THAT Can Access Data AND Data WITH sensitive_data.exist = true',
};
