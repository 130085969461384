import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';

export const PolicyManagement: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const { policyManagement } = stateOfYourCloud;
  const navigate = useNavigate();

  return (
    <DashboardWidget
      label="Policy Management"
      content={renderResourcesWithIconAndCount([
        {
          label: 'Environments with Blueprints',
          count: policyManagement.data?.environments_with_blueprints || 0,
          isLoading: policyManagement.isLoading,
          iconType: IconTypes.Environment,
          onClick: () => navigate('/policy-hub/blueprint'),
        },
        {
          label: 'Policies Enforced',
          count: policyManagement.data?.policies || 0,
          isLoading: policyManagement.isLoading,
          iconType: IconTypes.List,
          onClick: () => navigate('/policy-hub/policy'),
        },
        {
          label: 'Custom Policy Blueprints',
          count: policyManagement.data?.custom_policy_blueprints || 0,
          isLoading: policyManagement.isLoading,
          iconType: IconTypes.Documents,
          onClick: () => navigate('/policy-hub/blueprint'),
        },
        {
          label: 'Policy Exceptions',
          count: policyManagement.data?.exceptions || 0,
          isLoading: policyManagement.isLoading,
          iconType: IconTypes.Exception,
          onClick: () => navigate('/policy-hub/blueprint'),
        },
      ])}
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
    />
  );
};
