import React from 'react';

import { NativeResources } from '@ariksa/inventory-core';

import { EC2Vulnerability } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/Vulnerability';
import { ECRImages } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ECR/Components/ECRImages';
import { ContainerVulnerability } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/ContainerVulnerability/ContainerVulnerability';

export const getVulnerability = (resourceType: NativeResources) => {
  switch (resourceType) {
    case NativeResources.Ec2Instance:
    case NativeResources.LambdaFunction:
    case NativeResources.Container:
    case NativeResources.AmazonMachineImage:
    case NativeResources.GcpComputeEngine:
    case NativeResources.GcpCloudFunctions:
    case NativeResources.GcpContainer:
    case NativeResources.AzureVirtualMachine:
    case NativeResources.AzureVirtualMachineScaleSetInstance:
    case NativeResources.AzureVirtualMachineScaleSets:
    case NativeResources.AzureFunctions:
    case NativeResources.AzureContainer:
      return <EC2Vulnerability />;
    case NativeResources.ElasticKubernetesService:
    case NativeResources.ElasticContainerService:
    case NativeResources.EcsCluster:
    case NativeResources.Gcpgke:
    case NativeResources.Aks:
      return <ContainerVulnerability vulnerability />;
    case NativeResources.ElasticContainerRegistry:
      return <ECRImages vulnerability />;
    default:
      return null;
  }
};

export const resourceVulnerabilityKey = (
  instanceId: string,
  containerId: string,
) => {
  return `${instanceId}-${containerId}`;
};
