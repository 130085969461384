import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

import { overviewDashboardWidgets } from './widget';

export const dataSecurityDashboardConfig: IDashboardLayout = {
  id: 'overview_dashboard',
  title: 'Overview Dashboard',
  widgets: [
    {
      type: 'card',
      i: 'summary',
      x: 0,
      y: 0,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataSources',
      x: 0,
      y: 3.8,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'buckets',
      x: 0,
      y: 7.6,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'databases',
      x: 0,
      y: 11.4,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'disks',
      x: 0,
      y: 15.2,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'saas',
      x: 0,
      y: 19,
      w: 2.4,
      h: 3.8,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataSecurityFindingsToPrioritize',
      x: 2.4,
      y: 0,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'insightsForDataSources',
      x: 5.6,
      y: 0,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataFootprintBySourceTypes',
      x: 8.8,
      y: 0,
      w: 3.2,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataSourcesAndSensitiveData',
      x: 2.4,
      y: 7.6,
      w: 4.8,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataRegions',
      x: 7.2,
      y: 7.6,
      w: 4.8,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'publiclyExposed',
      x: 2.4,
      y: 15.2,
      w: 4.8,
      h: 7.6,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'findingsHistory',
      x: 7.2,
      y: 15.2,
      w: 4.8,
      h: 7.6,
      isResizable: false,
      static: true,
    },
  ],
  available: overviewDashboardWidgets,
};
