import React, { FC, useEffect, useRef, useState } from 'react';

import { NativeResources, ResourceMapping } from '@ariksa/inventory-core/api';
import { Box, Stack, UseDisclosureProps } from '@chakra-ui/react';
import { isString, map } from 'lodash';
import { parse } from 'marked';
import HTMLRenderer from 'react-html-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { Drawer, StackedHeader } from 'components/index';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions } from 'containers/SharedState/slice';

interface Props {
  resource: Record<string, any> & {
    name?: string;
    resource_id: string;
    uuid: string;
    account_id: string;
    native_name: NativeResources;
  };
  useDisclosure: UseDisclosureProps;
}

export const SummarizeResource: FC<Props> = props => {
  const { resource, useDisclosure } = props;
  const [resourceType, setResourceType] = useState<ResourceMapping>(
    {} as ResourceMapping,
  );
  const { aiResourceSummary } = useSelector(selectSharedState);
  const { getResourceType } = useResourceType();
  const ref = useRef(document.querySelector('.portal-container'));
  const dispatch = useDispatch();

  useEffect(() => {
    setResourceType(
      getResourceType(resource.native_name) || ({} as ResourceMapping),
    );
  }, [resource, getResourceType]);

  useDeepCompareEffect(() => {
    dispatch(
      actions.getAIResourceSummary({
        q: {
          accountId: resource.account_id,
          resourceUuid: resource.uuid,
          resourceType: resource.native_name,
        },
      }),
    );
  }, [dispatch, resource]);

  return (
    <Drawer
      isOpen={useDisclosure.isOpen!}
      onClose={useDisclosure.onClose!}
      header={
        <StackedHeader
          upper={resource?.name || resource?.resource_id}
          lower={
            (resourceType.resource_alias || resourceType.cloud_native_name) +
            ' / ' +
            resourceType.cloud_agnostic_name
          }
          type={resource.native_name}
        />
      }
      styles={{
        content: { maxW: '4xl' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
      body={
        <Stack h="full" spacing={6} pt={4}>
          <Box fontSize="md" fontWeight={600}>
            Resource Summary for {resource?.name || resource?.resource_id}
          </Box>
          {aiResourceSummary.isLoading ? (
            <Box flex={1}>
              <CustomSpinner />
            </Box>
          ) : (
            <Box>
              {isString(aiResourceSummary.data) ? (
                <Stack px={4} spacing={3}>
                  <HTMLRenderer html={parse(aiResourceSummary.data)} />
                </Stack>
              ) : (
                <Stack>
                  {map(aiResourceSummary.data, o => (
                    <Box>{<HTMLRenderer html={parse(o)} />}</Box>
                  ))}
                </Stack>
              )}
            </Box>
          )}
        </Stack>
      }
    />
  );
};
