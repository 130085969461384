import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { each } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip, WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { SearchQueryMapping } from 'containers/Dashboard/types';
import { getSearchQuery } from 'containers/Dashboard/utils';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

export const IdentitiesWithExcessPermissions: React.FC = () => {
  const { identitiesWithExcessPermissions } = useSelector(
    selectIdentityOverview,
  );
  const navigate = useNavigate();
  const [data, setData] = useState<Record<string, any>>({});

  useEffect(() => {
    let items = {};
    each(
      identitiesWithExcessPermissions.data?.items,
      o => (items[o.provider_or_source_type + ' ' + o.agnostic] = o.count),
    );
    setData(items);
  }, [identitiesWithExcessPermissions.data]);

  const items = [
    {
      label: 'Users',
      count: formatNumber(data?.['Cloud User'] || 0),
      isLoading: identitiesWithExcessPermissions.isLoading,
      iconType: IconTypes.IamUser,
      onClick: () => {
        const url = securityGraphRedirectUrl({
          query: getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_excess_permissions,
            ['User'],
          ),
        });
        navigate(url);
      },
      iconTooltip: formatTooltip({
        header: 'Users',
        content: 'Cloud-native and federated users',
      }),
    },
    {
      label: 'Roles',
      count: formatNumber(data?.['Cloud Role'] || 0),
      isLoading: identitiesWithExcessPermissions.isLoading,
      iconType: IconTypes.Role,
      onClick: () => {
        const url = securityGraphRedirectUrl({
          query: getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_excess_permissions,
            ['Role'],
          ),
        });
        navigate(url);
      },
      iconTooltip: formatTooltip({
        header: 'Roles',
        content: 'Cloud-native roles for public clouds, SaaS',
        width: 32,
      }),
    },
    {
      label: 'Non-users',
      count: formatNumber(data?.['Cloud Non-users'] || 0),
      isLoading: identitiesWithExcessPermissions.isLoading,
      iconType: IconTypes.VirtualMachine,
      onClick: () => {
        const url = securityGraphRedirectUrl({
          query: getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_excess_permissions,
            ['Compute,Credential,ContainerService,App'],
          ),
        });
        navigate(url);
      },
      iconTooltip: formatTooltip({
        header: 'Non-users',
        content:
          'Non—user identities: VMs, Containers, Container Services, Serverless etc.',
        width: 48,
      }),
    },
    {
      label: 'Cloud access keys',
      count: 0,
      //isLoading: identitiesWithExcessPermissions.isLoading,
      iconType: IconTypes.Key,
      /*onClick: () => {
        const url = securityGraphRedirectUrl({
          query: getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_excess_permissions,
            ['Compute,Credential,ContainerService,App'],
          ),
        });
        navigate(url);
      },*/
    },
  ];

  const columns = [
    {
      header: <Box pl={9}>TYPE</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.iconType}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
          color="primary"
        >
          {row?.label}
        </WithResourceIcon>
      ),
      //styles: { cell: { w: '40%' }, header: { w: '40%' } },
    },
    {
      header: 'ENTITIES',
      accessor: 'count',
      render: ({ value }) => formatNumber(value),
      styles: {
        td: { w: '40%' },
        cell: { textAlign: 'right', w: '53%' },
        header: { textAlign: 'center' },
      },
    },
    //{header: '', styles: { cell: { w: '40%' } },}
  ];

  return (
    <DashboardWidget
      label="Identities with Excess Permissions"
      content={<DashboardTable columns={columns} data={items} />}
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
    />
  );
};
