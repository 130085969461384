import React, { FC, useState } from 'react';

import {
  Box,
  BoxProps,
  Heading,
  CloseButton,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { find, map } from 'lodash';
import { useLocation } from 'react-router-dom';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { TimeRangeButtonGroup } from 'components/DataEntry/Button/OptionsButtonGroup/TimeRangeButtonGroup';
import { ViewAllIcon } from 'components/Icons/ReactCustomIcons/ViewAllIcon';
import {
  EditIcon,
  modalCloseButtonIconStyles,
  PrimaryIconButton,
  QuestionCircleIcon,
  Tag,
} from 'components/index';
import { EnvironmentName } from 'containers/App/utils';
import { reportChartTimeRangeOptions } from 'containers/Reports/ReportsDashboard/Components/ReportWidget/utils';
import { toTitleCase } from 'utils/string';

export interface IReportWidget extends BoxProps {
  onClickRemove?: () => void;
  onClickEdit: () => void;
  currentRecord: Record<string, any>;
  expandChart: boolean;
  setExpandChart: (val: boolean) => void;
  getReportingChartDetails(
    chart: Record<string, any>,
    index: number,
    timeRange: string,
  ): void;
}

export const ReportWidgetHeader: FC<IReportWidget> = props => {
  const {
    onClickRemove,
    onClickEdit,
    currentRecord,
    expandChart,
    setExpandChart,
    getReportingChartDetails,
  } = props;
  let { pathname } = useLocation();
  const [isEditDashboard] = useState(pathname === '/reports/dashboard/edit');

  return (
    <Box>
      {/*<ScheduleReport isOpen={isOpen} onClose={onClose} />*/}
      <HStack px={3} h={'40px'} justifyContent={'space-between'}>
        <Stack spacing={1}>
          <HStack>
            <Heading fontSize="sm" fontWeight={600}>
              {toTitleCase(currentRecord?.name)}
            </Heading>
            <CustomTooltip label={currentRecord?.description}>
              <Box color="primary">
                <QuestionCircleIcon />
              </Box>
            </CustomTooltip>
          </HStack>
          <HStack>
            <Box>Filter(s): </Box>
            {!!currentRecord?.environment_ids[0] && (
              <EnvironmentName
                environmentId={currentRecord?.environment_ids[0]}
              />
            )}
            {currentRecord?.environment_ids?.length > 1 && (
              <CustomTooltip
                label={
                  <Stack>
                    <Box>Environments</Box>
                    {map(currentRecord?.environment_ids, (o, index) => (
                      <HStack>
                        <Box>{index + 1}</Box>
                        <EnvironmentName environmentId={o} />
                      </HStack>
                    ))}
                  </Stack>
                }
              >
                <Tag
                  label={'+ ' + (currentRecord?.environment_ids?.length - 1)}
                />
              </CustomTooltip>
            )}
            {/*{map(accounts, (o, index) => (
              <WrapItem>
                <CloudAccountName accountId={o} />
                {index + 1 === accounts?.length ? '' : ','}
              </WrapItem>
            ))}*/}
          </HStack>
        </Stack>

        <HStack>
          {!isEditDashboard && (
            <TimeRangeButtonGroup
              options={reportChartTimeRangeOptions}
              styles={{ option: { fontSize: 'xs', py: 0.5 } }}
              value={find(
                reportChartTimeRangeOptions,
                o => o.value === currentRecord?.timeRange,
              )}
              onChange={selected => {
                getReportingChartDetails(
                  currentRecord,
                  currentRecord.index,
                  selected?.value,
                );
              }}
            />
          )}
          {!isEditDashboard && !expandChart && (
            <CustomTooltip label="Expand">
              <PrimaryIconButton
                aria-label="view all"
                icon={<ViewAllIcon p="1px" />}
                p={1}
                size="xs"
                maxW={4}
                borderRadius="lg"
                onClick={() => {
                  setExpandChart(true);
                  //onClickExpand?.();
                }}
              />
            </CustomTooltip>
          )}
          {!isEditDashboard && (
            <CustomTooltip label="Edit">
              <PrimaryIconButton
                aria-label="edit"
                icon={<EditIcon />}
                size="xs"
                maxW={4}
                p={1}
                borderRadius="lg"
                onClick={() => onClickEdit?.()}
              />
            </CustomTooltip>
          )}
          {isEditDashboard && (
            <CloseButton
              {...modalCloseButtonIconStyles}
              size="sm"
              onClick={() => onClickRemove?.()}
            />
          )}
        </HStack>
      </HStack>
    </Box>
  );
};
