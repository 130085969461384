import React, { FC, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { each } from 'lodash';
import { useSelector } from 'react-redux';

import { colorMapForCharts } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PieChart } from 'components/Visualization';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { renderPieChartLegend } from 'containers/Visibility/Data/Components/Dashboard/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const SensitiveDataByRegionPieChart: FC = () => {
  const { sensitiveDataByRegion } = useSelector(selectDataDashboard);
  const { regions, total = 0 } = sensitiveDataByRegion.data;
  const { getResourceAlias } = useResourceType();
  const [data, setData] = useState<any>();
  const [clouds, setClouds] = useState(0);

  useEffect(() => {
    if (!!regions) {
      let items: Record<string, any>[] = [];
      let clouds = new Set();
      each(regions, o => {
        items.push({
          name: o.region,
          value: o.region_count,
        });
        !!o.cloud && clouds.add(o.cloud);
      });
      setData(items);
      setClouds(clouds.size);
    }
  }, [regions, getResourceAlias]);

  return (
    <DashboardWidget
      label="Sensitive Data By Regions"
      subHeader={
        clouds +
        ' cloud' +
        (clouds === 1 ? ', ' : 's, ') +
        regions?.length +
        ' region' +
        (regions?.length === 1 ? '' : 's')
      }
      content={
        <Box w="full" h="full">
          <PieChart
            isLoading={sensitiveDataByRegion.isLoading}
            data={data}
            dataKey="value"
            total={formatNumber(total, 1)}
            outerRadius="85%"
            innerRadius="76%"
            activeShape={false}
            colorMap={colorMapForCharts}
            styles={{
              pie: { cx: '45%' },
              legend: {
                wrapperStyle: {
                  width: '160px',
                },
              },
            }}
            renderLegend={renderPieChartLegend}
            centerLabel="records"
          />
        </Box>
      }
    />
  );
};
