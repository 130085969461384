import React from 'react';

import { formatTooltip } from 'components/DataDisplay';
import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { ComputeEntities } from 'containers/Dashboard/CloudPostureOverview/Components/ComputeEntities';
import { CloudCompliance } from 'containers/Dashboard/Overview/Components/CloudCompliance';
import { CriticalAndHighSeverityFindingsByCategory } from 'containers/Dashboard/Overview/Components/CriticalAndHighSeverityFindingsByCategory';
import { DataSourcesSummaryCard } from 'containers/Dashboard/Overview/Components/DataSourcesSummaryCard';
import { Entities } from 'containers/Dashboard/Overview/Components/Entities';
import { FindingsToPrioritizeAcrossAllCategories } from 'containers/Dashboard/Overview/Components/FindingsToPrioritizeAcrossAllCategories';
import { GovernanceScore } from 'containers/Dashboard/Overview/Components/GovernanceScore';
import { Identities } from 'containers/Dashboard/Overview/Components/Identities';
import { Infrastructure } from 'containers/Dashboard/Overview/Components/Infrastructure';
import { OpenAlertsAndResolutions } from 'containers/Dashboard/Overview/Components/OpenAlertsAndResolutions';
import { PolicyManagement } from 'containers/Dashboard/Overview/Components/PolicyManagement';
import { RuntimeInsights } from 'containers/Dashboard/Overview/Components/RuntimeInsights';
import { Summary } from 'containers/Dashboard/Overview/Components/Summary';

export const overviewDashboardWidgets: Record<string, WidgetProps> = {
  summary: {
    flex: true,
    components: {
      content: <Summary />,
    },
  },
  entities: {
    flex: true,
    components: {
      content: <Entities />,
    },
  },
  findingsToPrioritizeAcrossAllCategories: {
    flex: true,
    components: {
      content: <FindingsToPrioritizeAcrossAllCategories />,
    },
  },
  criticalAndHighSeverityFindingsByCategory: {
    flex: true,
    components: {
      content: <CriticalAndHighSeverityFindingsByCategory />,
    },
  },
  runtimeInsights: {
    flex: true,
    components: {
      content: <RuntimeInsights />,
    },
  },
  computeEntities: {
    flex: true,
    components: {
      content: <ComputeEntities />,
    },
  },
  dataSourcesSummaryCard: {
    flex: true,
    components: {
      content: (
        <DataSourcesSummaryCard
          tooltip={formatTooltip({
            header: 'Data sources',
            content: 'Buckets, Disks, Databases and Data warehouses',
            width: 48,
          })}
        />
      ),
    },
  },
  cloudCompliance: {
    flex: true,
    components: {
      content: <CloudCompliance />,
    },
  },
  governanceScore: {
    flex: true,
    components: {
      content: <GovernanceScore />,
    },
  },
  identities: {
    flex: true,
    components: {
      content: (
        <Identities
          labelTooltip={formatTooltip({
            header: 'Identities',
            content: 'Cloud users, federated users & machine identities',
            width: 48,
          })}
        />
      ),
    },
  },
  infrastructure: {
    flex: true,
    components: {
      content: <Infrastructure />,
    },
  },
  policyManagement: {
    flex: true,
    components: {
      content: <PolicyManagement />,
    },
  },
  openAlertsAndResolutions: {
    flex: true,
    components: {
      content: <OpenAlertsAndResolutions />,
    },
  },
};
