import { customTheme } from 'theme';

export const widgetStyles = {
  common: {
    rounded: customTheme.radii.cardBorderRadius,
    borderWidth: '1px',
    px: 2,
    //pb: 2,
    h: 'full',
    bg: 'cardBg',
    borderColor: 'componentBorder',
    _hover: {
      boxShadow: `rgba(99, 99, 99, 0.2) 0px 5px 10px 2px`,
      border: '1px solid',
      borderColor: 'primary',
    },
  },
  dark: {
    //borderColor: 'whiteAlpha.400',
  },
  light: {
    //borderColor: 'gray.200',
    //boxShadow: '0 0 30px #e4e4e4',
  },
};

export const widgetContentTableHeaderStyles = {
  fontSize: 'xs',
  color: customTheme.colors.gray['250'],
};
