import { theme } from '@chakra-ui/react';

import { colorOpacity } from '../utils';

import { colors } from './colors';

export const primary = '#5d3edb';

export const light = {
  primary,
  //secondary: '#8882F1',
  primaryNavBarBg: primary,
  secondaryNavBarBg: colors.gray['400'],
  bodyBg: colors.background,
  topNavSearchBg: '#9d8be8',

  cardBg: colors.gray['0'],
  componentBorder: colors.gray['100'],
  chartGridColor: colors.gray['100'],

  selectedMenuBg: colorOpacity(primary, 0.05),
  selectedSideNavMenuBg: colorOpacity(primary, 0.1),
  menuColor: colors.gray['200'],

  tableSelectedRowBg: colors.blue['50'],
  //hover: '#F8F2FE',
  hover: '#F0E6FD', //colorOpacity(primary, 0.1)
  tableHeader: colors.gray['300'],
  tableHeaderBg: colors.gray['50'],
  tableRowBorder: colors.gray['100'],

  edit: colors.green['300'],
  delete: 'red',
  add: primary,
  critical: theme.colors.red['500'],
  high: '#df5d56',
  medium: '#da8b31',
  low: '#efb943',
  info: theme.colors.blue['300'],
  success: theme.colors.green['300'],
  error: theme.colors.red['500'],

  formFieldBorder: colors.gray['200'],
  placeholder: colors.gray['250'],

  pieChartBorder: colors.gray['0'],
  gaugeChartBorder: colors.gray['0'],

  sensitiveDataBg: '#404040',
  passed: '#9dc763',
};
