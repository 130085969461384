import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { forEach, includes, isEmpty, some } from 'lodash';
import { useSelector } from 'react-redux';

import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { usePageContentContext } from 'components/Layout';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectApp } from 'containers/App/selectors';
import { selectSharedState } from 'containers/SharedState/selectors';
import { DataDashboardSideNav } from 'containers/Visibility/Data/Components/DataDashboardSideNav';
import { DataDashboardContextProvider } from 'containers/Visibility/Data/Components/hooks/context';
import { useDataDashboard } from 'containers/Visibility/Data/Components/hooks/useDataDashboard';
import { NoDataScannerDeployed } from 'containers/Visibility/Data/Components/NoDataScannerDeployed';
import { useInjector } from 'utils/inject';

import { dataDashboardSaga } from './saga';
import { reducer, sliceKey } from './slice';

export const Data: React.FC = () => {
  useInjector(sliceKey, reducer, dataDashboardSaga);
  const { environments } = useSelector(selectApp);
  const { dataScannerInfo } = useSelector(selectSharedState);
  const { environmentId, accountId } = useAccessBoundary();
  const [environmentsObject, setEnvironmentsObject] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [showDashboard, setShowDashboard] = useState(true);
  const { contentHeight } = usePageContentContext();
  const contextValue = useDataDashboard();

  useEffect(() => {
    if (!isEmpty(environments.data)) {
      let items = {};
      forEach(environments.data, o => (items[o.id] = o));
      setEnvironmentsObject(items);
    }
  }, [environments.data]);

  useEffect(() => {
    !!environmentId &&
      setAccounts(environmentsObject[environmentId]?.account_ids);
  }, [environmentId, environmentsObject]);

  useEffect(() => {
    const scanners = Object.keys(dataScannerInfo.data);

    setShowDashboard(some(accounts, o => includes(scanners, o)));
  }, [accounts, accountId, dataScannerInfo.data]);

  return (
    <DataDashboardContextProvider value={contextValue}>
      <Box h={contentHeight + 'px'}>
        <WithSpinner
          loadStatus={{
            loading: dataScannerInfo.isLoading || environments.isLoading,
            loaded: dataScannerInfo.isSuccess,
          }}
          spinnerSize="lg"
        >
          {showDashboard ? <DataDashboardSideNav /> : <NoDataScannerDeployed />}
        </WithSpinner>
      </Box>
    </DataDashboardContextProvider>
  );
};
