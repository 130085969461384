import React, { FC } from 'react';

import { Box, Flex, ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import backgroundMissing from 'images/Background-Missing.jpg';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { PrimaryButton } from 'components/DataEntry';

export const NoDataScannerDeployed: FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      pl={24}
      pt={3}
      backgroundImage={backgroundMissing}
      w="full"
      h="full"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      borderRadius={6}
    >
      <Stack color="white" pt={6} pl={1} pr={32} spacing={16} w="85%">
        <Stack
          spacing={8}
          pl={12}
          border="1px solid"
          borderRadius={6}
          borderColor={customTheme.colors.gray['100']}
          pt={5}
          pb={10}
          bg="rgba(255, 255, 255, 0.25)"
        >
          <Box fontSize="2xl">Outpost for Data Not Deployed</Box>
          <Box fontSize="lg" w={'1000px'}>
            You have no Outpost for Data in the selected environment. This
            delivers document classification, data classification to enable
            robust data access & governance
          </Box>
        </Stack>
        <Stack pl={12} spacing={3}>
          <Box fontSize="2xl">Next Steps</Box>
          <UnorderedList fontSize="lg" spacing={2}>
            <ListItem pl={4}>Deploy Outpost for Data</ListItem>
            <ListItem pl={4}>
              Automatically scan all supported data sources (cloud and SaaS)
            </ListItem>
            <ListItem pl={4}>
              Customize with labels for AI search & data access and governance
            </ListItem>
          </UnorderedList>
        </Stack>
        <Flex>
          <Box pt={40} pl={12}>
            <PrimaryButton
              size="lg"
              onClick={() => navigate('/setup/outpost/add/data')}
            >
              OUTPOST FOR DATA
            </PrimaryButton>
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
};
