import React, { ReactNode, useEffect, useState } from 'react';

import { DataCategory } from '@ariksa/inventory-core';
import { PublicResourceSummary, Resources } from '@ariksa/inventory-core/api';
import {
  Box,
  Stack,
  HStack,
  Center,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { each, filter, includes, isEmpty, map, some, toArray } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorMap, customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import { WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { CriticalDataIcon, IconTypes } from 'components/Icons';
import { getIconColor } from 'components/Icons/Components/getIconColor';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { SearchQueryMapping } from 'containers/Dashboard/types';
import { getSearchQuery } from 'containers/Dashboard/utils';
import { renderCloudIcons } from 'containers/Dashboard/utils/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';
import { limitedString } from 'utils/string';

export const PubliclyAccessibleDataSources: React.FC = () => {
  const navigate = useNavigate();
  const { publiclyExposedDataSources } = useSelector(
    selectDataSecurityOverview,
  );
  const { riskContext } = useSelector(selectSharedState);
  const [categories, setCategories] = useState<
    Record<
      string,
      PublicResourceSummary & { iconType: string; clouds: string[] }
    >
  >({});
  const { accountMapping } = useCloudAccountId();
  console.log(publiclyExposedDataSources.data);

  useEffect(() => {
    const items = {};
    each(publiclyExposedDataSources.data, o => {
      let clouds = new Set();
      each(o.resources, r => {
        const cloud = accountMapping[r?.account]?.cloud_type;
        if (!!cloud) clouds.add(cloud);
      });
      items[o.category] = { ...o, clouds: toArray(clouds) };
    });
    setCategories({
      Buckets: {
        ...items[DataCategory.Buckets],
        iconType: IconTypes.SimpleStorageService,
      },
      Databases: {
        ...items[DataCategory.Databases],
        iconType: IconTypes.Database,
      },
      Others: {
        ...items[DataCategory.Others],
        iconType: IconTypes.BlockStorage,
      },
      SaaS: {
        ...items[DataCategory.SaaS],
        iconType: IconTypes.Cloud,
      },
    });
  }, [publiclyExposedDataSources.data, accountMapping]);

  const getTopResources = resources => {
    let topResources: Record<string, any>[] = [];
    each(resources, o => {
      if (some(riskContext.data?.[o.uuid], { type: 'Sensitive data' })) {
        topResources.push({ ...o, hasSensitiveData: true });
      }
    });
    const filteredResources = filter(
      resources,
      o => !includes(topResources, t => t?.uuid),
    );

    return [...topResources, ...filteredResources];
  };

  const renderTag = resource => {
    const cloud = accountMapping?.[resource?.account]?.cloud_type ?? '';
    return (
      <CustomTooltip
        label={
          <Stack spacing={0}>
            <Box color="orange">{resource?.name || resource?.resource_id}</Box>
            <Box>
              {resource?.hasSensitiveData
                ? 'Contains sensitive data'
                : 'No sensitive data found'}
            </Box>
          </Stack>
        }
      >
        <HStack
          borderRadius={6}
          bg={
            cloud
              ? colorOpacity(getIconColor(cloud), 0.2)
              : colorOpacity(customTheme.colors.primary, 0.2)
          }
          border="1px solid"
          borderColor={colorMap.primary(200)}
          px={1}
          spacing={1}
          cursor="pointer"
          onClick={() => {
            if (resource?.hasSensitiveData)
              navigate(
                '/visibility/data?tab=DataSources&uuid=' +
                  resource?.uuid +
                  '&resource_id' +
                  resource?.resource_id +
                  '&account_id' +
                  resource?.account +
                  '&resource_type=' +
                  resource?.resource_type,
              );
            else
              navigate(
                '/inventory/summary?category=Data&resource_type=' +
                  resource?.resource_type +
                  '&resource_id=' +
                  resource?.resource_id,
              );
          }}
        >
          {resource?.hasSensitiveData && (
            <Box boxSize={4}>
              <Center>
                <CriticalDataIcon color="critical" />
              </Center>
            </Box>
          )}
          <Box>
            {limitedString(resource?.name || resource?.resource_id, 19)}
          </Box>
        </HStack>
      </CustomTooltip>
    );
  };

  const getResourceType = category => {
    switch (category) {
      case 'Buckets':
        return Resources.ObjectStorage;
      case 'Databases':
        return Resources.Database;
      case 'Disks':
      case 'Others':
        return Resources.BlockStorage;
      default:
        return 'Repository';
    }
  };

  const renderItem = (
    item: PublicResourceSummary & { icon: ReactNode },
    category: DataCategory,
  ) => {
    let resources = getTopResources(map(item?.resources, o => ({ ...o })));
    return (
      <Center h="full" justifyContent="left">
        <HStack fontSize="md">
          <HStack
            cursor="pointer"
            onClick={() => {
              const url = securityGraphRedirectUrl({
                query: getSearchQuery(
                  SearchQueryMapping.Show_resource_type_with_internet_accessible,
                  [getResourceType(item?.category)],
                ),
              });
              navigate(url);
            }}
          >
            <HStack w={32} spacing={4}>
              <Box boxSize={5}>
                <Center color="primary">{item?.icon}</Center>
              </Box>
              <Box>
                {category === DataCategory?.Others ? 'Disks' : category}
              </Box>
            </HStack>
            <Box w={16} fontWeight={600}>
              {item?.total ?? 0}
            </Box>
          </HStack>
          {!!item?.resources && (
            <HStack spacing={1}>
              {resources?.[0] && renderTag(resources?.[0])}
              {resources?.[1] && renderTag(resources?.[1])}
              {item?.total > 2 && (
                <Box
                  borderRadius={6}
                  border="1px solid"
                  borderColor={colorMap.primary(200)}
                  px={1}
                  bg="primary"
                  color="white"
                  cursor="pointer"
                  onClick={() => navigate('/inventory/summary?category=Data')}
                >
                  {'+' + (item?.total - 2)}
                </Box>
              )}
            </HStack>
          )}
        </HStack>
      </Center>
    );
  };

  const data = map(
    [
      DataCategory?.Buckets,
      DataCategory?.Databases,
      DataCategory?.Others,
      DataCategory?.SaaS,
    ],
    c => ({
      type: c,
      count: categories?.[c]?.total ?? 0,
      clouds: categories?.[c]?.clouds ?? [],
    }),
  );

  const columns = [
    {
      header: <Box pl={9}>RESOURCE</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={categories[row.type]?.iconType}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
          color="primary"
        >
          {row.type === DataCategory?.Others ? 'Disks' : row.type}
        </WithResourceIcon>
      ),
      styles: { td: { w: '50%' } },
    },
    {
      header: 'COUNT',
      accessor: 'count',
      styles: { td: { w: '25%', textAlign: 'right' } },
    },
    {
      header: 'CLOUDS',
      render: ({ row }) => renderCloudIcons(row?.clouds),
      styles: { td: { w: '25%' } },
    },
  ];

  return (
    <DashboardWidget
      label="Publicly Exposed Data Sources"
      isLoading={publiclyExposedDataSources.isLoading}
      noData={isEmpty(publiclyExposedDataSources.data)}
      noDataMessage="No data sources are publicly accessible"
      content={
        <Box w="full" pl={1} h="full">
          <DashboardTable
            columns={columns}
            data={data}
            onRowClick={() => navigate('/inventory/summary?category=Data')}
          />
        </Box>
      }
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
      tooltip={{
        tooltip: (
          <Stack>
            <Box>
              Ariksa detects publicly exposed data sources in cloud and SaaS and
              enriches with deeper context to uncover imminent threats.
            </Box>
            <Box>To protect your data:</Box>
            <UnorderedList color="primary" spacing={2}>
              <ListItem pl={2}>Deploy Outpost for Data</ListItem>
              <ListItem pl={2}>Review exposed data</ListItem>
              <ListItem pl={2}>Remediate using Ariksa</ListItem>
            </UnorderedList>
          </Stack>
        ),
        header: 'Publicly Exposed Data Sources',
        showArrow: false,
        onClickFooter: () => navigate('/setup/outpost/add/data'),
      }}
    />
  );
};
