import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { Box, useDisclosure } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { getIcon } from 'components/Icons/Components';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import {
  AllEntitiesDetailsProps,
  SearchQueryMapping,
} from 'containers/Dashboard/types';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

export const InsightsForCloudNetworking: React.FC = () => {
  const { insightsForCloudNetworking } = useSelector(
    selectCloudPostureOverview,
  );
  const navigate = useNavigate();
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  const getPanel = key => {
    switch (key) {
      case 'Public IPs':
        return PanelsEnum.ExternallyAccessiblePublicIps;
      case 'Public subnets':
        return PanelsEnum.ExternallyAccessiblePublicSubnets;
      case 'Anomalous traffic':
        return PanelsEnum.AnomalousActivityMachines;
      default:
        return PanelsEnum.OpenSshMachines;
    }
  };

  const onClick = key => {
    const url = securityGraphRedirectUrl({
      query:
        SearchQueryMapping.Show_VirtualMachine_with_open_ports_equals_22_and_protocol_tcp,
    });

    switch (key) {
      case 'VMs with SSH open':
        return navigate(url);
    }
  };

  return (
    <>
      <DashboardWidget
        isLoading={insightsForCloudNetworking.isLoading}
        label="Insights for Cloud Networking"
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
        content={
          <Box w="full" h="full" overflow="auto">
            {renderResourcesWithIconAndCount(
              map(insightsForCloudNetworking.data, (count, key) => ({
                label: key!,
                icon: getIcon(key),
                count: formatNumber(count),
                iconColor: 'critical',
                onClick: () => {
                  allEntitiesDisclosure.onOpen();
                  setDetails({
                    panel: getPanel(key),
                    iconType: key,
                    total: count,
                  });
                  //onClick(key);
                },
              })),
            )}
          </Box>
        }
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
