import React, { useState } from 'react';

import { PanelsEnum, Resources } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';

interface Props {
  scannerDeployed: boolean;
}

export const VulnerableHostsWithAccessToSensitiveData: React.FC<Props> = props => {
  const { vulnerableEntitiesWithSensitiveDataAccess } = useSelector(
    selectVulnerabilityOverview,
  );
  const { data, isLoading } = vulnerableEntitiesWithSensitiveDataAccess;
  const navigate = useNavigate();
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  return (
    <>
      <DashboardWidget
        label="Vulnerable Entities with Access to Sensitive Data"
        isLoading={isLoading}
        content={renderResourcesWithIconAndCount([
          {
            label: 'Virtual Machine',
            count: (data?.items?.['Virtual Machine'] as number) || 0,
            isLoading: isLoading,
            iconType: IconTypes.Ec2Instance,
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.VulnerableWithAccessToSensitive,
                iconType: IconTypes.VirtualMachine,
                total: (data?.items?.['Containers'] as number) || 0,
                resourceType: Resources.VirtualMachine,
                resourceTypeClass: 'agnostic',
              });
            },
            iconColor: 'primary',
          },
          {
            label: 'Serverless',
            count: (data?.items?.['Serverless'] as number) || 0,
            isLoading: isLoading,
            iconType: IconTypes.LambdaFunction,
            iconColor: 'orange',
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.VulnerableWithAccessToSensitive,
                iconType: IconTypes.LambdaFunction,
                total: (data?.items?.['Serverless'] as number) || 0,
                resourceType: Resources.ServerlessCompute,
                resourceTypeClass: 'agnostic',
              });
            },
          },
          {
            label: 'Containers',
            count:
              ((data?.items?.['Container'] ||
                data?.items?.['Containers']) as number) || 0,
            isLoading: isLoading,
            iconType: IconTypes.Container,
            iconColor: 'skyBlue.300',
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.VulnerableWithAccessToSensitive,
                iconType: IconTypes.Container,
                total: (data?.items?.['Containers'] as number) || 0,
                resourceType: Resources.Containers,
                resourceTypeClass: 'agnostic',
              });
            },
          },
          {
            label: 'Kubernetes',
            count: (data?.items?.['Kubernetes'] as number) || 0,
            isLoading: isLoading,
            iconType: IconTypes.Kubernetes,
            iconColor: 'blue.300',
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.VulnerableWithAccessToSensitive,
                iconType: IconTypes.Kubernetes,
                total: (data?.items?.['Kubernetes'] as number) || 0,
                resourceType: Resources.Kubernetes,
                resourceTypeClass: 'agnostic',
              });
            },
          },
        ])}
        /*contentTooltip={
          props.scannerDeployed ? undefined : (
            <Center w="full">
              <Box w="60%">
                <DashboardOverviewTooltip
                  button={
                    <Center w="full">
                      <HStack spacing={4} align="center">
                        <Box boxSize={5} color="primary">
                          <Center>
                            <IdeaGlitterIcon />
                          </Center>
                        </Box>
                        <Box color="primary" fontSize="md" flex={1}>
                          Deploy Outpost for Data to identify risks to sensitive
                          data from vulnerable entities
                        </Box>
                      </HStack>
                    </Center>
                  }
                  header="Deploy Ariksa Outpost for Vulnerability"
                  tooltip={
                    <Stack>
                      <Box>
                        Ariksa Outpost for Vulnerability detects vulnerabilities
                        and exploits in OS, application, libraries and databases
                        that are hosted within virtual machine and containers.
                      </Box>
                      <Box>
                        Ariksa automatically connects hidden dots to uncover
                        attack paths and risks to data arising out of a
                        combination of exploitable vulnerabilities Internet
                        exposure, privileged roles, leaked credentials and data
                        sensitivity, to help you drive efficient prioritization
                        of your security efforts.
                      </Box>
                    </Stack>
                  }
                  footerText="Deploy now"
                  onClickFooter={() =>
                    navigate('/setup/outpost/add/vulnerability')
                  }
                />
              </Box>
            </Center>
          )
        }*/
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        vulnerable
        {...details}
      />
    </>
  );
};
