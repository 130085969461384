import React, { FC, useEffect } from 'react';

import { Box, Stack, Center, Input, FormControl } from '@chakra-ui/react';
import { filter, find, includes, isEmpty } from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { QueryStatus } from 'services/types';

import { NotificationService } from 'api/services';
import { Tag } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { Select } from 'components/DataEntry';
import { FormLabel } from 'components/DataEntry/Form/fields/FormLabel';
import { defaultStyles as defaultFormStyles } from 'components/DataEntry/Form/styles';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { toTitleCase } from 'utils/string';

import { actions } from '../../../../slice';

interface Props {
  clientInfo: any;
}

export const JiraComponent: FC<Props> = props => {
  const { clientInfo } = props;
  const dispatch = useDispatch();
  const { jiraMandatoryFields, jiraClients } = useSelector(selectAlertWorkflow);

  //set client options
  useEffect(() => {
    const payload = { clientId: clientInfo?.uuid, name: clientInfo?.name };
    dispatch(
      actions.getMandatoryJiraFields({
        q: payload,
        status: QueryStatus.pending,
      }),
    );

    NotificationService.Clients.getJiraProjectDetails(payload).then(res => {
      dispatch(
        actions.getMandatoryJiraFields({
          q: payload,
          status: QueryStatus.fulfilled,
          data: res.data,
        }),
      );
    });
  }, [dispatch, clientInfo]);

  const content = (name, value) => (
    <Stack direction="row" align="center" w="full">
      <Box fontSize="sm">{name}</Box>
      <Tag label={value} />
    </Stack>
  );

  const getOptions = list =>
    map(list, o => ({
      label: o?.name ?? o.value,
      value: { id: o.id, value: o?.name ?? o.value },
    }));

  const updateFields = (field, value) => {
    dispatch(
      actions.updateJiraClientFields({ field, value, name: clientInfo.name }),
    );
  };

  return (
    <Box border="1px solid" borderColor="gray.200" p={2} borderRadius={6}>
      <Stack spacing={4}>
        <Stack direction="row">
          {content('Name', clientInfo?.name)}
          {content(
            'Project Name',
            clientInfo?.jira?.project_name || clientInfo?.jira?.project_id,
          )}
        </Stack>
        {jiraMandatoryFields?.[clientInfo.uuid]?.isLoading && (
          <Center py={2}>
            <CustomSpinner
              loading={jiraMandatoryFields?.[clientInfo.uuid]?.isLoading}
            />
          </Center>
        )}
        {!jiraMandatoryFields?.[clientInfo.uuid]?.isLoading &&
          map(jiraMandatoryFields?.[clientInfo.uuid]?.data, o => {
            const options = getOptions(o?.allowed_values);
            const all = jiraClients?.[clientInfo.name]?.[o?.name];

            const selected = isEmpty(o)
              ? all?.[0] ?? ''
              : /*: isEmpty(all)
              ? options?.[0] ?? (o.type === 'array' ? [] : {})*/
              o.type === 'array'
              ? filter(options, op =>
                  includes(
                    map(all, k => k?.id),
                    op.value?.id,
                  ),
                )
              : find(options, op => op.value?.id === all?.id);
            //console.log(selected, all, isEmpty(all), o, options);
            return (
              <FormControl isRequired={true} key={'jira' + o?.name}>
                <FormLabel
                  htmlFor={o?.name}
                  label={toTitleCase(o?.name)}
                  isRequired={true}
                  styles={{
                    ...defaultFormStyles.textField?.label,
                    color: '#000',
                  }}
                />
                {isEmpty(o) ? (
                  <Input
                    {...defaultFormStyles.textField?.input}
                    value={selected}
                    onChange={e => updateFields(o?.name, [e.target.value])}
                  />
                ) : (
                  <Select
                    options={options}
                    isMulti={o.type === 'array'}
                    value={selected}
                    onChange={selected => {
                      updateFields(
                        o?.name,
                        o.type === 'array'
                          ? map(selected, l => l.value)
                          : selected?.value,
                      );
                    }}
                  />
                )}
              </FormControl>
            );
          })}
      </Stack>
    </Box>
  );
};
