/**
 *
 * Side Nav Bar
 *
 */

import React, { useMemo } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Center,
} from '@chakra-ui/react';
import { map, isEmpty, cloneDeep, merge, findIndex } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useSideNavContext } from './SideNavContext';
import { defaultStyles } from './styles';
import { SideNavMenuItemProps, SideNavStyles } from './types';

interface Props {
  menuItems: SideNavMenuItemProps[];
  paddingLeft?: number;
  styles?: SideNavStyles;
  isSecondary?: boolean;
}

export const NavBar: React.FC<Props> = props => {
  const {
    menuItems,
    paddingLeft,
    styles: secondaryNavStyles,
    isSecondary = false,
  } = props;
  const {
    initialPath,
    styles,
    onChange,
    setCurrentTab,
    currentTab,
    setCurrentPrimaryKeys,
    currentPrimaryKeys,
    keyMap,
    leftAlignSecondaryItems = false,
    showPrimaryInBold = true,
  } = useSideNavContext();
  const navigate = useNavigate();

  const handleTabChange = item => {
    navigate(`${initialPath}/${item?.key}`);
  };

  const handleOnTabClick = item => {
    onChange?.(item?.key, item);
    initialPath ? handleTabChange(item) : setCurrentTab(item);
    setCurrentPrimaryKeys(keyMap[item?.key]);
  };

  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return merge(props, styles);
  }, [styles]);

  const buttonStyles = (menuItem: SideNavMenuItemProps) => ({
    fontWeight: isSecondary || !showPrimaryInBold ? 400 : 600,
    ...baseStyles?.menuItem,
    ...(menuItem?.secondary
      ? !isEmpty(currentPrimaryKeys) &&
        findIndex(currentPrimaryKeys, each => each === menuItem.key) >= 0
        ? baseStyles?.selectedPrimary
        : {}
      : currentTab?.key === menuItem?.key
      ? baseStyles?.selectedSecondary
      : {}),
    ...menuItem?.styles?.menuItem,
  });

  /*const defaultIndex = () => {
    return findIndex(
      menuItems,
      each => findIndex(currentPrimaryKeys, key => key === each.key) >= 0,
    );
  };*/

  const renderTitle = item => {
    if (item?.hideTitle) {
      return <></>;
    }
    return (
      <HStack
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        w={'full'}
        spacing={3}
      >
        {item?.icon && (
          <Center >
            <Box boxSize={6}>{item?.icon}</Box>
          </Center>
        )}
        <Flex py={3} w="full" alignItems="flex-start">
          {item?.title}
        </Flex>
      </HStack>
    );
  };

  return (
    <Box h="full">
      <Accordion
        {...baseStyles.menu}
        {...secondaryNavStyles?.menu}
        w="full"
        p={isSecondary ? 0 : 2}
      >
        {map(menuItems, (each, index) => (
          <AccordionItem
            key={`${each?.key}-${index}-side-nav-item`}
            border="none"
            isDisabled={each?.isDisabled}
            w="full"
          >
            {/*when component is also present for primary item
            It'll render component on title or icon click*/}
            {each?.component && each?.secondary ? (
              <Box
                {...buttonStyles(each)}
                py={0}
                {...(currentTab?.key === each?.key
                  ? baseStyles.selectedSecondary
                  : {})}
                pl={paddingLeft ?? 4}
                w="full"
                cursor="pointer"
              >
                <HStack justify="space-between" w="full">
                  <Box onClick={() => handleOnTabClick(each)}>
                    {renderTitle(each)}
                  </Box>
                  <AccordionButton
                    {...(buttonStyles(each) as any)}
                    pr={currentTab?.key === each?.key ? 0 : 4}
                    //py={3}
                    justifyContent="end"
                  >
                    <Flex justify="flex-end">
                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>
                </HStack>
              </Box>
            ) : (
              <AccordionButton
                pl={paddingLeft}
                onClick={() => (each?.secondary ? '' : handleOnTabClick(each))}
                py={0}
                {...(buttonStyles(each) as any)}
              >
                {renderTitle(each)}
                {each?.secondary && (each?.showArrow ?? true) && (
                  <AccordionIcon />
                )}
              </AccordionButton>
            )}

            {each?.secondary && (
              <AccordionPanel p={0}>
                <NavBar
                  menuItems={each?.secondary}
                  paddingLeft={
                    leftAlignSecondaryItems
                      ? paddingLeft
                      : paddingLeft
                      ? paddingLeft + 8
                      : 12
                  }
                  styles={{ menu: each?.styles?.menu }}
                  isSecondary
                />
              </AccordionPanel>
            )}
            {each?.showDivider && (
              <Box px={4} py={3}>
                <Box w="full" borderBottom="1px solid" borderColor="gray.200" />
              </Box>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};
