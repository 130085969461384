import React, { ReactNode } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { usePageContentContext } from 'components/Layout';
import { CDashboard } from 'components/Visualization';
import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

interface Props {
  content?: ReactNode;
  label: ReactNode;
  icon: ReactNode;
  height?: string | number;
  titleBgColor?: string;
  iconBgColor?: string;
  titleFontColor?: string;
  dashboardWidgets?: IDashboardLayout;
}

export const DashboardOverviewWrapperCard: React.FC<Props> = props => {
  const {
    label,
    content,
    icon,
    height,
    iconBgColor = 'primary',
    titleFontColor = 'black',
    dashboardWidgets,
  } = props;
  const { contentHeight } = usePageContentContext();
  return (
    <Stack
      h={height || contentHeight + 'px'}
      minH={height || '1050px'}
      maxH={height || '1050px'}
      pb={2}
    >
      <Box
        color="primary"
        bg="white"
        py={2}
        px={3}
        borderRadius={6}
        border="1px solid"
        borderColor={customTheme.colors.gray['100']}
      >
        <PageHeaderWithIcon
          label={label}
          icon={icon}
          fontSize="xl"
          reversed
          border
          iconSize="md"
          borderColor={customTheme.colors.gray['250']}
          //useCustomColor
          iconBgColor={iconBgColor}
          iconStyles={{ borderRadius: 12 }}
          fontColor={titleFontColor}
          labelStyles={{fontWeight: 600}}
        />
      </Box>
      <Box flex={1} h="full" py={2} px={0}>
        {dashboardWidgets ? (
          <CDashboard dashboardWidgets={dashboardWidgets} />
        ) : (
          content
        )}
      </Box>
    </Stack>
  );
};
