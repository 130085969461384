import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, HStack, ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import { forEach, map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomerJourneyBulbIcon } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { dashboardWidgetLineStyles } from 'containers/Dashboard/utils/styles';
import { DashboardWidgetTooltipIcon } from 'containers/Dashboard/utils/utils';

export const RuntimeInsights: React.FC = () => {
  const { runtimeInsights } = useSelector(selectDashboard);
  const navigate = useNavigate();
  const [data, setData] = useState<Record<string, any>[]>([]);

  const onRedirect = useCallback(
    tab =>
      navigate(
        '/findings?tab=' + tab + '&severity=CRITICAL,HIGH&redirect=true',
      ),
    [navigate],
  );

  useEffect(() => {
    //setData(sortBy(categories, o => o.count).reverse());
  }, []);

  return (
    <DashboardWidget
      label="Runtime Insights"
      subHeader="Last 30 days"
      isLoading={runtimeInsights.isLoading}
      content={
        <Stack spacing={0} w="full" h="full" justify="space-between">
          {map(data, o => (
            <HStack
              {...dashboardWidgetLineStyles}
              justify="space-between"
              onClick={o.onClick}
            >
              <HStack spacing={6}>
                <Box boxSize={6} color="primary">
                  {o.icon}
                </Box>
                <Box>{o.label}</Box>
              </HStack>
              <Box>{o.count}</Box>
            </HStack>
          ))}
        </Stack>
      }
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
      tooltip={{
        tooltip: (
          <Stack spacing={3}>
            <Box>
              Ariksa correlates runtime info, logs, activity and configuration
              data to detect insights that proactively assess risks and imminent
              threats.
            </Box>
            <Box>For deeper insights:</Box>
            <UnorderedList color="primary" spacing={2}>
              <ListItem pl={2}>Retain user activity logs</ListItem>
              <ListItem pl={2}>Retain network activity logs</ListItem>
              <ListItem pl={2}>Deploy runtime sensors</ListItem>
            </UnorderedList>
          </Stack>
        ),
        header: 'Runtime Insights for Threats',
        showArrow: false,
        icon: <CustomerJourneyBulbIcon />,
        button: <DashboardWidgetTooltipIcon />,
      }}
    />
  );
};
