import React from 'react';

import { formatTooltip } from 'components/DataDisplay';
import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { Buckets } from 'containers/Dashboard/DataSecurityOverview/Components/Buckets';
import { Databases } from 'containers/Dashboard/DataSecurityOverview/Components/Databases';
import { DataFootprintBySourceTypes } from 'containers/Dashboard/DataSecurityOverview/Components/DataFootprintBySourceTypes';
import { DataSecurityFindingsToPrioritize } from 'containers/Dashboard/DataSecurityOverview/Components/DataSecurityFindingsToPrioritize';
import { DataSourcesAndSensitiveData } from 'containers/Dashboard/DataSecurityOverview/Components/DataSourcesAndSensitiveData';
import { Disks } from 'containers/Dashboard/DataSecurityOverview/Components/Disks';
import { InsightsForDataSources } from 'containers/Dashboard/DataSecurityOverview/Components/InsightsForDataSources';
import { SaaS } from 'containers/Dashboard/DataSecurityOverview/Components/SaaS';
import { SummaryOfAllDataSources } from 'containers/Dashboard/DataSecurityOverview/Components/SummaryOfAllDataSources';
import { CloudCompliance } from 'containers/Dashboard/Overview/Components/CloudCompliance';
import { DataSourcesSummaryCard } from 'containers/Dashboard/Overview/Components/DataSourcesSummaryCard';
import { GovernanceScore } from 'containers/Dashboard/Overview/Components/GovernanceScore';
import { Identities } from 'containers/Dashboard/Overview/Components/Identities';
import { Infrastructure } from 'containers/Dashboard/Overview/Components/Infrastructure';
import { OpenAlertsAndResolutions } from 'containers/Dashboard/Overview/Components/OpenAlertsAndResolutions';
import { PolicyManagement } from 'containers/Dashboard/Overview/Components/PolicyManagement';
import { RuntimeInsights } from 'containers/Dashboard/Overview/Components/RuntimeInsights';
import {
  ActiveDataRegions
} from 'containers/Dashboard/DataSecurityOverview/Components/ActiveDataRegions';
import {
  PubliclyAccessibleDataSources
} from 'containers/Dashboard/DataSecurityOverview/Components/PubliclyAccessibleDataSources';
import {
  DataSecurityFindingsHistory
} from 'containers/Dashboard/DataSecurityOverview/Components/DataSecurityFindingsHistory';

export const overviewDashboardWidgets: Record<string, WidgetProps> = {
  summary: {
    flex: true,
    components: {
      content: <SummaryOfAllDataSources />,
    },
  },
  dataSources: {
    flex: true,
    components: {
      content: (
        <DataSourcesSummaryCard
          tooltip={formatTooltip({
            header: 'Data sources',
            content:
              'Buckets, Disks, Databases, File stores and Data warehouses in public clouds and SaaS',
            width: 48,
          })}
        />
      ),
    },
  },
  buckets: {
    flex: true,
    components: {
      content: <Buckets />,
    },
  },
  databases: {
    flex: true,
    components: {
      content: <Databases />,
    },
  },
  disks: {
    flex: true,
    components: {
      content: <Disks />,
    },
  },
  saas: {
    flex: true,
    components: {
      content: <SaaS />,
    },
  },
  dataSecurityFindingsToPrioritize: {
    flex: true,
    components: {
      content: <DataSecurityFindingsToPrioritize />,
    },
  },
  insightsForDataSources: {
    flex: true,
    components: {
      content: <InsightsForDataSources />,
    },
  },
  dataFootprintBySourceTypes: {
    flex: true,
    components: {
      content: <DataFootprintBySourceTypes />,
    },
  },
  dataSourcesAndSensitiveData: {
    flex: true,
    components: {
      content: <DataSourcesAndSensitiveData />,
    },
  },
  dataRegions: {
    flex: true,
    components: {
      content: <ActiveDataRegions />,
    },
  },
  publiclyExposed: {
    flex: true,
    components: {
      content: <PubliclyAccessibleDataSources />,
    },
  },
  findingsHistory: {
    flex: true,
    components: {
      content: <DataSecurityFindingsHistory />,
    },
  },
};
