/**
 *
 * Add Ariksa Vulnerability Outpost
 *
 */

import React, { FC, useEffect, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Outpost } from '@ariksa/inventory-core/api';
import { Box, HStack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import {
  AzureIcon,
  GoogleCloudIcon,
  OutpostForVulnerabilityIcon,
  QuestionCircleIcon,
} from 'components/Icons';
import {
  SideNav,
  SideNavMenuItemProps,
  AwsIcon,
  PageHeaderWithIcon,
} from 'components/index';
import { AboutAriksaOutpostForVulnerability } from 'containers/Setup/Outpost/Components/OutpostWizard/AriksaOutpostForVulnerability/AboutAriksaOutpostForVulnerability';
import { OutpostProps } from 'containers/Setup/Outpost/types';
import { actions } from 'containers/Setup/slice';

import { AddAriksaOutpostForVulnerabilityForm } from './AddAriksaOutpostForVulnerabilityForm';

export const AriksaOutpost: FC<OutpostProps> = props => {
  const { selectedTab, updateValue } = props;
  const [showHelp, setShowHelp] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.getOutpostConfig({ q: { outpost: Outpost.Vulnerability } }),
    );
  }, [dispatch]);

  const contentHeader = () => (
    <PageHeaderWithIcon
      label={
        <HStack>
          <Box>Deploy Outpost for Vulnerability</Box>
          <Box
            onClick={() => setShowHelp(!showHelp)}
            color="primary"
            cursor="pointer"
          >
            <QuestionCircleIcon />
          </Box>
        </HStack>
      }
      icon={<OutpostForVulnerabilityIcon />}
      reversed
    />
  );

  const helpComponent = () => (
    <AboutAriksaOutpostForVulnerability onClose={() => setShowHelp(false)} />
  );

  const menuItems: SideNavMenuItemProps[] = [
    {
      title: 'AWS',
      key: CloudProviders.Aws,
      component: <AddAriksaOutpostForVulnerabilityForm {...props} />,
      icon: <AwsIcon />,
      contentHeader: contentHeader(),
      helpContent: helpComponent(),
      showHelpContent: showHelp,
    },
    {
      title: 'GCP',
      key: CloudProviders.Gcp,
      component: <AddAriksaOutpostForVulnerabilityForm {...props} />,
      icon: <GoogleCloudIcon />,
      contentHeader: contentHeader(),
      helpContent: helpComponent(),
      showHelpContent: showHelp,
    },
    {
      title: 'Azure',
      key: CloudProviders.Azure,
      component: <AddAriksaOutpostForVulnerabilityForm {...props} />,
      icon: <AzureIcon />,
      contentHeader: contentHeader(),
      helpContent: helpComponent(),
      showHelpContent: showHelp,
    },
  ];

  return (
    <SideNav
      current={selectedTab}
      onChange={val => {
        updateValue('selectedTab', val);
        updateValue('selectedRegion', {});
      }}
      menuItems={menuItems}
      styles={{ componentWrapper: { pos: 'relative' } }}
    />
  );
};
