import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Box, Stack, HStack } from '@chakra-ui/react';
import { forEach } from 'lodash';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { CriticalDataIcon } from 'components/Icons';

import { customTheme } from '../../../../../../../theme';

interface Props {
  label: string;
  analyzed: number;
  sensitiveDataCount: number;
  total: number;
  isLoading: boolean;
  icon: ReactNode;
}

export const AnalyzedCard: FC<Props> = props => {
  const { label, analyzed, total, isLoading, icon, sensitiveDataCount } = props;

  return (
    <Stack w="full" px={3} h="full" justify="center" spacing={3}>
      <HStack>
        <Box boxSize={6} color="primary">
          {icon}
        </Box>
        <Box fontSize="lg">{label}</Box>
      </HStack>
      <HStack justify="space-between" w="full">
        <Box color={customTheme.colors.gray['300']}>
          {formatNumber(analyzed, 1)} out of {formatNumber(total, 1)} analyzed
        </Box>
        <HStack>
          <Box boxSize={5} color="critical">
            <CriticalDataIcon />
          </Box>
          <Box>{formatNumber(sensitiveDataCount, 1)}</Box>
        </HStack>
      </HStack>
      {/*<HStack
        justify="space-between"
        w="full"
        align="center"
        color="white"
        px={2}
      >
        <Stack spacing={1}>
          <Box fontSize="md">{label}</Box>
          {isLoading && <CustomSpinner color="white" loading={isLoading} />}
          {!isLoading && <Box>{total ? total : 0}</Box>}
        </Stack>
      </HStack>*/}
    </Stack>
  );
};
