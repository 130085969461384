import React, { BaseSyntheticEvent, FC, ReactNode } from 'react';

import {
  Box,
  Center,
  Table,
  TableContainer,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { customTheme } from 'theme';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

interface Props {
  columns: {
    header: ReactNode;
    accessor?: string;
    render?({ row, value });
    styles?: {
      td?: Record<string, any>;
      cell?: Record<string, any>;
      header?: Record<string, any>;
      w?: string | number;
    };
  }[];
  data: Record<string, any>[];
  isLoading?: boolean;
  noDataMessage?: ReactNode;
  align?: any;
  onRowClick?(row);
  styles?: { td?: Record<string, any>; cell?: Record<string, any> };
  onScroll?(e: BaseSyntheticEvent);
  onScrollIsLoading?: boolean;
}

export const DashboardTable: FC<Props> = props => {
  const {
    columns,
    data,
    isLoading,
    noDataMessage,
    align = 'left',
    styles,
    onRowClick,
    onScroll,
    onScrollIsLoading,
  } = props;
  return (
    <TableContainer h="full" w="full" overflowY="auto" overflowX="hidden">
      <Table
        variant="unstyled"
        h={isLoading || isEmpty(data) ? 'full' : 'default'}
        w="full"
      >
        <Thead position="sticky" top={0} zIndex={1} bg="white">
          <Tr borderBottom="none" w="full">
            {map(columns, o => (
              <Th
                fontWeight={400}
                color={customTheme.colors.gray['270']}
                fontSize="12px"
                border="none"
                textAlign={align}
                px={3}
                w={o?.styles?.w}
                {...o.styles?.header}
              >
                {o.header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody h="full" overflow="scroll" onScroll={onScroll}>
          {isLoading || isEmpty(data) ? (
            <Tr h="full">
              <Td colSpan={columns?.length} borderBottom="0">
                <Box h="full" display="flex" alignItems="center">
                  <Center w="full" h="full">
                    {isLoading ? (
                      <CustomSpinner />
                    ) : (
                      <NoDataAvailable text={noDataMessage} />
                    )}
                  </Center>
                </Box>
              </Td>
            </Tr>
          ) : (
            map(data, row => (
              <Tr
                borderRadius={6}
                _hover={{ bg: 'selectedSideNavMenuBg' }}
                w="full"
                css={{
                  'td: first-child': { borderLeftRadius: 6 },
                  'td: last-child': { borderRightRadius: 6 },
                }}
                cursor={onRowClick ? 'pointer' : 'inherit'}
                onClick={() => onRowClick?.(row)}
              >
                {map(columns, (col, index) => (
                  <Td
                    px={3}
                    py={3}
                    borderLeftRadius={index === 0 ? 6 : 0}
                    borderRightRadius={index === columns?.length - 1 ? 6 : 0}
                    maxWidth={col?.styles?.w}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    {...styles?.td}
                    {...col?.styles?.td}
                  >
                    <Box
                      textAlign={align}
                      w="full"
                      {...styles?.cell}
                      {...col?.styles?.cell}
                    >
                      {col?.render
                        ? col?.render?.({
                            row,
                            value: col?.accessor ? row[col?.accessor] : '',
                          })
                        : col?.accessor
                        ? row[col?.accessor]
                        : ''}
                    </Box>
                  </Td>
                ))}
              </Tr>
            ))
          )}
          {onScrollIsLoading && (
            <Tr>
              <CustomSpinner />
            </Tr>
          )}
        </Tbody>
        {/*)}*/}
      </Table>
    </TableContainer>
  );
};
